import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Link,
    Route,
    Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import PageHero from "../components/Hero";
import MediaComponent from "../partials/Media";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class ExpertisesHomepage extends Component {
    constructor(props) {
        super(props);

        //console.log('in Home.js')

        //console.log(this.props);

    }

    componentDidMount() {
        gsap.registerPlugin(MorphSVGPlugin);
        gsap.registerPlugin(DrawSVGPlugin);

    }

    renderMetas() {

        let title = (this.props.lngDatas.expertises.meta.title !== "") ? this.props.lngDatas.expertises.meta.title : (this.props.lngDatas.generic.meta.title !== "") ? this.props.lngDatas.generic.meta.title : "";
        let description = (this.props.lngDatas.expertises.meta.description !== "") ? this.props.lngDatas.expertises.meta.description : (this.props.lngDatas.generic.meta.description !== "") ? this.props.lngDatas.generic.meta.description : "";
        //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.expertises.meta.keywords !=="")? this.props.lngDatas.expertises.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
        let slug = this.props.lngDatas.expertises.meta.slug;
        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={slug}/>
            </Helmet>
        )
    }

    renderList(lines, index) {
        return lines.map((item, indexline) => {
            return (
                <li className="list-item" key={`categories-item-${index}-line-${indexline}`}>
      				<span className="list-text">
      					{item.text}
      				</span>
                </li>
            )
        });
    }

    renderCategories(version) {
        const oThis = this;
        let items =  (this.props.datas.expertises && this.props.datas.expertises.length > 0)?this.props.datas.expertises:[];
        let files = this.props.datas.uploads;

        return items.map((item, index) => {
            let visual = false;
            let name = false;
            let description = false;
            let slug = false;
            let baseSlug = false;


            if ( item.identity?.logoSVG?._id && item.identity?.logoSVG?._id.length > 0 ) {
                visual = findNestedObj(files,"_id", item.identity.logoSVG._id );
            }

            if ( item.identity && item.identity[this.props.lngDatas.name.data] && item.identity[this.props.lngDatas.name.data].title ) {
                name = item.identity[this.props.lngDatas.name.data].title;
            }

            if ( item.identity && item.identity[this.props.lngDatas.name.data] && item.identity[this.props.lngDatas.name.data].catch) {
                description = item.identity[this.props.lngDatas.name.data].catch
            }

            if ( item.meta && item.meta[this.props.lngDatas.name.data] && item.meta[this.props.lngDatas.name.data].slug) {
                slug = item.meta[this.props.lngDatas.name.data].slug
            }

            if ( this.props.lngDatas.expertises?.meta?.slug ) {
                baseSlug = this.props.lngDatas.expertises.meta.slug
            }




            if ( name && slug && baseSlug ) {
                return (
                    <Link 
                        className={"item__expertiseItem"} 
                        key={`item__expertiseItem-${index}`}
                        to={`/${this.props.lngDatas.name.data}/${baseSlug}/${slug}`}>
                        <div className="item__expertise" key={`categories-item-${index}`} data-slug={slug}>
                            {
                                visual &&
                                <MediaComponent media={visual} version={version} />
                            }
                            <span className="name">{name}</span>
                            {
                                description &&
                                <span className="description" dangerouslySetInnerHTML={{__html:description}}></span>
                            }
                        </div>
                    </Link>
                )
            }

        });
    }

    iniFooterNavCTAs(svg) {
        new CircleCtaAnim(svg);
    }

    renderFooterNav() {
        return (
            <ul className="row w-100 justify-content-center navigation">
                <li className="col-12 col-md-6 align-self-start previous">
                    <Link className="cta hiddenCursor"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug}>
                        <span className="text">
                            <span className="label">
                                {this.props.lngDatas.generic.lab_cta_next}
                            </span>
                            <span className="name">
                                {this.props.lngDatas.universes.lab_nav}
                            </span>
                        </span>
                        <div className="circle">
                            <ReactSVG
                                className="circlesvg"
                                src="/ui/ctas/a_circle_full.svg"
                                renumerateIRIElements={false}
                                afterInjection={(error, svg) => {
                                    if (error) {
                                        console.error(error)
                                        return
                                    } else {
                                        this.iniFooterNavCTAs(svg)
                                    }
                                }}
                            />
                        </div>
                    </Link>
                </li>
                <li className="col-12 col-md-6 align-self-end next">
                    <Link className="cta hiddenCursor"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.brands.meta.slug}>
                        <div className="circle">
                            <ReactSVG
                                className="circlesvg"
                                src="/ui/ctas/a_circle_full.svg"
                                renumerateIRIElements={false}
                                afterInjection={(error, svg) => {
                                    if (error) {
                                        console.error(error)
                                        return
                                    } else {
                                        this.iniFooterNavCTAs(svg)
                                    }
                                }}
                            />
                        </div>
                        <span className="text">
                            <span className="label">
                                {this.props.lngDatas.generic.lab_cta_next}
                            </span>
                            <span className="name">
                                {this.props.lngDatas.brands.lab_nav}
                            </span>
                        </span>
                    </Link>
                </li>
            </ul>
        )
    }


    render() {
        return (
            <section className="page expertises">

                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    identity={false}
                    surtitle={{
                        type: 'text',
                        content: this.props.lngDatas.expertises.lab_nav,
                    }}
                    title={{
                        type: 'text',
                        content: this.props.lngDatas.expertises.hero,
                    }}
                    bottom={true}
                    module={'brand'}
                />

                {this.renderMetas()}

                <section className={'expertises__introduction section content'}>
                    <p className={'section__title'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.expertises.introduction.catch}}></p>
                    <p className={'section__content'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.expertises.introduction.text}}></p>
                </section>

                <section className={'expertises__list'}>
                    {this.renderCategories('original')}
                </section>

                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.lngDatas.universes.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.universes.meta.slug,
                            },
                            prev: {
                                title: this.props.lngDatas.brands.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.brands.meta.slug,
                            }
                        }
                    }
                />
            </section>
        )
    }
}
