import React, { Component, useState } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from 'react-svg';
import { DidomiSDK } from '@didomi/react';
import AppLoading from "./ui/components/AppLoading";


import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Route,
    Redirect,
    useRouteMatch,
    useParams,
    useLocation
} from "react-router-dom";
import { withRouter } from 'react-router'
import { gsap } from "gsap";
import { TweenMax } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

import Homepage from './ui/pages/Home';
import Universes from './ui/pages/Universes';
import Brands from './ui/pages/Brands';
import Expertises from './ui/pages/Expertises';
import News from './ui/pages/News';
import Legals from './ui/pages/Legals';
import Cookies from './ui/pages/Cookies';
import Tos from './ui/pages/Tos';
import Privpol from './ui/pages/Privpol';
import Contacts from './ui/transversals/Contacts';
import HeaderGen from './ui/transversals/HeaderGean'
import HideCursor from "./assets/scripts/HideCursor";
import IsVisible from "./assets/scripts/IsVisible";
import BlobsHeader from "./ui/transversals/blobsHeader";
import Jobs from "./ui/pages/Jobs.Homepage";
import AboutHomePage from "./ui/pages/About.Homepage";
import BlogHomePage from "./ui/pages/Blog.Homepage";

import HeaderNavigation from "./ui/components/HeaderNavigation";
import PageHero from "./ui/components/Hero";
import Footer from "./ui/components/Footer/Footer";
import Preloader from "./ui/components/preloader";


let didomiObject = {} ;


function toggleNav(val){

    let body = document.querySelector('body');
    let navbtn = document.querySelector('.menu-burger-btn');
    let nav = document.querySelector('.navigation');

    if ( val ) {
        if ( val === 2) {
            body.classList.remove('locked');
            navbtn.classList.remove('active');
            nav.classList.remove('open');
        }
    } else {
        if(!navbtn.classList.contains('active')){
            body.classList.add('locked');
            navbtn.classList.add('active');
            nav.classList.add('open');
        } else {
            body.classList.remove('locked');
            navbtn.classList.remove('active');
            nav.classList.remove('open');
        }
    }

}

function closeNav(){
    let navbtn = document.querySelector('.menu-burger-btn');

    if(navbtn.classList.contains('active')){
        toggleNav();
    }
}

function openTos(lngDatas, props){
    let tosId = (lngDatas.generic.footer.file_tos)?lngDatas.generic.footer.file_tos._id:null;
    let tosFile = (tosId !== "undefined")?findNestedObj(props.uploads, '_id', tosId):'';
    let tosSrc =  ''; //(tosFile && tosFile.trim())?tosFile.staticPath:'';
    document.location.replace(tosSrc);
}

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

function iniFooterNavCTAs(svg){

    let circleItem = svg.querySelector('.cta-ltr-circle');
    let arrowItem = svg.querySelector('.cta-ltr-arrow');
    let textItem = svg.querySelector('.text');

    // CURSOR
    const cursor = document.getElementById('CursorDot');
    const follower = document.getElementById('CursorCircle');
    const cursorSliderRight =  document.getElementById('arrowRight');
    const cursorSliderLeft =  document.getElementById('arrowLeft');
    const cursorParent = document.querySelector('.cursor');


    function moveCircle(e) {
        if(document.hasFocus()){
            if ( !cursorParent.classList.contains('active') ) {
                cursorParent.classList.add('active');
            }
            gsap.to(cursor, 0.2, {
                x: e.clientX,
                y: e.clientY,
                ease: "ease-in"
            });
            gsap.to(follower, 0.3, {
                x: e.clientX,
                y: e.clientY,
                ease: "ease-in"
            });

            TweenMax.staggerTo(cursorSliderRight, 0.3, {
                x: e.clientX,
                y: e.clientY,
                ease: "ease-in"
            });

            TweenMax.staggerTo(cursorSliderLeft, 0.3, {
                x: e.clientX,
                y: e.clientY,
                ease: "ease-in"
            });
        }


    }

    function mousePress() {

    }

    function hoverCtaFunc(e) {
        gsap.to(cursor, 0.3, {
            opacity: 1,
            scale: 0
        });
        gsap.to(follower, 0.3, {
            scale: 0
        });
    }

    function unhoverCtaFunc(e) {
        gsap.to(cursor, 0.3, {
            opacity: 1,
            scale: 1
        });
        gsap.to(follower, 0.3, {
            scale: 1
        });
    }

    let ctaParent = svg.parentNode;

    while(!ctaParent.classList.contains('cta') && ctaParent.parentNode !== null){
        ctaParent = ctaParent.parentNode;
    }

    let ctacirc = gsap;
    let tlctacirc = ctacirc.timeline();
    let tlctarr = ctacirc.timeline();
    let tlcttxt = ctacirc.timeline();

    tlctacirc.startTime(0);

    tlctacirc.to(circleItem, {drawSVG:"0% 75%"}).pause();

    tlctacirc.fromTo(circleItem, {drawSVG:"0% 75%"},{duration:.300, drawSVG:"100%", ease: "expo"}).pause();

    tlctarr.to(arrowItem, {opacity:0}).pause();

    tlctarr.to(arrowItem, {x: 50, duration:.300, opacity:1, ease: "expo"}).pause();

    tlcttxt.to(textItem, {x:0}).pause();

    tlcttxt.to(textItem, {x:50, duration:.300, ease:'expo'}).pause();

    ctaParent.addEventListener('mouseover', function(event){
        //console.log(this);
        event.stopPropagation();
        //console.log('over');
        hoverCtaFunc();
        tlctacirc.play();
        tlctarr.play();
        tlcttxt.play();
        //console.log(event.target);
    });

    ctaParent.addEventListener('mouseout', function(event){
        //console.log(this);
        event.stopPropagation();
        //console.log('out');
        unhoverCtaFunc();
        tlctacirc.reverse();
        tlctarr.reverse();
        tlcttxt.reverse();
        //console.log(event.target);
    });


    window.addEventListener('mousemove', moveCircle);



    let hidenCursorElementsList = document.querySelectorAll('.hiddenCursor');
    hidenCursorElementsList.forEach( (elem,key)=>{
        new HideCursor(elem);
    });

}

function openContact(){

    /*let body = document.querySelector('body');
    let modal = document.getElementById('modalContacts');
    let blobs = modal.querySelector('.blobs');
    let navbtn = document.querySelector('.menu-burger-btn');
    if(navbtn.classList.contains('active')){
        toggleNav();
    }

    body.classList.add('locked');
    gsap.to(blobs, {y:'100vh', duration: .5, onComplete: function(){
            blobs.classList.add('done');
        }});
    modal.classList.add('show');*/
}

function closeContact(){
    /*let body = document.querySelector('body');
    let modal = document.getElementById('modalContacts');
    let blobs = modal.querySelector('.blobs');
    let form = modal.querySelector( ".form" );
    let fields = form.querySelector(".fields");
    let confirmation = form.querySelector(".confirmation");

    body.classList.remove('locked');

    if(form.classList.contains('sent')){
        gsap.to(confirmation, {opacity:0, duration: .25, onComplete: function(){
                form.classList.remove('sent');
                fields.classList.remove('hide');
                gsap.to(fields, {opacity:1, duration: .25});
            }});
    } else{
        let inputs  = modal.querySelectorAll( ".field" );
        let errors  = modal.querySelectorAll( ".error" );
        inputs.forEach((item)=>{
            item.classList.remove('error');
        });
        errors.forEach((item)=>{
            item.classList.remove('show');
        });

        let form = modal.querySelector( ".form" );
        form.classList.remove('sending');
    }

    gsap.to(blobs, {y: '-100vh', duration: .5, onComplete: function(){
            blobs.classList.remove('done');
        }});
    modal.classList.remove('show');
    form.reset();*/
}

function RenderLngCtn(props){
    let { lng } = useParams();
    let location = useLocation();
    let paramsLocation = useParams();
    const blog = props.blog;

    const [navigationOpenState, setNavigationOpenState] = useState(false);
    const [contactOpenState, setContactOpenState] = useState(false);
    const [navigationState, setNavigationState] = useState(false);
    const [preloaderState, setPreloaderState] = useState(true);

    const [loading, setLoading]  = useState(true);
    const [showLoader, setShowLoader]  = useState(true);
    const [showContent, setShowContent]  = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    function handleContactPanel(state=null) {

        if ( state !== null) {
            let body = document.querySelector('body');
            if ( state ) {
                body.classList.add('locked');
            } else {
                body.classList.remove('locked');
            }
            setContactOpenState(state);
        } else {
            let body = document.querySelector('body');
            if ( contactOpenState ) {
                body.classList.remove('locked');
            } else {
                body.classList.add('locked');
            }
            setContactOpenState(!contactOpenState);
        }

    }

    function handleNavigationPanel(state=null) {
        if ( state !== null) {
            let body = document.querySelector('body');
            if (state ) {
                body.classList.add('locked');
            } else {
                body.classList.remove('locked');
            }
            setNavigationOpenState(state);
        } else {
            let body = document.querySelector('body');
            if ( navigationOpenState ) {
                body.classList.remove('locked');
            } else {
                body.classList.add('locked');
            }
            setNavigationOpenState(!navigationOpenState);
        }
    }



    function openContact(){
        setNavigationState(!navigationState);
    }

    function closeContact(){
        setNavigationState(false);
    }



    function onDidomiReady(didomi) {
        didomiObject = didomi;
    }





    //console.log(location);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);





    //findNestedObj(props.languages, 'name.data', lng);

    let lngDatas = props.languages.find(item => item.name.data === lng);

    //console.log(props);
    let datas = {
        brands : props.brands,
        contacts : props.contacts,
        news : props.news,
        newscontactuser : props.newscontactuser,
        newstypes : props.newstypes,
        tags :  props.tags,
        types : props.types,
        universes : props.universes,
        uploads : props.uploads,
        contactUrl : props.contactUrl,
        headers: props.headers,
        expertises: props.expertises,
        jobs: props.jobs,
        blog: props.blog,
    };

    const appControls = {
        handleNavigationPanel: handleNavigationPanel,
        navigationPanelState: navigationOpenState,
        handleContactPanel: handleContactPanel,
        contactOpenState: contactOpenState,
    };

    const linksHeader = [
        {
            path : "/"+lng+"/"+lngDatas.brands.meta.slug,
            exact : false,
            lab_nav : lngDatas.brands.lab_nav,
            isExt : false,
            genHeader: true,
        },{
            path : "/"+lng+"/"+lngDatas.expertises.meta.slug,
            exact : false,
            lab_nav : lngDatas.expertises.lab_nav,
            isExt : false,
            genHeader: true,
        },{
            path : "/"+lng+"/"+lngDatas.universes.meta.slug,
            exact : false,
            lab_nav : lngDatas.universes.lab_nav,
            isExt : false,
            genHeader: true,
        },{
            path : "/"+lng+"/"+lngDatas.about.meta.slug,
            exact : false,
            lab_nav : lngDatas.about.lab_nav,
            isExt : false,
            genHeader: true,
        },{
            path : "/"+lng+"/"+lngDatas.news.meta.slug,
            exact : false,
            lab_nav : lngDatas.news.lab_nav,
            isExt : false,
            genHeader: true,
        },{
            exact : false,
            path : "/"+lng+"/"+lngDatas.contacts.meta.slug,
            lab_nav : lngDatas.contacts.lab_nav,
            isExt : false,
            genHeader: false,
        }
    ];

    const linksSideNav = [
        {
            path : "https://blog.unifygroup.com/",
            exact : false,
            isExt: true,
            lab_nav : lngDatas.blog.lab_nav,
            genHeader:false,
        },
        {
            path : "/"+lng+"/"+lngDatas.jobs.meta.slug,
            exact : false,
            lab_nav : lngDatas.jobs.lab_nav,
            isExt : false,
            genHeader: true,
        },
    ];

    const routesHeader = [
        {
            path : "/"+lng+"/"+lngDatas.brands.meta.slug,
            exact : false,
            lab_nav : lngDatas.brands.lab_nav,
            component : Brands,
            genHeader: 2,
        },
        {
            path : "/"+lng+"/"+lngDatas.expertises.meta.slug,
            exact : false,
            lab_nav : lngDatas.expertises.lab_nav,
            component : Expertises,
            genHeader: 3,
        },
        {
            path : "/"+lng+"/"+lngDatas.universes.meta.slug,
            exact : false,
            lab_nav : lngDatas.universes.lab_nav,
            component : Universes,
            genHeader: 1,
        },
        {
            path : "/"+lng+"/"+lngDatas.news.meta.slug,
            exact : false,
            lab_nav : lngDatas.news.lab_nav,
            component : News,
            genHeader: 4,
        },
        {
            path : "/"+lng+"/"+lngDatas.about?.meta.slug,
            exact : false,
            lab_nav : lngDatas.about.lab_nav,
            component : AboutHomePage,
            genHeader: false,
        },
        {
            path : "https://blog.unifygroup.com/",
            exact : false,
            isExt: true,
            lab_nav : lngDatas.blog.lab_nav,
            genHeader: false,
        },
        {
            path : "/"+lng+"/",
            exact : true,
            lab_nav : "Home",
            component : Homepage,
            genHeader: false,
        },{
            path : "/"+lng+"/"+lngDatas.brands.meta.slug,
            exact : false,
            lab_nav : lngDatas.brands.lab_nav,
            component : Brands,
            genHeader: 2,
        },{
            path : "/"+lng+"/"+lngDatas.expertises.meta.slug,
            exact : false,
            lab_nav : lngDatas.expertises.lab_nav,
            component : Expertises,
            genHeader: 3,
        },{
            path : "/"+lng+"/"+lngDatas.jobs.meta.slug,
            exact : false,
            lab_nav : lngDatas.jobs.lab_nav,
            component: Jobs,
            genHeader: 1,
        },{
            path : "/"+lng+"/"+lngDatas.news.meta.slug,
            exact : false,
            lab_nav : lngDatas.news.lab_nav,
            component : News,
            genHeader: 4,
        },
        {
            path : "/"+lng+"/"+lngDatas.contacts.meta.slug,
            exact : false,
            lab_nav : lngDatas.contacts.lab_nav,
            component : Contacts,
            genHeader: false,
        },
        {
            path : "/"+lng+"/"+lngDatas.generic.legals.meta.slug,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_legals,
            component: Legals,
            genHeader: false,
        },{
            path : "/"+lng+"/"+lngDatas.generic.cookies.meta.slug,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_cookies,
            component: Cookies,
            genHeader: false,
        },{
            path : "/"+lng+"/"+lngDatas.generic.footer.slug_tos,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_tos,
            component: Tos,
            genHeader: false,
        },
    ];


    let tosId = (lngDatas.generic.footer.file_tos)?lngDatas.generic.footer.file_tos._id:null;
    let tosFile = (tosId !== "undefined")?findNestedObj(props.uploads, '_id', tosId):'';
    let tosSrc =  ''; //(tosFile && tosFile.trim())?tosFile.staticPath:'';

    let techspecsId = (lngDatas.generic.footer.file_techspecs)?lngDatas.generic.footer.file_techspecs._id:null;
    let techspecsFile = (techspecsId !== "undefined")?findNestedObj(props.uploads, '_id', techspecsId):'';
    let techspecsSrc = (techspecsFile && techspecsFile.trim())?techspecsFile.staticPath:'';

    let mediaId = (lngDatas.generic.footer.file_media)?lngDatas.generic.footer.file_media._id:null;
    let mediaFile = (mediaId !== "undefined")?findNestedObj(props.uploads, '_id', mediaId):'';
    let mediaSrc = (mediaFile && mediaFile.trim())?mediaFile.staticPath : '';

    const routesFooter = [
        {
            path : "/"+lng+"/"+lngDatas.generic.legals.meta.slug,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_legals,
            url : "",
            isDownloadable : false,
            isExt : false
        },{
            path : "/"+lng+"/"+lngDatas.generic.cookies.meta.slug,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_cookies,
            url : "",
            isDownloadable : false,
            isExt : false
        },{
            path : "/"+lng+"/"+lngDatas.generic.footer.slug_tos,
            exact : false,
            lab_nav : lngDatas.generic.footer.lab_link_tos,
            isDownloadable : true,
            url : tosSrc,
            isExt : true,
            isModal : {
                fn : openTos.bind(this, lngDatas, props)
            }
        }
    ];

    const footerMenuLinks = [

    ];

    const footerMenuCopy = [

    ];



    let classnav = '';
    if(location.pathname !== "/"+lng+"/"){
        //console.log('match');
        classnav = 'iswhite';
    } else {
        //console.log('not match');
        classnav = '';
    }


    console.log('location.pathname', location.pathname);
    return(
        <>
        {showLoader &&
            <Preloader
                loadingState={loading}
                setShowLoader={setShowLoader}
                setShowContent={setShowContent}
            />
        }

        <div className={`container-fluid p-0 unify ${showContent ? '':'hidden'}`}>
            <div className="cursor">
            </div>
            <div className="translated">
                <HeaderNavigation
                    RenderNavLng={RenderNavLng}
                    routesHeader={linksHeader}
                    languages={props.languages}
                    linksHeader={linksHeader}
                    linksSideNav={linksSideNav}
                    lngDatas={lngDatas}
                    toggleNav={toggleNav}
                    lng={lng}
                    classnav={classnav}
                    navigationState={navigationState}
                    handlePanel={handleNavigationPanel}
                    panelState={navigationOpenState}
                    handleContactPanel={handleContactPanel}
                    contactPanelState={contactOpenState}

                />
                <div className="row m-0 content">
                    <Switch>
                        {routesHeader.map( (route, index) => (
                            <Child
                                key={index}
                                {...route}
                                datas={{
                                    ...datas,
                                    blog: props.blog,
                                }}
                                lngDatas={lngDatas}
                                loc={route.location}
                                appControls={appControls}
                                paramsLocation={paramsLocation}
                                footerMenuCopy={footerMenuCopy}
                                footerMenuLinks={footerMenuLinks}
                                locationPath={location.pathname}
                            />
                        ))}
                    </Switch>
                </div>
                {location.pathname != routesFooter[0].path && location.pathname != routesFooter[1].path &&
                <DidomiSDK
                  apiKey="e14afd68-12e7-4635-b8a2-08150c644ef2"
                  iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
                  gdprAppliesGlobally={true}
                  sdkPath="https://sdk.privacy-center.org/"
                  embedTCFStub={true}
                  onReady={(didomi) => { onDidomiReady(didomi) } }
                  onConsentChanged={cwtToken => console.log('A consent has been given/withdrawn', cwtToken)}
                  onNoticeShown={() => console.log('Didomi Notice Shown')}
                  onNoticeHidden={() => console.log('Didomi Notice Hidden')}
                  onNoticeBackdropclick={() => console.log('Didomi Notice Backdrop Click')}
                  onNoticeClickAgree={() => {

                        window.dataLayer = window.dataLayer || [];
                        function gtag(){window.dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'UA-148137896-1');
                    }

                  }
                  onNoticeClickMoreInfo={() => console.log('Didomi Notice Click More Info')}
                  onPreferencesClickAgreeToAll={() => console.log('Didomi Preferences Click Agree to All')}
                  onPreferencesClickDisagreeToAll={() => console.log('Didomi Preferences Click Disagree to All')}
                  onPreferencesClickPurposeAgree={purposeId => console.log('Didomi Preferences Click Purpose Agree', purposeId)}
                  onPreferencesClickPurposeDisagree={purposeId => console.log('Didomi Preferences Click Purpose Disagree', purposeId)}
                  onPreferencesClickViewVendors={() => console.log('Didomi Preferences Click View Vendors')}
                  onPreferencesClickSaveChoices={() => console.log('Didomi Preferences Click Save Choices')}
                  onPreferencesClickVendorAgree={vendorId => console.log('Didomi Preferences Click Vendor Agree', vendorId)}
                  onPreferencesClickVendorDisagree={vendorId => console.log('Didomi Preferences Click Vendor Disagree', vendorId)}
                  onPreferencesClickVendorSaveChoices={() => console.log('Didomi Preferences Click Vendor Save Choices')}
                />
                }
            </div>
        </div>
        </>
    )
}

function RenderHeaderNav(props){
    return props.routes.map((route, index)=>{
        return (route.lab_nav !=="")?(
            <li key={`nav-item-${index}`} className="col-12 col-md-auto p-0">
                {!route.isExt ? (
                    !route.isModal ? (
                        <NavLink exact={route.exact} to={route.path} activeClassName="selected"><span>{route.lab_nav}</span></NavLink>
                    ) : (
                        <button onClick={route.isModal.fn}><span>{route.lab_nav}</span></button>
                    )

                ) : (
                    <a href={route.path} target="blank">
            <span>
              {route.lab_nav}
            </span>
                    </a>
                )}
            </li>
        ) : '';
    });
}

function RenderFooterNav(props){
    return props.routes.map((route, index)=>{
        return(
            <li key={`nav-item-${index}`} className="col-12 col-md-auto ">
                {route.isDownloadable ? (
                    <a href={route.url} download={route.isDownloadable} target={route.isExt ? "_blank" : "_self"} className={route.isDownloadable ? "download" : ""} >
                        <span>
                          {route.lab_nav}
                        </span>
                    </a>  
                ) : (
                    <a href={route.path} >
                        <span>
                          {route.lab_nav}
                        </span>
                    </a>
                )
                }
            </li>
        )
    });
}

function RenderNavLng(props){
    return props.languages.map((item, index)=>{
        return(
            <li key={`lngnav-item-${item._id}`} className="col-auto m-0">
                <NavLink to={`/${item.name.data}/`} activeClassName="selected">{item.name.short}</NavLink>
            </li>
        )
    });
}

function Child(datasObj) {
    //console.log(datasObj);
    return (
        <Route
            path={datasObj.path}
            render={props => {
                return(
                    // pass the sub-routes down to keep nesting
                    <datasObj.component
                        {...props}
                        didomiObject={didomiObject}
                        fnNav={closeNav}
                        fnContact={openContact}
                        lngDatas={datasObj.lngDatas}
                        datas={datasObj.datas}
                        loc={datasObj.loc}
                        appControls={datasObj.appControls}
                        paramsLocation={datasObj.paramsLocation}
                        footerMenuCopy={datasObj.footerMenuCopy}
                        footerMenuLinks={datasObj.footerMenuLinks}
                        locationPath={datasObj.location.pathname}
                    />
                )
            }}
        />
    )
}

export default class App extends Component {
    constructor(props) {
        super(props);

        this.blog = {};
        this.blog.tags = [];
        this.blog.articles = [];

        this.state = {
            blog: {
                tags: [],
                articles: [],
                dispositifs: [],
                offers: [],
                topics: [],
            }
        };

        //console.log(this.props);

    }

    componentDidMount() {
    //     Promise.all([
    //         fetch('https://api.hubapi.com/cms/v3/blogs/tags?hapikey=f12aa845-ace8-4627-a904-d19d95cd7d02&limit=100'),
    //         fetch('https://api.hubapi.com/content/api/v2/blog-posts?hapikey=f12aa845-ace8-4627-a904-d19d95cd7d02&limit=100'),
    //         fetch('https://api.hubapi.com/blogs/v3/topics?hapikey=f12aa845-ace8-4627-a904-d19d95cd7d02&limit=100'),
    //     ]).then(  (responses) => {
    //         return Promise.all(responses.map(function (response) {
    //             return response.json();
    //         }));
    //     }).then( (data) => {
    //         const regexOffres = new RegExp(/\/offres\//);
    //         const regexDispositifs = new RegExp(/\/success-stories\//);
    //         let offers = [];
    //         let dispositifs = [];
    //         let articles = [];

    //         data[1].objects.map((item)=>{
    //             if ( regexOffres.test(item.absolute_url) ) {
    //                 offers.push(item);
    //             } else if ( regexDispositifs.test(item.absolute_url) ) {
    //                 dispositifs.push(item);
    //             } else {
    //                 articles.push(item);
    //             }
    //         });


            
    //         this.setState({blog: {...this.state.blog, tags:data[0].results }} );
    //         this.setState({blog: {...this.state.blog, topics:data[2].objects }});
    //         this.setState({blog: {...this.state.blog, articles:articles }});
    //         this.setState({blog: {...this.state.blog, dispositifs:dispositifs }});
    //         this.setState({blog: {...this.state.blog, offers:offers }});
    //     }).catch( (error) => {
    //         // if there's an error, log it
    //     });
    }


    render() {
        return (
            <Router>
                <Switch>
                    <Route
                        strict path="/:lng/"

                        render={(props) => {

                            return(
                                <RenderLngCtn {...this.props} />
                            )
                        }}
                    />
                    <Redirect strict from="" to={`/${this.props.dfltLng.name.data}/`} />
                    <Redirect strict from="/:lng" to="/:lng/" />
                </Switch>
            </Router>
        )
    }
}
