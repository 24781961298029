import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from 'react-svg';
import {Helmet} from "react-helmet";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Route,
    Redirect,
    useRouteMatch,
    useParams,
    useLocation
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import {TimelineMax,TweenMax} from "gsap";
import {gsap} from "gsap";

export default class BlobsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.blobs = {};
        this.state.refs = {};
        this.state.refs.blobs = React.createRef();
        this.state.refs.item = React.createRef();
        this.targets = [
            ["#header__BlobIntro1_1", "#header__BlobIntro1_2"],
            ["#header__BlobIntro2_1", "#header__BlobIntro2_2"],
            ["#header__BlobIntro3_1", "#header__BlobIntro3_2"]
        ];
        this.tlblob1 = false;
        this.tlblob2 = false;
        this.tlblob3 = false;
        this.TimelineShouldStop = false;
    }

    componentDidUpdate(prevProps) {
        this.getCurrentItem();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        //this.killAnimationBlobs();
        return true;
    }

    componentDidMount() {
        this.init();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.current === this.props.current) {
            return false;
        } else {
            return true;
        }
    }

    init() {
        this.state.blobs.targets = [...this.state.refs.blobs.current.querySelectorAll('.itemPath')];
        this.getCurrentItem();
    }

    async getCurrentItem() {
        let newArray = this.props.pathes.filter(el => {
            return el.path === this.props.current;
        });
        if (newArray.length > 0) {
            this.state.active = true;
            this.state.blobs = newArray[0].blobs;
            this.state.refs.item.current.classList = "blobs__header row m-0 content";
            await this.killAnimationBlobs();
            await this.switchBlobs();
            await this.animateBlobs();
        } else {
            this.state.active = false;
            this.state.refs.item.current.classList = "blobs__header row m-0 content hid";
        }
    }

    switchBlobs() {
        return new Promise(resolve => {
            const timing = 1;// seconds
            const delay = 1;
            let that = this;
            for (let i = 0; i < this.targets.length; i++) {
                for (let j = 0; j < this.targets[i].length; j++) {
                    //let target = this.state.refs.blobs.current.querySelector(this.targets[i][j]);
                    //TweenMax.to(target, {duration: 1, morphSVG: {shape: that.state.blobs[i][j], type: "rotational", ease: "ease-in"}});
                }
            }
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    }

    animateBlobs() {
        return new Promise(resolve => {
            this.tlblob1 = new TimelineMax({onComplete: '', repeat: -1, repeatDelay: 3, yoyo: true});
            this.tlblob1.to("#header__BlobIntro1_1", {
                duration: 7,
                morphSVG: {shape: "#header__BlobIntro1_2", type: "rotational"}
            }).play();

            this.tlblob2 = new TimelineMax({onComplete: '', repeat: -1, repeatDelay: 2, yoyo: true});
            this.tlblob2.to("#header__BlobIntro2_1", {
                duration: 7,
                morphSVG: {shape: "#header__BlobIntro2_2", type: "rotational"}
            }).play();

            this.tlblob3 = new TimelineMax({onComplete: '', repeat: -1, repeatDelay: 1, yoyo: true});
            this.tlblob3.to("#header__BlobIntro3_1", {
                duration: 7,
                morphSVG: {shape: "#header__BlobIntro3_2", type: "rotational"}
            }).play();
            resolve(true);
        });
    }

    killAnimationBlobs() {
        return new Promise(resolve => {
            if (this.tlblob1, this.tlblob2, this.tlblob3) {
                this.tlblob1.kill();
                this.tlblob2.kill();
                this.tlblob3.kill();
            }
            this.tlblob1 = false;
            this.tlblob2 = false;
            this.tlblob3 = false;
            resolve(true);
        });


    }

    render() {
        return (
            <section className="blobs__header row m-0 content" ref={this.state.refs.item}>
                <div className="col-12 page">
                    <div className="row m-0">
                        <div className="col-12 mx-auto p-0 block-l">
                            <div className="zone-blobs">
                                <div className="blobs" ref={this.state.refs.blobs}>
                                    <div className="blob blob-1">
                                        <svg version="1.1" id="header__BlobIntro1" xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                             viewBox="0 0 1403 265" style={{enableBackground: 'new 0 0 1403 265'}}
                                             xmlSpace="preserve">
                                            <g>
                                                <linearGradient id="header__BlobIntro1Grad"
                                                                gradientUnits="userSpaceOnUse" x1="407.1291"
                                                                y1="-470.4952" x2="717.624" y2="-3.7024">
                                                    <stop offset="0" style={{stopColor: '#F2F2F2', stopOpacity: 0}}/>
                                                    <stop offset="1" style={{stopColor: '#F2F2F2'}}/>
                                                </linearGradient>
                                                <path id="header__BlobIntro1_1" className="itemPath blobintro1grad"
                                                      style={{
                                                          fillRule: 'evenodd',
                                                          clipRule: 'evenodd',
                                                          fill: 'url(#header__BlobIntro1Grad)',
                                                          fillOpacity: 0.1
                                                      }}
                                                      d="M0,0c10.8,56.4,35.5,111.2,77.6,159.6c116.6,134.1,435.9,139.2,957.6,15.3C1202.6,120.2,1313.8,61.9,1369.1,0 H0z"/>
                                                <path id="header__BlobIntro1_2" className="itemPath blobintro1grad"
                                                      style={{
                                                          fillRule: 'evenodd',
                                                          clipRule: 'evenodd',
                                                          fill: 'url(#header__BlobIntro1Grad)',
                                                          fillOpacity: 0.1
                                                      }}
                                                      d="M-4.6,0c6.2,57.4,28.2,113.1,70.8,162.1c116.6,134.1,454.1,128.3,1012.5-17.6 c160.2-41.2,268.1-89.3,323.8-144.5H-4.6z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="blob blob-2">
                                        <svg version="1.1" id="header__BlobIntro2" xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                             viewBox="0 0 1485 294" style={{enableBackground: 'new 0 0 1485 294'}}
                                             xmlSpace="preserve">
                                            <g>
                                                <linearGradient id="header__BlobIntro2Grad"
                                                                gradientUnits="userSpaceOnUse" x1="407.1291"
                                                                y1="-470.4952" x2="717.624" y2="-3.7024">
                                                    <stop offset="0" style={{stopColor: '#7225F1', stopOpacity: 0.99}}/>
                                                    <stop offset="0.2107" style={{stopColor: '#8E38ED'}}/>
                                                    <stop offset="0.4801" style={{stopColor: '#A43BED'}}/>
                                                    <stop offset="0.7483" style={{stopColor: '#BF35C9'}}/>
                                                    <stop offset="1" style={{stopColor: '#F02988'}}/>
                                                </linearGradient>
                                                <path id="header__BlobIntro2_1"
                                                      style={{fill: 'url(#header__BlobIntro2Grad)'}}
                                                      className="itemPath blobintro2grad"
                                                      d="M12.3,0C20.8,63,46.3,124.7,93,178.5c116.6,134.1,435.9,139.2,957.6,15.3C1269.4,128.8,1409,64.2,1469.4,0 H12.3z"/>
                                                <path id="header__BlobIntro2_2"
                                                      style={{fill: 'url(#header__BlobIntro2Grad)', fillOpacity: 0}}
                                                      className="itemPath blobintro2grad"
                                                      d="M12,0c1.6,79.6,26.5,152.3,82.5,198.8c114.7,95.2,446.7,79.6,996.1-46.8c196.6-45,323.4-95.7,380.5-152H12z"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="blob blob-3">
                                        <svg version="1.1" id="header__BlobIntro3" xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                             viewBox="0 0 1512 304" style={{enableBackground: 'new 0 0 1512 304'}}
                                             xmlSpace="preserve">
                                            <g>
                                                <linearGradient id="header__BlobIntro3Grad"
                                                                gradientUnits="userSpaceOnUse" x1="407.1291"
                                                                y1="-470.4952" x2="717.624" y2="-3.7024">
                                                    <stop offset="0" style={{stopColor: '#7225F1', stopOpacity: 0}}/>
                                                    <stop offset="1" style={{stopColor: '#FFFFFF'}}/>
                                                </linearGradient>
                                                <path style={{
                                                    fillRule: 'evenodd',
                                                    clipRule: 'evenodd',
                                                    fill: 'url(#header__BlobIntro3Grad)',
                                                    fillOpacity: 0.15
                                                }} id="header__BlobIntro3_1" className="itemPath blobintro3grad"
                                                      d="M0.4,0c12.3,47.2,37.1,95.5,77.2,143.3C285,390.1,581.6,295.4,998.3,225.4c280-47,443-133.7,513.7-225.4H0.4z"/>
                                                <path style={{
                                                    fillRule: 'evenodd',
                                                    clipRule: 'evenodd',
                                                    fill: 'url(#header__BlobIntro3Grad)',
                                                    fillOpacity: 0
                                                }} id="header__BlobIntro3_2" className="itemPath blobintro3grad"
                                                      d="M0.3,0c3.5,50,17.6,99.4,45,145c127.9,213,469.5,128,886.3,58.1C1206.7,157,1363,83.3,1455.6,0H0.3z"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
