import React, {Component} from "react";
import SectionStep from "../components/content/sectionStep";
import MediaComponent from "../partials/Media";
import PageHero from "../components/Hero";
import { ReactSVG } from "react-svg";
import Footer from "../components/Footer/Footer";
import GlideCarousel from '../../assets/scripts/GlideCarousel';
import Glide from '@glidejs/glide';

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}


export default class AboutHomePage extends Component {
    constructor(props) {
        super(props);
        this.refList = {
            goodCats : [],
        };
        this.state = {
            activeCat : 0,
            activeItem : 0
        };

        this.glideTalents = false;

    }

    componentDidMount() {
        this.props.appControls.handleNavigationPanel(false);
        if (this.props.lngDatas.about.talents && this.props.lngDatas.about.talents.list.length > 0 && document.querySelector('#talentsList')) {
            this.glideTalents = new Glide('#talentsList', {
                type: 'carousel',
                autoplay:2000,
                gap:48,
                startAt: 0,
                perView: 5,
                focusAt: 0,
                breakpoints: {
                    1024: {
                        gap:0,
                        focusAt: 0,
                        perView: 2,
                    },
                }
            }).mount();
        }
    }

    componentDidUpdate(){
        if(this.glideTalents){
            this.glideTalents.update()
        } 
    }


    renderMainMedia(version) {
        console.log('renderMainMedia version', version);
        // console.log('datas uploads', this.props.datas.uploads);
        let media_ = findNestedObj(this.props.datas.uploads, "_id", this.props.lngDatas?.about?.edito?.media?._id);
        // console.log('renderMainMedia _id : ', this.props.lngDatas.about.edito.media._id);
        // console.log('renderMainMedia object : ', media_);
        if ( media_ ) {
            return (
                <MediaComponent
                    media={media_}
                    version={version}
                />
            )
        }
    }

    renderGoodDisplayed(catIndex, index){

        let cat = this.props.lngDatas.about.good.list[catIndex];
        //console.log('renderGoodDisplayed / cat :', cat);
        let item = cat.items[index];
        //console.log('renderGoodDisplayed / item :', item);
        let logo = false;
        if(item && item.logo && item.logo._id && item.logo._id.trim()){
            logo = findNestedObj(this.props.datas.uploads, "_id", item.logo._id);
        }

        return(
            <div className="displayed-good">
                {logo &&
                    <MediaComponent
                        media={logo}
                        version={'original'}
                    />
                }
                {!logo && item && item.name && item.name.trim() &&
                    <p className="name">{item.name}</p>
                }
                {item && item.description && item.description.trim() &&
                    <p className="description">{item.description}</p>
                }
                {item && item.url && item.url.trim() &&
                    <a href={item.url} className="link" target="_blank">{this.props.lngDatas.generic.lab_cta_readmore}</a>
                }
            </div>
        )

    }

    displayGood(catIndex, index){
        this.setState({
            activeCat:catIndex,
            activeItem:index
        })
    }

    renderGoods(version){

        let media = findNestedObj(this.props.datas.uploads, "_id", this.props.lngDatas?.about?.good?.media?._id);
        
        return (
            <section className={'section content fullWidth containered  about__good'}>
                <div className={'good__media'}>
                    <div className={'good__mediaContainer'}>
                        {this.renderGoodDisplayed(this.state.activeCat, this.state.activeItem)}
                    </div>
                </div>
                <div className={'good__content'}>
                    {
                        this.props.lngDatas &&
                        this.props.lngDatas.about &&
                        this.props.lngDatas.about.good &&
                        this.props.lngDatas.about.good.surtitle &&
                        this.props.lngDatas.about.good.surtitle.trim() &&
                        <p className={'good__surtitle'}>{this.props.lngDatas.about.good.surtitle}</p>
                    }
                    {
                        this.props.lngDatas &&
                        this.props.lngDatas.about &&
                        this.props.lngDatas.about.good &&
                        this.props.lngDatas.about.good.title &&
                        this.props.lngDatas.about.good.title.trim() &&
                        <p className={'good__title'}>{this.props.lngDatas.about.good.title}</p>
                    }
                    <ul className={"good__list"}>
                        {this.props.lngDatas.about.good.list.map((cat, catIndex) => {
                            return (
                                <li 
                                    className={`good__cat ${this.state.activeCat === catIndex ? 'active':''}`}
                                    key={`goodsCat-${catIndex}`}
                                >
                                    <div className={"cat__head"} onClick={()=>{this.setState({activeCat:catIndex, activeItem:0})}}>
                                        <p className={"good__catName"}>{cat.title}</p>

                                        {(cat.items && cat.items.length && cat.items.length > 0 && cat.items[0].name && cat.items[0].name.trim() ) &&
                                        <button className={"cat__toggle"}>
                                            <ReactSVG
                                                className="arrow"
                                                src="/ui/icons/arrowdown.svg"
                                            />
                                        </button>
                                        }
                                    </div>
                                    {
                                        (cat.items && cat.items.length && cat.items.length > 0 && cat.items[0].name && cat.items[0].name.trim() ) &&

                                        <ul className={"good__catList"}>
                                            {cat.items.map((item, index) => {
                                                return (
                                                    <li key={catIndex+'-'+index} className={`good__catListItem ${this.state.activeItem === index ? 'active':''}`}>
                                                        <span 
                                                            onClick={this.displayGood.bind(this, catIndex, index)}
                                                        >
                                                            {item.name}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    }

                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
        )
    }

    renderTalents(version){
        console.log('this.props.lngDatas', this.props.lngDatas);
        return this.props.lngDatas.about.talents.list.map((item, index) => {
            let visual = false;
            if(item.visual && item.visual._id && item.visual._id.trim()){
                visual = findNestedObj(this.props.datas.uploads, "_id", item.visual._id);
            }
            return (
                <div className="glide__slide item-talents" key={`abouttalents-item-${index}`}>
                    <div className="item-talents-container">
                        {visual &&
                        <div className="item__visual">
                            <MediaComponent
                                media={visual}
                                version={version}
                            />
                        </div>
                        }
                        
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <>
            <section className={'col-12 page about__homepage'}>

                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    identity={false}
                    surtitle={{
                        type: 'text',
                        content: this.props.lngDatas.about.lab_nav,
                    }}
                    title={{
                        type: 'text',
                        content: this.props.lngDatas.about.hero,
                    }}
                    subtitle={{
                        type: 'button',
                        content:{
                            action: {
                                name: this.props.lngDatas.about.hero_cta,
                                ref: 'showreel',
                            }
                        },
                    }}
                    bottom={true}
                    module={'brand'}
                />
                {
                    this.props.lngDatas &&
                    this.props.lngDatas.about &&
                    this.props.lngDatas.about.keyFigures &&
                    this.props.lngDatas.about.keyFigures.length > 0 &&
                <section className={'section content content__keydata about__keyfigures'}>
                    <ul className={'keydatas'}>
                        {this.props.lngDatas.about.keyFigures.map((item, index) => {
                            return (
                                <li className={'keydata'} key={index}>
                                    <p className={'keydata__title'}>{item.figure}</p>
                                    <p className={'keydata__subtitle'}>{item.label}</p>
                                    <p className={'keydata__light'}>{item.description}</p>
                                    <p className={'keydata__xs'}>{item.source}</p>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                }


                { this.props.lngDatas?.about?.introduction?.content &&
                <section className={'section content content__step about__introduction'}>
                    <p className={'section__title'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.about.introduction.title}}></p>
                    <div className={'step__content'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.about.introduction.content}}></div>
                </section>
                }

                <section className={'section edito about__edito'}>
                    <span className={'section__surtitle'}
                          dangerouslySetInnerHTML={{__html: this.props.lngDatas.about.edito.surtitre}}></span>
                    <h2 className={'section__title'}
                        dangerouslySetInnerHTML={{__html: this.props.lngDatas.about.edito.title}}></h2>
                    <p className={'edito__content'}
                       dangerouslySetInnerHTML={{__html: this.props.lngDatas.about.edito.content}}></p>

                </section>


                { this.props.lngDatas?.about?.edito?.media?._id &&
                <div className={'edito__media about__media'}>
                    {this.renderMainMedia('original')}
                </div>
                }

                {  
                    this.props.lngDatas &&
                    this.props.lngDatas.about &&
                    this.props.lngDatas.about.carousel &&
                    this.props.lngDatas.about.carousel.length > 0 &&
                <div className={"edito__content--carousel about__carousel"}>
                    <div className={'carousel__controleur'}>
                        <ul className={'controleur__items'}>
                            { this.props.lngDatas.about.carousel.map((item,index)=>{
                                return (
                                    <li key={`about-crsl-iteml-${index}`} onMouseEnter={()=>{this.setState({activeCat:index})}} className={`controleur__item ${this.state.activeCat === index ? 'active':''}`}>
                                        <p className={'item__title'}>{item.tag}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={'carousel__contents'}>
                        <ul className={'contents__items'}>
                            { this.props.lngDatas.about.carousel.map((item,index)=>{
                                return (
                                    <li key={`about-crsl-cnt-${index}`}  className={`contents__item ${this.state.activeCat === index ? 'active':''}`}>
                                        <p className={'item__title'} dangerouslySetInnerHTML={{__html:item.title}}></p>
                                        <p className={'item__controleur'} dangerouslySetInnerHTML={{__html:item.tag}}></p>
                                        <p className={'item__content'} dangerouslySetInnerHTML={{__html:item.content}}></p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>

                </div>
                }

                <section className={'section content content__listCenter about__list'}>
                    {
                        this.props.lngDatas &&
                        this.props.lngDatas.about &&
                        this.props.lngDatas.about.brandformance &&
                        this.props.lngDatas.about.brandformance.title &&
                        this.props.lngDatas.about.brandformance.title.trim() &&
                    <h2 className={'section__title'}>{this.props.lngDatas.about.brandformance.title}</h2>
                    }
                    {
                        this.props.lngDatas &&
                        this.props.lngDatas.about && 
                        this.props.lngDatas.about.brandformance && 
                        this.props.lngDatas.about.brandformance.list &&
                        this.props.lngDatas.about.brandformance.list.length > 0 &&
                    <ul className={'list__items'}>
                        {this.props.lngDatas.about.brandformance.list.map((item,index)=>{
                            return (
                                <li className={'item'}key={`brandformance-item-${index}`}>
                                    <div className={'item__index'}>
                                        <span>0{index+1}</span>
                                    </div>
                                    <p className={'item__content'} dangerouslySetInnerHTML={{__html:item}}></p>
                                </li>
                            )
                        })}
                    </ul>
                    }
                </section>

                {this.renderGoods('original')}

                <section className={'section content fullWidth about__talents'}>
                    <div className={'talents__content'}>
                        {
                            this.props.lngDatas &&
                            this.props.lngDatas.about &&
                            this.props.lngDatas.about.talents &&
                            this.props.lngDatas.about.talents.title &&
                            this.props.lngDatas.about.talents.title.trim() &&
                            <p className={'talents__title'}>{this.props.lngDatas.about.talents.title}</p>
                        }
                    </div>
                    <div className="glide" id={'talentsList'}>
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {this.renderTalents('original')}
                            </ul>
                        </div>
                    </div>
                </section>

            </section>
                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.lngDatas.news.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.news.meta.slug,
                            },
                            prev: {
                                title: this.props.lngDatas.universes.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.universes.meta.slug,
                            }
                        }
                    }
                />
                </>
        )
    }
}
