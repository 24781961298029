import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";


export default class Privpol extends Component {
  constructor(props) {
    super(props);


  }

  componentDidMount(){
    gsap.registerPlugin(MorphSVGPlugin);
    this.props.fnNav();

    document.querySelector('header.header').classList.remove('light-theme');
  }

  renderMetas(){

    let title = (this.props.lngDatas.generic.privpol.meta.title !=="")? this.props.lngDatas.generic.privpol.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
    let description = (this.props.lngDatas.generic.privpol.meta.description !=="")? this.props.lngDatas.generic.privpol.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
    //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.expertises.meta.keywords !=="")? this.props.lngDatas.expertises.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
    let slug = this.props.lngDatas.expertises.meta.slug;
    return(
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
      </Helmet>
    )
  }

  render() {
    return (
      <section className="col-12 page common">
        <div className="col-12 zone-blob block-l">
          <div className="blobs">

            <ReactSVG
              className="blob layer1"
              src="/ui/blobs/svg/news_introduction_1.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                }
                let blob1 = gsap;
                let tlblob1 = blob1.timeline({onComplete: '', repeat: -1, repeatDelay: 3, yoyo: true});

                tlblob1
                  .to("#BlobIntro1_1", { duration: 7, morphSVG: { shape: "#BlobIntro1_2", type: "rotational"}})
                  .play(); 
              }}
            />
            <ReactSVG
              className="blob layer2"
              src="/ui/blobs/svg/news_introduction_2.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                }
                let blob2 = gsap;
                let tlblob2 = blob2.timeline({onComplete: '', repeat: -1, repeatDelay: 2, yoyo: true});

                tlblob2
                  .to("#BlobIntro2_1", { duration: 7, morphSVG: { shape: "#BlobIntro2_2", type: "rotational"}})
                  .play();   
              }}
            />
            <ReactSVG
              className="blob layer3"
              src="/ui/blobs/svg/news_introduction_3.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                }
                let blob3 = gsap;
                let tlblob3 = blob3.timeline({onComplete: '', repeat: -1, repeatDelay: 1, yoyo: true});

                tlblob3
                  .to("#BlobIntro3_1", { duration: 7, morphSVG: { shape: "#BlobIntro3_2", type: "rotational"}})
                  .play();   
              }}
            />
          </div>
        </div>
        <div className="col-12 mx-auto p-0 block">
          {this.renderMetas()}        
          <div className="header">
            <h1 className="title">
              <span>
                {this.props.lngDatas.generic.footer.lab_link_privpol}
              </span>
            </h1>
            <h1 className="subtitle">
              <span>
                {this.props.lngDatas.generic.privpol.subtitle}
              </span>
            </h1>
          </div>
          <div className="row editorial justify-content-start">
            <div className="col-12 col-lg-8 p-0">
                <div dangerouslySetInnerHTML={{__html:this.props.lngDatas.generic.privpol.text}}></div>
            </div>

          </div>
        </div>
      </section>
    )
  }
}
