import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import PageHero from "../components/Hero";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

export default class BrandsHomepage extends Component {
  constructor(props) {
    super(props);


    this.state={
      showfilters:false,
      currentFilterLabel: 'all',
    }

    console.log('BrandsHomepage props', props);
  }

  componentDidMount(){
    gsap.registerPlugin(MorphSVGPlugin);
    gsap.registerPlugin(DrawSVGPlugin);

  }

  renderMetas(){

    let title = (this.props.lngDatas.brands.meta.title !=="")? this.props.lngDatas.brands.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
    let description = (this.props.lngDatas.brands.meta.description !=="")? this.props.lngDatas.brands.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
    //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.brands.meta.keywords !=="")? this.props.lngDatas.brands.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
    let slug = this.props.lngDatas.brands.meta.slug;
    return(
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
      </Helmet>
    )
  }


  renderItems(filterReq){
    let items = this.props.datas.brands;
    let types = this.props.datas.types;
    let files = this.props.datas.uploads; 
    let lngDatas = this.props.lngDatas;
    let lng = lngDatas.name.data;

    let sorted = [...items].sort((a, b) => a.order[`${lngDatas.name.data}`] - b.order[`${lngDatas.name.data}`]);

    sorted = sorted.filter( (item)=> {
      // obtenir le label du filtre de l'item
      let current = this.props.datas.types.filter((fil)=>{
        return fil._id === item.identity.type._id;
      });
      return filterReq.includes(current[0].label);
      //return current[0].label === this.state.currentFilterLabel
    });

    console.log('filters: ', filterReq, 'sorted :', sorted);


    let itemsList = [];
    let temp_list = [];
    if ( window.innerWidth > 1024 ) {
      sorted.map((item,key)=>{
        temp_list.push(item);
        if ( temp_list.length === 7 || key === sorted.length-1 ) {
          itemsList.push(temp_list);
          temp_list = [];
        }
      });
    } else {
      itemsList.push(sorted);
    }


    return itemsList.map((colitems,colindex)=>{
      return (
          <div className={'col-brands'} key={`col-brands-${colindex}`}>
            {colitems.map((item,key)=>{
              let imgSrc="";
              if(item.identity.logoSVG && item.identity.logoSVG._id){
                let img = item.identity.logoSVG._id;
                let file = findNestedObj(files, '_id', img);
                //console.log(file);
                if(file){
                  imgSrc = file.staticPath;
                }
              }
              let typeLabel = types.find(type => type._id === item.identity.type._id).label;
              return (
                  <Link className="item-brand" data-filter={typeLabel} key={`brands-item-${key}`} to={"/"+lng+"/"+lngDatas.brands.meta.slug+"/"+item.meta[`${lngDatas.name.data}`].slug}>
                    {/*<span>{item.order[`${lngDatas.name.data}`]}</span>*/}
                    {item.identity.logoSVG &&
                    <ReactSVG
                        className={'logo'}
                        src={imgSrc}
                    />
                    }
                  </Link>
              )
            })}
          </div>
      )
    });
  }


  iniFooterNavCTAs(svg){
    new CircleCtaAnim(svg);
  }

  renderFooterNav(){
    return(
    <ul className="row w-100 justify-content-center navigation">
      <li className="col-12 col-md-6 align-self-start previous">
        <Link className="cta hiddenCursor" to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.universes.meta.slug}>
          <span className="text">
            <span className="label">
              {this.props.lngDatas.generic.lab_cta_next}
            </span>
            <span className="name">
              {this.props.lngDatas.universes.lab_nav}
            </span>
          </span>
          <div className="circle">
            <ReactSVG
              className="circlesvg"
              src="/ui/ctas/a_circle_full.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                } else {
                  this.iniFooterNavCTAs(svg)
                }
              }}
            />
          </div>
        </Link>
      </li>
      <li className="col-12 col-md-6 align-self-end next">
        <Link className="cta hiddenCursor" to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.expertises.meta.slug}>
          <div className="circle">
            <ReactSVG
              className="circlesvg"
              src="/ui/ctas/a_circle_full.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                } else {
                  this.iniFooterNavCTAs(svg)
                }
              }}
            />
          </div>
          <span className="text">
            <span className="label">
              {this.props.lngDatas.generic.lab_cta_next}
            </span>
            <span className="name">
              {this.props.lngDatas.expertises.lab_nav}
            </span>
          </span>
        </Link>
      </li>
    </ul>
    )
  }




  filterItems(label) {

    this.setState({currentFilterLabel: label});

    /*let filters = document.querySelectorAll('.filter-btn');
    let filterActive = document.querySelectorAll('button[data-filter='+label+']');
    let items = document.querySelectorAll('.item');
    let filtered = document.querySelectorAll('.item[data-filter='+label+']');

    //console.log(items);
    if(label==="all"){
      this.state.currentFilterLabel = 'tous';
      items.forEach(function(item){
        item.classList.remove('hide');
      });
      filters.forEach(function(item){
        item.classList.remove('active');
      });
      filterActive.forEach(function(item){
        item.classList.add('active');
      });
    } else {
      this.state.currentFilterLabel = label;
      items.forEach(function(item){
        item.classList.add('hide');
      });
      filtered.forEach(function(item){
        item.classList.remove('hide');
      });
      filters.forEach(function(item){
        item.classList.remove('active');
      });
      filterActive.forEach(function(item){
        item.classList.add('active');
      });
    }*/
  }

  toggleFilters(){
    /*const self = this;

    const dropdown = document.getElementById('dropdownFilter');

    if(!this.state.showfilters) {
      this.setState({
        showfilters: true
      });
      dropdown.classList.add('unfold');

      document.addEventListener('click', function switchTog(event){


        let starter = event.target;

        while(starter !== dropdown && starter.parentNode !== null){
          starter = starter.parentNode;
        }

        if(starter !== dropdown){
          self.setState({
            showfilters: false
          })
          dropdown.classList.remove('unfold');
          document.removeEventListener('click', switchTog, true);
        }

      }, true)
    } else{
      self.setState({
        showfilters: false
      });
      dropdown.classList.remove('unfold');
    }*/
  }

  renderFilters(){
    let items = this.props.datas.types;

    return items.map((item, index)=>{
      return(
        <li key={`brands-filter-${index}`}>
          <button className={`filter-btn ${this.state.currentFilterLabel === item.label ? 'active':''}`} data-filter={item.label} onClick={this.filterItems.bind(this, item.label)}>
            <span>
              {item.name[`${this.props.lngDatas.name.data}`]}
            </span>
          </button>
        </li>
      )
    });   
  }

  render() {
    return (

      <div className="col-12 page homepage brands-homepage">

        <PageHero
            lngDatas={this.props.lngDatas}
            datas={this.props.datas}
            identity={false}
            surtitle={{
              type: 'text',
              content: this.props.lngDatas.brands.lab_nav,
            }}
            title={{
              type: 'text',
              content: this.props.lngDatas.brands.hero,
            }}
            bottom={true}
            module={'brand'}
        />

        <section className={'brands__introduction section content'}>
          <p className={'section__title'}>{this.props.lngDatas.brands?.introduction?.title}</p>
          <p>{this.props.lngDatas.brands?.introduction?.content}</p>
        </section>

        <div className="section presentation">
            <div className="row mx-0 w-100">
              {this.props.lngDatas.brands?.filter1intro?.title && this.props.lngDatas.brands?.filter1intro?.title.trim() &&
                <p className={'section__title'}>{this.props.lngDatas.brands?.filter1intro?.title}</p>
              }
              {this.props.lngDatas.brands?.filter1intro?.content && this.props.lngDatas.brands?.filter1intro?.content.trim() &&
                <p className={'section__description'}>{this.props.lngDatas.brands?.filter1intro?.content}</p>
              }
              {/* 
                <div className="col-auto">
                  <h4 className="filter-btn">{this.props.datas.types[0].name[`${this.props.lngDatas.name.data}`]} / {this.props.datas.types[2].name[`${this.props.lngDatas.name.data}`]}</h4>
                </div>
              */}
            </div>
        </div>

        <div className="brands-list section">
          {this.renderItems([this.props.datas.types[0].label, this.props.datas.types[2].label])}
        </div>

        <div className="section presentation">
            <div className="row mx-0 w-100">
              {this.props.lngDatas.brands?.filter2intro?.title && this.props.lngDatas.brands?.filter2intro?.title.trim() &&
                <p className={'section__title'}>{this.props.lngDatas.brands?.filter2intro?.title}</p>
              }
              {this.props.lngDatas.brands?.filter2intro?.content && this.props.lngDatas.brands?.filter2intro?.content.trim() &&
                <p className={'section__description'}>{this.props.lngDatas.brands?.filter2intro?.content}</p>
              }
              {/* 
                <div className="col-auto">
                  <h4 className="filter-btn">{this.props.datas.types[1].name[`${this.props.lngDatas.name.data}`]}</h4>
                </div>
              */}
            </div>
        </div>

        <div className="brands-list section">
          {this.renderItems([this.props.datas.types[1].label])}
        </div>

        <Footer
            datas={this.props.datas}
            didomiObject={this.props.didomiObject}
            lngDatas={this.props.lngDatas}
            footerMenuLinks={this.props.footerMenuLinks}
            footerMenuCopy={this.props.footerMenuCopy}
            locationPath={this.props.locationPath}
            params={this.props.paramsLocation}
            appControls={this.props.appControls}
            custom={
              {
                next: {
                  title: this.props.lngDatas.expertises.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.expertises.meta.slug,
                },
                prev: {
                  title: this.props.lngDatas.jobs.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.jobs.meta.slug,
                }
              }
            }
        />


      </div>
    )
  }
}
