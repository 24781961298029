import React, { Component } from "react";
import ButtonCircle from "../../partials/buttonCircle";


export default class KeyData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className={'section content content__keydata'}>
                <ul className={'keydatas'}>
                    {this.props.items.map((item, index)=>{
                        return (
                            <li key={index} className={'keydata'}>
                                <p className={'keydata__title'}>{item.figure}</p>
                                <p className={'keydata__subtitle'}>{item.label}</p>
                                <p className={'keydata__light'}>{item.description}</p>
                                <p className={'keydata__xs'}>{item.source}</p>
                            </li>
                        )
                    })}
                </ul>
            </section>
        )
    }
}


