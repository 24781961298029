import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Link,
    Route,
    Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import PageHero from "../components/Hero";
import ArticlesList from "../components/articlesList";
import ButtonCircle from "../partials/buttonCircle";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class ExpertisesDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: (this.props.datas.blog?.offers)?this.props.datas.blog.offers:[],
            dispositifs: (this.props.datas.blog?.dispositifs)?this.props.datas.blog.dispositifs:[],
        }

        //console.log('in Home.js')

        console.log("/ ExpertisesDetail // props",this.props);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevProps.datas.blog?.offers !== this.props.datas.blog?.offers &&  this.props.datas.blog?.offers.length > 0 ) {
            this.setState({offers: this.props.datas.blog.offers});
        }
        if (prevProps.datas.blog?.dispositifs !== this.props.datas.blog?.dispositifs && this.props.datas.blog?.dispositifs.length > 0) {
            this.setState({dispositifs: this.props.datas.blog?.dispositifs});
        }
    }


    getRelatedOffers(source, currentSubject) {
        let offers = source;
        let tags = (this.props.datas.blog?.tags)?this.props.datas.blog?.tags:[];
        let relatedOffers = [];
        offers.map((offer) => {
            let offerTags = [];
            offer.meta.tag_ids.map((tagId) => {
                let _tags = tags.filter((tagItem) => {
                    return parseInt(tagItem.id) === tagId;
                });
                _tags.map((_tag) => {
                    offerTags.push(_tag);
                });
            });
            offerTags.map((offerTagItem) => {
                if (offerTagItem.name.toLowerCase() === currentSubject.toLowerCase()) {
                    relatedOffers.push(offer);
                }
            });
        });
        return relatedOffers;
    }

    renderDispositifs(source){
        let items = this.getRelatedOffers(this.state.dispositifs, source);
        console.log('items', items);
        if(items && items.length > 0){
            return(
            <div className={'latest__devices'}>
                <ArticlesList
                    articles={items}
                    contentType={'blog'}
                    datas={this.props.datas}
                    files={this.props.datas.uploads}
                    lngDatas={this.props.lngDatas}
                    presentation={'slider'}
                    sliderControls={true}
                    title={'Nos derniers dispositifs'}
                />
            </div>
            )
        }
    }

    renderOffresSaisonnieres(source){
        let items = this.getRelatedOffers(this.state.offers, source);
        if(items && items.length > 0){
            return(
            <div className={'seasonal__offer'}>
                <ArticlesList
                    articles={items}
                    contentType={'seasonals'}
                    datas={this.props.datas}
                    files={this.props.datas.uploads}
                    filtre={false}
                    lngDatas={this.props.lngDatas}
                    presentation={'list'}
                    sideContent={'description'}
                    sliderControls={true}
                    title={'Nos offres saisonnières'}
                ><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua.</p>
                </ArticlesList>
            </div>
            )
        }
    }

    render() {
        return (
            <section className="col-12 page expertises-detail">
                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    identity={false}
                    surtitle={{
                        type: 'media',
                        content: this.props.contentData.identity.logoSVG,
                    }}
                    title={{
                        type: 'text',
                        content: this.props.contentData.identity[this.props.lngDatas.name.data].catch,
                    }}
                    bottom={true}
                    module={'expertise-detail'}
                />

                <div className={'detail__close fullWidth containered'}>
                    <Link to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.expertises.meta.slug}`}>
                        <button className={'close__page'}>
                            <ReactSVG
                                src={'/ui/icons/close.svg'}
                                className={'close__page__item'}
                            />
                        </button>
                    </Link>
                </div>

                <section className={'section content content__step introduction fullWidth containered'}>
                    <div className={'section__title'}>{this.props.contentData.introduction[this.props.lngDatas.name.data].title || this.props.lngDatas.expertises.steps.introduction}</div>
                    <div className={'step__content'}
                       dangerouslySetInnerHTML={{__html: this.props.contentData.introduction[this.props.lngDatas.name.data].content}}></div>
                </section>

                <section className={'section content content__keydata keyfigures fullWidth containered'}>
                    <ul className={'keydatas'}>
                        {this.props.contentData.keyFigures[this.props.lngDatas.name.data].map((item, index) => {
                            return (
                                <li className={'keydata'} key={index}>
                                    <p className={'keydata__title'}>{item.figure}</p>
                                    <p className={'keydata__subtitle'}>{item.label}</p>
                                    <p className={'keydata__light'}>{item.description}</p>
                                    <p className={'keydata__xs'}>{item.source}</p>
                                </li>
                            )
                        })}
                    </ul>
                </section>

                {
                    (this.props.contentData.prestations && this.props.contentData.prestations[this.props.lngDatas.name.data]) &&

                    <section className={'expertise__services'}>
                        <p className={'services__subtitle'}>{this.props.contentData.prestations[this.props.lngDatas.name.data].surtitle}</p>
                        <h2 className={'services__title'}>{this.props.contentData.prestations[this.props.lngDatas.name.data].title}</h2>
                        <ul className={'expertise__servicesList'}>
                            {this.props.contentData.prestations[this.props.lngDatas.name.data].liste.map((item, index) => {
                                return (
                                    <li className={'service__category section'} key={index}>
                                        <h3 className={'category__title'}>{item.title}</h3>
                                        {item.liste &&
                                        <div className={'category__list'}>
                                            <h4 className={'category__description'}>{item.surtitle}</h4>
                                            <ul className={'category__items'}>
                                                {item.liste.map((service, indexx) => {
                                                    return (
                                                        <li className={'service__item'} key={indexx}>{service}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                }

                {
                    (this.props.contentData.prestations && this.props.contentData.prestations[this.props.lngDatas.name.data]) &&

                    <section className={"exp__nav section content"}>

                        <div className={'nav-item'}>
                            <ButtonCircle
                                key={'btn-nav-exp-1'}
                                action={{
                                    name: this.props.contentData.prestations[this.props.lngDatas.name.data].buttons.presentation,
                                    ref: 'expertise-presentation'
                                }}
                            />
                        </div>

                        <div className={'nav-item'}>

                            <a href={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.contacts.meta.slug}`}>
                                <ButtonCircle
                                    key={'btn-nav-exp-0'}
                                    action={{
                                        ref: true,
                                        name: this.props.contentData.prestations[this.props.lngDatas.name.data].buttons.contact,
                                    }}
                                />
                            </a>
                            
                        </div>

                    </section>

                }

                {/* this.renderDispositifs(this.props.contentData.identity[this.props.lngDatas.name.data].title) */}

                {/* this.renderOffresSaisonnieres(this.props.contentData.identity[this.props.lngDatas.name.data].title) */}

                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.contentData.index != this.props.datas.expertises.length-1 ? this.props.datas.expertises[this.props.contentData.index+1].identity.name : this.props.datas.expertises[0].identity.name,
                                path:  this.props.contentData.index != this.props.datas.expertises.length-1 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.expertises.meta.slug}/${this.props.datas.expertises[this.props.contentData.index+1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.expertises.meta.slug}/${this.props.datas.expertises[0].meta[this.props.lngDatas.name.data].slug}` ,
                            },
                            prev: {
                                title: this.props.contentData.index > 0 ? this.props.datas.expertises[this.props.contentData.index-1].identity.name : this.props.datas.expertises[this.props.datas.expertises.length-1].identity.name,
                                path:  this.props.contentData.index > 0 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.expertises.meta.slug}/${this.props.datas.expertises[this.props.contentData.index-1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.expertises.meta.slug}/${this.props.datas.expertises[this.props.datas.expertises.length-1].meta[this.props.lngDatas.name.data].slug}` ,
                            }
                        }
                    }
                />


            </section>
        )
    }
}
