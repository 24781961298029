import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import { ReactSVG } from "react-svg";
import MediaComponent from "../partials/Media";
import {TweenLite} from "gsap";
import {TimelineMax} from "gsap";

export default class Preloader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reveal: false,
        }
    }

    componentDidMount() {
        this.preloaderBackground = document.querySelector('#js_preload_bg');
        this.preloaderBase = document.querySelector('#js_preload_base');
        this.preloaderLight = document.querySelector('#js_preload_light');
        this.preloaderContainer = document.querySelector('#js_preload');
        this.body = document.body;
        this.domValid = false;



        if ( this.preloaderBackground && this.preloaderBase && this.preloaderLight && this.preloaderContainer ) {
            this.domValid = true;
        }

        this.revealPreloader();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
            if ( prevProps.loadingState !== this.props.loadingState && this.props.loadingState ) {
                this.revealPreloader();
            }

            else if ( prevProps.loadingState !== this.props.loadingState && !this.props.loadingState && this.state.revealed ) {
                this.hidePreloader();
            }

            else if ( prevState.revealed !== this.state.revealed && this.state.revealed && !this.props.loadingState ) {
                this.hidePreloader();
            }

    }


    revealPreloader() {
        if ( this.domValid ) {
            const timeline = new TimelineMax({
                onComplete: () => {
                    this.setState({revealed: true});
                },
            });
            timeline.set(document.body, {  overflow: "hidden"});
            timeline.set(this.preloaderContainer, {  display: "block"});
            timeline.set(this.preloaderBackground,  {  scaleY: 0, transformOrigin: "bottom"});
            timeline.set(this.preloaderBase, { autoAlpha: 0});
            timeline.set(this.preloaderLight, {  width: 0, autoAlpha: 1});

            timeline.to(this.preloaderBackground, 1, {  scaleY: 1});
            timeline.to(this.preloaderBase, 1, {  autoAlpha: 1});
            timeline.to(this.preloaderLight, 1, {  width: `${Math.floor(Math.random() * (80 - 30 + 1)) + 30}px`});
            timeline.add( ()=>{this.props.setShowContent(false)} );
        }

        // background s'ouvre
        // logo base apparait
        // logo light apparait à moitié
        // contenu disparaît
    }

    hidePreloader() {
        if ( this.domValid ) {
            const timeline = new TimelineMax({
                onComplete: () => {

                },
            });
            timeline.add( ()=>{this.props.setShowContent(true)} );
            timeline.set(this.preloaderBackground,  {  transformOrigin: "top"});
            timeline.to(this.preloaderLight, 1, {  width: `120px`});
            timeline.to(this.preloaderBase, 1, {  autoAlpha: 0});
            timeline.to(this.preloaderLight, 1, {  autoAlpha: 0});
            timeline.to(this.preloaderBackground, 1, {  scaleY: 0});
            timeline.set(this.preloaderContainer, {  display: "none"});
            timeline.set(document.body, {  overflow: "inherit"});

        }
         // contenu apparaît
        // logo light remplie logo light
        // les deux logos disparissent
        // le background disparait
        // preloader hidden

    }

    render() {

        return (
            <section className={"app__preloader"} id={"js_preload"}>
                <div className={"preloader__container"}>

                    <div className={"preloader__logoLight"}>
                        <div className={"preloader__wrap"}>
                            <div className={"preloader__logoLightArea"}>
                                <div className={"preloader__wrap"}>
                                    <div className={"preloader__logoLightMask"} id={"js_preload_light"}>
                                        <div className={"preloader__wrap"}>
                                            <div className={"preloader__logoLightContainer"}>
                                                <ReactSVG
                                                    className={'logo'}
                                                    src={'/ui/logos/logo-unify.svg'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"preloader__logoBase"}>
                        <div className={"preloader__wrap"}>
                            <div className={"preloader__logoBaseArea"}>
                                <div className={"preloader__wrap"}>
                                    <div className={"preloader__logoBaseMask"} id={"js_preload_base"}>
                                        <div className={"preloader__wrap"}>
                                            <div className={"preloader__logoBaseContainer"}>
                                                <ReactSVG
                                                    className={'logo'}
                                                    src={'/ui/logos/logo-unify.svg'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"preloader__background"} id={"js_preload_bg"}></div>
                </div>
            </section>
        )
    }
}