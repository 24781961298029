import React, {Component} from "react";
import ButtonCircle from "../partials/buttonCircle";
import { ReactSVG } from "react-svg";
import ListSocials from "../partials/socials";
import MediaComponent from "../partials/Media";


function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}


export default class PageHero extends Component {
    constructor(props) {
        super(props);
    }

    renderHeroSurtitle(version) {
        if (this.props.surtitle && this.props.surtitle.type) {
            if (this.props.surtitle.type === 'media' && this.props.surtitle.content._id) {
                let files = this.props.datas.uploads;
                let file = findNestedObj(files, '_id', this.props.surtitle.content._id);
                let style = false;
                return (
                    <div className={'hero__surtitle__media'}>
                        <MediaComponent media={file} version={version}/>
                    </div>
                )
            } else if (this.props.surtitle.type === 'text') {
                return (
                    <p className={'hero__surtitile__text'}>{this.props.surtitle.content}</p>
                )
            }
        } else {
            return(
                <></>
            )
        }
    }

    renderMainTitle() {
        if (this.props.title && this.props.title.type && this.props.title.content) {
            return (
                <div className={'hero__title'} dangerouslySetInnerHTML={{__html: this.props.title.content}}>

                </div>

            )
        } else {
            return (
                <></>
            )
        }
    }

    renderSubtitle() {
        if (this.props.subtitle && this.props.subtitle.type && this.props.subtitle.content) {
            if (this.props.subtitle.type === 'button') {
                return (
                    <ButtonCircle
                        target={this.props.subtitle.content.target}
                        action={this.props.subtitle.content.action}
                    />
                )
            }
        }
    }

    renderBottom() {
        return (
            <>
                <div className={'hero__scroller'}>
                    <p>Scroll</p>
                </div>

                <div className={'hero__socials'}>
                    {this.props.lngDatas &&
                        <ListSocials
                            lngDatas={this.props.lngDatas}
                        />
                    }
                </div>
            </>
        )
    }

    renderHeroMedia(version) {
        // console.log('this.props.identity', this.props.identity);
        if (this.props.identity?.cover?.isUrl) {
            return <MediaComponent media={this.props.media} version={version}/>
        } else {
            let files = this.props.datas.uploads;
            let file = findNestedObj(files, '_id', this.props.identity.cover._id);
            return <MediaComponent media={file} autoplay={this.props.autoplay} version={version}/>
        }

    }

    render() {
        return (
            <section className={`hero hero__page ${this.props.module}`}>
                <div className={'hero__container'}>
                    <div className={'hero__content'}>
                        <div className={'hero__main'}>

                            <div className={'hero__title_container'}>
                                <div className={'hero__surtitle'}>
                                    {this.renderHeroSurtitle('original')}
                                </div>


                                {this.renderMainTitle()}


                                <div className={'hero__subtitle'}>
                                    {this.renderSubtitle()}
                                </div>
                            </div>
                        </div>

                        {this.props.bottom &&
                        <div className={'hero__bottom'}>
                            {this.renderBottom()}
                        </div>
                        }

                    </div>

                    {this.props.identity?.cover &&
                    <div className={'hero__media'}>
                        {this.renderHeroMedia('original')}
                    </div>

                    }

                    <div className={'hero__logo'}>
                        <ReactSVG
                            className="icon"
                            src="/ui/backgrounds/headerUnify.svg"
                        />
                    </div>
                </div>
            </section>
        )
    }
}