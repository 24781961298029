import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import ContactModal from "../pages/Contact.modal";

function findNestedObj(entireObj, keyToFind, valToFind) {
	let foundObj;
	JSON.stringify(entireObj, (_, nestedValue) => {
		if (nestedValue && nestedValue[keyToFind] === valToFind) {
			foundObj = nestedValue;
		}
		return nestedValue;
	});
	return foundObj;
}

function postAjax(url, data, success) {
    var params = typeof data == 'string' ? data : Object.keys(data).map(
        function(k){ return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
    ).join('&');

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new window.ActiveXObject("Microsoft.XMLHTTP");
    xhr.open('POST', url);
    xhr.onreadystatechange = function() {
    	if(xhr.readyState>3){
	    	//console.log(xhr); 
	    	success(xhr); 
    	}
    };
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(params);
    return xhr;
}

export default class Contacts extends Component {
	constructor(props) {
		super(props);

		//console.log('in Home.js')

		//console.log(this.props);
		this.state = {
			mailReq : ''
		}

		this.fedRefs = {
			name : React.createRef(),
			email : React.createRef(),
			company : React.createRef(),
			job : React.createRef(),
			req : React.createRef(),
			message : React.createRef(),
			file : React.createRef()
		}

	}

	componentDidMount(){
		// initialisation des variables
		let fileInput  = document.querySelector(".file"),  
		    button     = document.querySelector(".upload"),
		    the_return = document.querySelector(".file-list");

		gsap.registerPlugin(DrawSVGPlugin);


	}

	resetForm(e){

		const oThis = this;

		oThis.state.mailReq = '';

		e.preventDefault();
		let form = document.querySelector( ".form" );
		let fields = form.querySelector(".fields");
		let confirmation = form.querySelector(".confirmation");
		form.reset();

		gsap.to(confirmation, {opacity:0, duration: .25, onComplete: function(){



			form.classList.remove('sent');
			fields.classList.remove('hide');
			gsap.to(fields, {opacity:1, duration: .25});
		}});
	}

	sendForm(e){
		e.preventDefault();

		let form = document.querySelector( ".form");
		let fields = form.querySelector(".fields");
		let confirmation = form.querySelector(".confirmation");
		let inputs  = form.querySelectorAll( ".field" );
		let errors  = form.querySelectorAll( ".error" );
		let errorsCount = 0;

		inputs.forEach((item)=>{
			item.classList.remove('error');
		});
		errors.forEach((item)=>{
			item.classList.remove('show');
		});

		let recquired = form.querySelectorAll(".recquired");

		recquired.forEach((item)=>{
			if(item.classList.contains('email')){
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.value)){
					return (true)
				} else {
					item.parentNode.classList.add('error');
					form.querySelector(".error.mail").classList.add('show');
					errorsCount ++;
				}
			} else {
				if(item.value.trim() === ""){
					item.parentNode.classList.add('error');
					form.querySelector(".error.empties").classList.add('show');
					errorsCount ++;
				}
			}
		});

		if(this.state.mailReq === ""){
			form.querySelector("#ContactRequestField").classList.add('error');
			form.querySelector(".error.mail").classList.add('show');
			errorsCount ++;
		} else {
			form.querySelector("#ContactRequestField").classList.remove('error');
		}

		if(errorsCount === 0){

			let bundle = {
				name : this.fedRefs.name.current.value.trim(),
				email : this.fedRefs.email.current.value.trim(),
				company : this.fedRefs.company.current.value.trim(),
				job : this.fedRefs.job.current.value.trim(),
				req : this.state.typeReq,
				mailto : this.fedRefs.req.current.value.trim(),
				message : this.fedRefs.message.current.value.trim()
			}

			//console.log(bundle);
			form.classList.add('sending');

			//console.log('print before send in client', new Date());

			postAjax(this.props.datas.contactUrl, bundle, function(res){ 

				// console.log('print once sent in client', new Date());

				// AJAX CALL WITH RESPONSE

				if(res.status === 200){
					gsap.to(fields, {opacity:0, duration: .25, onComplete: function(){
						form.classList.remove('sending');
						fields.classList.add('hide');
						form.classList.add('sent');
						gsap.to(confirmation, {opacity:1, duration: .25});
					}});
				} else{
					form.classList.remove('sending');
					gsap.to(errors, {opacity:1, duration: .25});
				}
			});
		}

	}

	selectContacts(e){
		//console.log(e.target.options[e.target.selectedIndex].text);

		this.setState({
			mailReq : e.nativeEvent.target.value,
			typeReq : e.target.options[e.target.selectedIndex].text
		});

	}

	renderContacts(){
		let contacts = this.props.datas.contacts;

		return contacts.map((item, index)=>{
			//console.log(item.email);
			return(
				<option key={`contact-req-${index}`} value={item.email}>{item.label[`${this.props.lngDatas.name.data}`]}</option>
			)
		});
	}

	RenderDocuments(){ 

	  let tosId = this.props.lngDatas.generic.footer.file_tos._id;
	  let tosFile = findNestedObj(this.props.uploads, '_id', tosId);
	  let tosSrc = (tosFile)?tosFile.staticPath:'';

	  let techspecsId = this.props.lngDatas.generic.footer.file_techspecs._id;
	  let techspecsFile = findNestedObj(this.props.uploads, '_id', techspecsId);
	  let techspecsSrc = (techspecsFile)?techspecsFile.staticPath:'';

	  let mediaId = this.props.lngDatas.generic.footer.file_media._id;
	  let mediaFile = findNestedObj(this.props.uploads, '_id', mediaId);
	  let mediaSrc = (mediaFile)?mediaFile.staticPath : '';
	  const routesFooter = [{
	      path : "",
	      exact : false,
	      lab_nav : this.props.lngDatas.generic.footer.lab_link_tos,
	      url : tosSrc,
	      isDownloadable : true,
	      isExt : false
	    },{
	      path : "",
	      exact : false,
	      lab_nav : this.props.lngDatas.generic.footer.lab_link_media,
	      url : mediaSrc,
	      isDownloadable : true,
	      isExt : false
	    },{
	      path : "",
	      exact : false,
	      lab_nav : this.props.lngDatas.generic.footer.lab_link_techspecs,
	      url : techspecsSrc,
	      isDownloadable : true,
	      isExt : false
	    }
	  ];
		return routesFooter.map((route, index)=>{
			return(
				<li key={`nav-item-${index}`} className="col-12 col-md-auto item">

					<a href={route.url} className="download" download={route.isDownloadable} target={route.isExt ? "_blank" : "_self"} >
						<div className="circle">
				            <ReactSVG
				              className="circlesvg"
				              src="/ui/ctas/a_circle_cut.svg"
				            />
				          </div>
						<span className="text">
							{route.lab_nav}
						</span>
					</a>
				</li>
			)
		});
	}

	iniFooterNavCTAs(svg){
		// CURSOR
		const cursor = document.getElementById('CursorDot'),
			follower = document.getElementById('CursorCircle');

		function moveCircle(e) {
			gsap.to(cursor, 0.2, {
				x: e.clientX,
				y: e.clientY,
				ease: "ease-in"
			});
			gsap.to(follower, 0.5, {
				x: e.clientX,
				y: e.clientY,
				ease: "ease-in"
			});
		}

		function hoverCtaFunc(e) {
			gsap.to(cursor, 0.3, {
				opacity: 1,
				scale: 0
			});
			gsap.to(follower, 0.3, {
				scale: 0
			});
		}

		function unhoverCtaFunc(e) {
			gsap.to(cursor, 0.3, {
				opacity: 1,
				scale: 1
			});
			gsap.to(follower, 0.3, {
				scale: 1
			});
		}

		let circleItem = svg.querySelector('.cta-ltr-circle');
		let arrowItem = svg.querySelector('.cta-ltr-arrow');

		let ctaParent = svg.parentNode;

		while(!ctaParent.classList.contains('cta') && ctaParent.parentNode !== null){
			ctaParent = ctaParent.parentNode;
		}

		let ctacirc = gsap;
		let tlctacirc = ctacirc.timeline();
		let tlctarr = ctacirc.timeline();

		tlctacirc.startTime(0);

		tlctacirc
			.to(circleItem, {drawSVG:"0% 75%"})
			.pause();

		tlctacirc
			.fromTo(circleItem, {drawSVG:"0% 75%"},{duration:.300, drawSVG:"100%", ease: "expo"})
			.pause();

		tlctarr
			.to(arrowItem, {opacity:0})
			.pause();

		tlctarr
			.to(arrowItem, {x: 50, duration:.300, opacity:1, ease: "expo"})
			.pause();

		ctaParent.addEventListener('mouseover', function(event){
			//console.log(this);
			event.stopPropagation();
			//console.log('over');
			hoverCtaFunc();
			tlctacirc.play();
			tlctarr.play();
			//console.log(event.target);
		});

		ctaParent.addEventListener('mouseout', function(event){
			//console.log(this);
			event.stopPropagation();
			//console.log('out');
			unhoverCtaFunc();
			tlctacirc.reverse();
			tlctarr.reverse();
			//console.log(event.target);
		});
	}

	render() {
		/*return (
	      <section id="modalContacts" className="row m-0 modal contacts">
	        <div className="col-12 zone-blob block-l">
		      	<div className="blobs">
		            <div className="blob layer1">
		            	<img className="img" src="/ui/blobs/png/contact_1.png" alt="" />
			            <div className="point">
			            	<span dangerouslySetInnerHTML={{__html:this.props.lngDatas.contacts.address}}></span>
			            </div>
		            </div>
		            <img className="blob layer2" src="/ui/blobs/png/contact_2.png" alt="" />
		            <img className="blob layer3" src="/ui/blobs/png/contact_3.png" alt="" />
		      	</div>
		    </div>
	    	<div className="col-12 mx-auto p-0 block">
	    		<div className="row m-0 justify-content-end">
			      	<div className="btn close" onClick={this.props.fnClose.bind(this)}>
						<div className="menu-burger menu-burger__contact active">
							<button className="menu-burger-btn">
								<span className="line line-1"></span>
								<span className="line line-2"></span>
								<span className="line line-3"></span>
							</button>
						</div>
			      	</div>
		      	</div>
		      	<div className="row content">
		      		<div className="col-12 col-lg-6 informations">
		      			<div className="row h-100 m-0">
		      				<div className="col-12 col-md-6 col-lg-12 p-0 align-self-start">
					      		<h3 className="title">
					      			<span>
					      				{this.props.lngDatas.contacts.lab_nav}
					      			</span>
					      		</h3>
					      		<div className="introduction" dangerouslySetInnerHTML={{__html:this.props.lngDatas.contacts.introduction.text}}></div>

					            {this.props.lngDatas.generic.socials &&
					            <div className="row socials">
					              {this.props.lngDatas.generic.socials.linkedin !=="" &&
					                <a className="col-auto" href={this.props.lngDatas.generic.socials.linkedin} target="_blank">
					                  <ReactSVG
					                    className="icon"
					                    src="/ui/icons/icon-linkedin.svg"
					                  />
					                </a>
					              }
					              {this.props.lngDatas.generic.socials.twitter !=="" &&
					                <a className="col-auto" href={this.props.lngDatas.generic.socials.twitter} target="_blank">
					                  <ReactSVG
					                    className="icon"
					                    src="/ui/icons/icon-twitter.svg"
					                  />
					                </a>
					              }
					            </div>
					            }

					        </div>
					        <div className="col-12 p-0 align-self-end rgpd">
			            		<p
			            			className="notices"
			            			dangerouslySetInnerHTML={{__html:this.props.lngDatas.contacts.form.rgpd_text}}>
			            		</p>
				            </div>
				        </div>
		      		</div>
		      		<form name="contactform" id="contactForm" className="col-12 col-lg-6 form" onSubmit={this.sendForm.bind(this)}>
	      				<div className="row fields">
	      					<div className="col-12 col-md-6 field">
	      						<label htmlFor="ContactName" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_lastName} *
	      							</span>
	      						</label>
	      						<input type="text" ref={this.fedRefs.name} id="ContactName" className="d-block input recquired" />
	      						<div className="bord"></div>
	      					</div>
	      					<div className="col-12 col-md-6 field">
	      						<label htmlFor="ContactEmail" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_email} *
	      							</span>
	      						</label>
	      						<input type="text" ref={this.fedRefs.email} id="ContactEmail" className="d-block input email recquired" />
	      						<div className="bord"></div>
	      					</div>
	      					<div className="col-12 col-md-6 field">
	      						<label htmlFor="ContactCompany" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_company}
	      							</span>
	      						</label>
	      						<input type="text" ref={this.fedRefs.company} id="ContactCompany" className="d-block input" />
	      						<div className="bord"></div>
	      					</div>
	      					<div className="col-12 col-md-6 field">
	      						<label htmlFor="ContactJob" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_job}
	      							</span>
	      						</label>
	      						<input type="text" ref={this.fedRefs.job} id="ContactJob" className="d-block input" />
	      						<div className="bord"></div>
	      					</div>
	      					<div id="ContactRequestField" className="col-12 field">
	      						<label htmlFor="ContactRequest" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_entity} *
	      							</span>
	      						</label>
	      						<select ref={this.fedRefs.req} id="ContactRequest" className="d-block select" onChange={this.selectContacts.bind(this)}>
									<option value="">{this.props.lngDatas.contacts.form.lab_entity_select}</option>
	      							{this.renderContacts()}
	      						</select>
	      						<div className="bord"></div>
	      					</div>
	      					<div className="col-12 field">
	      						<label htmlFor="ContactMessage" className="d-block label">
	      							<span>
	      								{this.props.lngDatas.contacts.form.lab_message_user} *
	      							</span>
	      						</label>
	      						<textarea ref={this.fedRefs.message} id="ContactMessage" className="d-block textarea recquired"></textarea>
	      						<div className="bord"></div>
	      						<span className="italic">
	      							* {this.props.lngDatas.contacts.form.lab_mandatory_field}
	      						</span>
	      					</div>
	      					<div className="col-12 field">
	      						<p>
			      					{this.state.mailReq !== ""? (

			      						this.props.lngDatas.contacts.form.lab_message_directmail + " " + this.state.mailReq
			      						) : (
			      						this.props.lngDatas.contacts.form.lab_message_disclaimer
			      						)
			      					}
	      						</p>
	      					</div>
	      					<div className="col-12 field">
	      						<div className="row m-0 align-items-start justify-content-between">
		      						<div className="col-12 col-lg-7 p-0 errors">
		      							<div className="error empties">
		      								<p>
		      									{this.props.lngDatas.contacts.form.lab_error_fill}
		      								</p>
		      							</div>
		      							<div className="error mail">
		      								<p>
		      									{this.props.lngDatas.contacts.form.lab_error_mail}
		      								</p>
		      							</div>
		      							<div className="error send">
		      								<p>
		      									{this.props.lngDatas.contacts.form.lab_error_send}
		      								</p>
		      							</div>
		      						</div>
									<button className="col-12 col-lg-5 p-0 text-center text-md-right ml-auto cta hiddenCursor all">
										<span>
											{this.props.lngDatas.contacts.form.lab_btn_send}
										</span>
										<div className="circle">
								            <ReactSVG
								              className="circlesvg"
								              src="/ui/ctas/a_circle_full_ltr.svg"
								              renumerateIRIElements={false}
								              afterInjection={(error, svg) => {
								                if (error) {
								                  console.error(error)
								                  return
								                } else {
								                  this.iniFooterNavCTAs(svg)
								                }
								              }}
								            />
								          </div>
									</button>
								</div>
				            </div>
	      				</div>
	      				<div className="row confirmation">
	      					<div className="d-block">
	      						<p>
		      						{this.props.lngDatas.contacts.form.lab_message_confirm}
	      						</p>
	      					</div>
							<button className="d-block p-0 text-right ml-auto cta hiddenCursor all back" onClick={this.resetForm.bind(this)}>
								<span>
									{this.props.lngDatas.contacts.form.lab_btn_back}
								</span>
								<div className="circle">
									<ReactSVG
										className="circlesvg"
										src="/ui/ctas/a_circle_full_ltr.svg"
										renumerateIRIElements={false}
										afterInjection={(error, svg) => {
											if (error) {
												console.error(error)
											return
											} else {
												this.iniFooterNavCTAs(svg)
											}
										}}
									/>
								</div>
							</button>
	      				</div>
	      			</form>
			        {/!*
			        <div className="col-12 col-lg-6 documents">
			        	<h4 className="subtitle">
			        		<span>
			        			{this.props.lngDatas.contacts.lab_documents}
			        		</span>
			        	</h4>
			        	<ul className="row doc-list">
			        		{this.RenderDocuments()}
			        	</ul>
			        </div>
			    	*!/}
		      	</div>
	    	</div>
	      </section>
	    )*/

		return (
			<ContactModal
				{...this.props}
			/>
		)

	}
}
