import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import { ReactSVG } from "react-svg";

export default class HeaderNavigation extends Component {
    constructor(props) {
        super(props);
    }

    renderLogo() {
        return (
            <div className={'navigation__logo'}>
                <NavLink to={"/" + this.props.lng + "/"}>
                    <ReactSVG
                        className={'logo'}
                        src={'/ui/logos/logo-unify.svg'}
                    />
                </NavLink>
            </div>

        )
    }

    renderNavSide() {
        return (
            <div className={'navigation__navSide'}>
                <div className={'languages'}>
                    <ul className="languages-list">
                        {
                            this.props.languages.map((item, index) => {
                                if (index < 1) {
                                    return (
                                        <li key={`languages-nav-item-${index}`} className="languages-item">
                                            <NavLink to={`/${item.name.data}/`}
                                                     activeClassName="selected">{item.name.short}</NavLink>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <>
                                            <ReactSVG
                                                className="sep"
                                                src="/ui/sep.svg"
                                                key={`languages-nav-itemsvg-${index}`}
                                            />
                                            <li key={`languages-nav-item-${index}`} className="languages-item">
                                                <NavLink to={`/${item.name.data}/`}
                                                         activeClassName="selected">{item.name.short}</NavLink>
                                            </li>
                                        </>
                                    )
                                }
                            })
                        }
                    </ul>
                </div>

                <ul className={'secondNav'}>
                    {
                        this.props.linksSideNav.map((route, index) => {
                            return (route.lab_nav !== "") ? (
                                <li key={`navsec-item-${index}`} className="nav-item">
                                    {!route.isExt ? (
                                        !route.isModal ? (
                                            <NavLink exact={route.exact} to={route.path}
                                                     activeClassName="selected"><span>{route.lab_nav}</span></NavLink>
                                        ) : (
                                            <button onClick={() => {
                                                route.isModal.fn(this.props.navigationState)
                                            }}>
                                                <span>{route.isModal.labs[this.props.navigationState ? 'open' : 'close']}</span>
                                            </button>
                                        )

                                    ) : (
                                        <a href={route.path} target="blank">
                                            <span>
                                                {route.lab_nav}
                                            </span>
                                        </a>
                                    )}
                                </li>
                            ) : '';
                        })
                    }
                </ul>
            </div>
        )
    }

    RenderHeaderNav() {
        if (this.props.routesHeader) {
            return this.props.routesHeader.map((route, index) => {
                return (route.lab_nav !== "") ? (
                    <li key={`navhead-item-${index}`} className={`nav-item ${route.isModal ? 'mod' : 'lin'}`}>
                        {!route.isExt ? (
                            <NavLink
                                className={`${route.lab_nav != this.props.lngDatas.contacts.lab_nav ? '' : 'openclose'}`}
                                exact={route.exact} to={route.path} activeClassName="selected">
                                <span>{route.lab_nav}</span>
                                <div className={'indicator'}></div>
                            </NavLink>
                        ) : (
                            <a href={route.path} target="blank">
                                <span>
                                    {route.lab_nav}
                                </span>
                            </a>
                        )}
                    </li>
                ) : '';
            });
        }

    }

    renderNavMain() {
        return (
            <div className="navigation__navMain">
                <ul className="MaindNav">
                    {this.RenderHeaderNav(this.props.linksHeader)}
                </ul>
            </div>
        )
    }

    renderBurger() {
        return (
            <div className="navigation__burger">
                <button
                    className={`menu-burger-btn ${this.props.panelState || this.props.contactPanelState ? 'active' : ''}`}
                    onClick={() => {

                        if (this.props.contactPanelState) {
                            document.body.classList.remove('locked');
                            this.props.handleContactPanel(false);
                        } else {
                            document.body.classList.add('locked');
                            this.props.handlePanel();
                        }

                    }}>
                    <div className={'line__container'}>
                        <span className="line line-1"></span>
                        <span className="line line-2"></span>
                    </div>
                </button>
            </div>
        )
    }

    renderCloseContact() {
        return (
            <div className="navigation__burger">
                <button className={`menu-burger-btn ${this.props.panelState ? 'active' : ''}`} onClick={() => {
                    this.props.handlePanel();
                }}>
                    <div className={'line__container'}>
                        <span className="line line-1"></span>
                        <span className="line line-2"></span>
                    </div>
                </button>
            </div>
        )
    }

    renderMobileSecondNav() {
        return (
            <ul className={'secondNav mobile'}>
                {
                    this.props.linksSideNav.map((route, index) => {
                        return (route.lab_nav !== "") ? (
                            <li key={`nav-itemmob-${index}`} className="nav-item">
                                {!route.isExt ? (
                                    !route.isModal ? (
                                        <NavLink exact={route.exact} to={route.path}
                                                 activeClassName="selected"><span>{route.lab_nav}</span></NavLink>
                                    ) : (
                                        <button onClick={() => {
                                            route.isModal.fn(this.props.navigationState)
                                        }}>
                                            <span>{route.isModal.labs[this.props.contactPanelState ? 'close' : 'open']}</span>
                                        </button>
                                    )

                                ) : (
                                    <a href={route.path} target="blank">
                                            <span>
                                                {route.lab_nav}
                                            </span>
                                    </a>
                                )}
                            </li>
                        ) : '';
                    })
                }
            </ul>
        )
    }

    renderContactMobile() {
        return (
            <NavLink className={'mobile-btn-cnt'} to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.contacts.meta.slug}`}></NavLink>
        )
    }

    render() {
        return (
            <nav className={`navigation ${this.props.classnav} ${this.props.panelState ? 'open' : ''}`}>
                <div className={"navigation__container "}>
                    <div className={'navigation__side'}>
                        {this.renderLogo()}
                        {this.renderNavSide()}
                    </div>
                    {this.renderNavMain()}
                    {this.renderMobileSecondNav()}
                    {this.renderBurger()}
                    {this.renderContactMobile()}
                </div>
            </nav>
        )
    }
}