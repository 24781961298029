import React, { Component } from "react";
import ButtonCircle from "../../partials/buttonCircle";
import FooterNewsletter from "./FooterNewsletter";
import FooterNav from "./FooterNav";
import {Link, NavLink} from "react-router-dom";
import ListSocials from "../../partials/socials";
import Legals from "../../pages/Legals";
import Cookies from "../../pages/Cookies";
import Tos from "../../pages/Tos";

export default class Footer extends Component {
    constructor(props) {
        super(props);

        //console.log('Footer props', props);

        this.footerMenuLinks = [
            {
                path : "https://blog.unifygroup.com/",
                lab_nav : this.props.lngDatas.generic.footer.lab_link_blog,
                isDownloadable : false,
                isExt : true,
            },
            {
                path : "https://blog.unifygroup.com/fr/success-stories",
                lab_nav : this.props.lngDatas.generic.footer.lab_link_cases,
                isDownloadable : false,
                isExt : true,
            },
            {
                path : "https://blog.unifygroup.com/",
                lab_nav : this.props.lngDatas.generic.footer.lab_link_guides,
                isDownloadable : false,
                isExt : true,
            },
            {
                path : "https://blog.unifygroup.com/fr/offres",
                lab_nav : this.props.lngDatas.generic.footer.lab_link_offers,
                isDownloadable : false,
                isExt : true,
            }
        ];

        this.footerMenuModals = [
            {
                path : "/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.generic.legals.meta.slug,
                exact : false,
                lab_nav : this.props.lngDatas.generic.footer.lab_link_legals,
                component: Legals,
                genHeader: false,
            },{
                path : "/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.generic.cookies.meta.slug,
                exact : false,
                lab_nav : this.props.lngDatas.generic.footer.lab_link_cookies,
                component: Cookies,
                genHeader: false,
            },{
                path : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.jobs.meta.slug}`,
                lab_nav : this.props.lngDatas.jobs.lab_nav,
                isModal: false,
                isDownloadable : false,
                isExt : false,
            }, {
                path : "/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.generic.footer.slug_tos,
                exact : false,
                lab_nav : this.props.lngDatas.generic.footer.lab_link_tos,
                component: Tos,
                genHeader: false,
            },

        ];
    }

    componentDidMount() {
    }

    render() {
        return (
            <footer className={`footer ${(this.props.locationPath === "/fr/" || this.props.locationPath === "/en/" ) ? 'home':'' }`}>
                <div className={'footer__container'}>
                    <div className={'footer__hero'}>

                        {
                            this.props.custom  &&
                            <FooterNav
                                lngDatas={this.props.lngDatas}
                                custom={this.props.custom}
                            />
                        }



                    </div>
                    <div className={'footer__main'}>
                        <div className={'top'}>
                            {
                                ( this.footerMenuLinks && this.footerMenuLinks.length>0) &&
                                <ul className={'footer__menu'}>
                                    {
                                        this.footerMenuLinks.map((route, index)=>{
                                            if(route.lab_nav && route.lab_nav.trim()){ 
                                                return (
                                                    <li key={`nav-item-${index}`} className="nav-item">
                                                        {!route.isExt ? (
                                                            !route.isModal ? (
                                                                <NavLink exact={route.exact} to={route.path}
                                                                         activeClassName="selected"><span>{route.lab_nav}</span></NavLink>
                                                            ) : (
                                                                <button onClick={route.isModal.fn}><span>{route.lab_nav}</span></button>
                                                            )

                                                        ) : (
                                                            <a href={route.path} target="blank">
                                                                <span>
                                                                    {route.lab_nav}
                                                                </span>
                                                            </a>
                                                        )}
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>

                            }

                            <div className={'footer__newsletter'}>
                                <FooterNewsletter
                                    lngDatas={this.props.lngDatas}
                                />
                            </div>
                        </div>
                        <div className={'main'}>
                            <div className={'footer__content'}>
                                <h2 dangerouslySetInnerHTML={{__html:this.props.lngDatas.generic.footer.text}}></h2>
                                <a href={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.contacts.meta.slug}`}>
                                <ButtonCircle
                                    action={{
                                        ref: true,
                                        name: 'Nous contacter'
                                    }}
                                />
                                </a>
                            </div>

                        </div>
                        <div className={'bottom'}>
                            <ul className={'footer__links'}>
                                {
                                    ( this.footerMenuModals && this.footerMenuModals.length>0) &&
                                    this.footerMenuModals.map((route, index)=>{
                                        return (
                                            <li key={`nav-item-${index}`} className={`nav-item ${route.light ? 'light':''}`}>
                                                {!route.isExt ? (
                                                    !route.isModal ? (
                                                        <NavLink target="_blank" exact={route.exact} to={route.path}
                                                                 activeClassName="selected"><span>{route.lab_nav}</span></NavLink>
                                                    ) : (
                                                        <button><span>{route.lab_nav}</span></button>
                                                    )

                                                ) : (
                                                    <a href={route.path} target="blank">
                                            <span>
                                                {route.lab_nav}
                                            </span>
                                                    </a>
                                                )}
                                            </li>
                                        )
                                    })

                                } 

                                <li className="nav-item ">
                                    <a href="javascript:void();" onClick={() => this.props.didomiObject.preferences.show()}>
                                        <span>
                                          Préférences Cookies
                                        </span>
                                    </a>
                                </li>

                                <li className="social-links">
                                    <ListSocials
                                        lngDatas={this.props.lngDatas}
                                    />
                                </li>

                            </ul>
                            {/*<div className={'footer__socials'}>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}