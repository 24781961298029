import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import GlideCarousel from '../../assets/scripts/GlideCarousel'
import ArticlesList from "../components/articlesList";
import FilterSelect from "../partials/filterSelect";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import IsVisible from "../../assets/scripts/IsVisible";
import ImageLazy from "../transversals/ImageLazy"
import PageHero from "../components/Hero";
import Footer from "../components/Footer/Footer";


function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

export default class NewsHomepage extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

    this.state={
      latestN : 5,
      showfilters:false,
      selectedFilter: false,
    };


    this.news = this.props.datas.news;
    this.highlighted = [...this.news].slice(0,1);
    //console.log(this.highlighted);
    this.latest = [...this.news].slice(1,this.state.latestN+1);

    this.toggleFilters = this.toggleFilters.bind(this);

    this.pagination = {
      setup:9,
      increase:9,
    };
  }

  componentDidMount(){
    gsap.registerPlugin(MorphSVGPlugin);

    const carousels = document.querySelectorAll('.gl-carousel');
    carousels.forEach((carousel,key)=>{
      new GlideCarousel(carousel, {
        breakpoints: {
          800: {
            perView: 2
          },
          500: {
            perView: 1
          }
        }
      });
    });

    let titleReveal = document.querySelectorAll('.title-reveal');
    new IsVisible(titleReveal);
  }


  renderMetas(){

    let title = (this.props.lngDatas.news.meta.title !=="")? this.props.lngDatas.news.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
    let description = (this.props.lngDatas.news.meta.description !=="")? this.props.lngDatas.news.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
    //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.news.meta.keywords !=="")? this.props.lngDatas.news.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
    let slug = this.props.lngDatas.news.meta.slug;
    return(
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
      </Helmet>
    )
  }

  renderDate(dateData){

    let dateO = new Date(dateData);
    const isDayHuge = dateO.getDate() >= 10;
    const isMonthHuge = dateO.getMonth() + 1 >= 10;
    let dateD = isDayHuge ? dateO.getDate() : ("0" + dateO.getDate());
    let dateM = isMonthHuge ? (dateO.getMonth() + 1) : ("0" + (dateO.getMonth() + 1));
    let dateY = dateO.getFullYear();
    let date = dateD + '-' + dateM + '-' + dateY;


    return(
      date
    )
  }

  renderItems(datas, type, col){
    let files = this.props.datas.uploads;
    let types = this.props.datas.newstypes;

    return datas.map((item, index)=>{
      let imgSrc="";
      if(item.visual && item.visual._id){
        let img = item.visual._id;
        let file = findNestedObj(files, '_id', img);
        if(file){
          imgSrc = file.versions[`${type}`].staticPath;
        }
      }

      let typeLabels = [];

      item.newstype.map((newstypeitem, newstypeindex)=>{
        let newstypeitemfull = findNestedObj(types, '_id', newstypeitem._id);
        typeLabels.push(newstypeitemfull.label);
      });

      let typeLabel = JSON.stringify(typeLabels);


      return(
        <div className={col + " item"+" glide__slide"} data-filter={typeLabel} key={`news-${type}-${index}`}>
          <Link to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.news.meta.slug+"/"+item.meta[`${this.props.lngDatas.name.data}`].slug}>
            {item.visual && item.visual._id &&
            <figure className="visual">
              <div className="img-mask" >
                <img src={imgSrc} />
              </div>
            </figure>
            }
            <span className="date">
              {this.renderDate(item.edit.createdAt.$date)}
            </span>
            <h3 className="title">
              <span>
                {item.title[`${this.props.lngDatas.name.data}`]}
              </span>
            </h3>
            <div className="arrow">
              <ReactSVG
                className="off"
                src="/ui/ctas/news.svg"
              />
              <ReactSVG
                className="on"
                src="/ui/ctas/news_2.svg"
              />
            </div>
          </Link>
        </div>
      )
    });
  }

  toggleFilters(){
    const self = this;

    const dropdown = document.getElementById('dropdownFilter');

    if(!this.state.showfilters) {
      this.setState({
        showfilters: true
      })
      dropdown.classList.add('unfold');

      document.addEventListener('click', function switchTog(event){


        let starter = event.target;

        while(starter !== dropdown && starter.parentNode !== null){
          starter = starter.parentNode;
        }

        if(starter !== dropdown){ 
          self.setState({
            showfilters: false
          })
          dropdown.classList.remove('unfold');
          document.removeEventListener('click', switchTog, true);
        }

      }, true)
    } else{
      self.setState({
        showfilters: false
      });
      dropdown.classList.remove('unfold');
    } 
  }

  filterItems(label){
    let container = document.querySelector('.all');
    let filters = document.querySelectorAll('.all .filter-btn');
    let filterActive = document.querySelector('.all button[data-filter='+label+']');
    let items = document.querySelectorAll('.all .item');
    let filtered = [];
    items.forEach(function(item){
      let filterLabels = JSON.parse(item.getAttribute('data-filter'));
      if(filterLabels.find(lab => lab === label)){
        filtered.push(item);
      }
    });

    if(label==="all"){
      items.forEach(function(item){
        item.classList.remove('hide');
      });
      filters.forEach(function(item){
        item.classList.remove('active');
      });
      filterActive.classList.add('active');
    }
    else {
      items.forEach(function(item){
        item.classList.add('hide');
      });
      filtered.forEach(function(item){
        item.classList.remove('hide');
      });
      filters.forEach(function(item){
        item.classList.remove('active');
      });
      filterActive.classList.add('active');
    }

    document.querySelector('.all .filters-selected .label').innerHTML = filterActive.innerHTML;

    this.toggleFilters(); 
  }

  renderFilters(){
    let items = this.props.datas.newstypes;
    return items.map((item, index)=>{
      return(
        <li className="d-block" key={`brands-filter-${index}`}>
          <button className="filter-btn" data-filter={item.label} onClick={this.filterItems.bind(this, item.label)}>
            <span>
              {item.name[`${this.props.lngDatas.name.data}`]}
            </span>
          </button>
        </li>
      )
    });   
  }

  selectFilter(filter) {
    this.setState({selectedFilter:filter})
  }


  displayAll(e){
    e.preventDefault();

  }

  render() {

    return (
      <div className="col-12 page news-homepage">
        {this.renderMetas()}

        <PageHero
            lngDatas={this.props.lngDatas}
            datas={this.props.datas}
            identity={false}
            surtitle={{
              type: 'text',
              content: this.props.lngDatas.news.lab_nav,
            }}
            title={{
              type: 'text',
              content: this.props.lngDatas.news.hero,
            }}
            bottom={true}
            module={'brand'}
        />

        <section className={'news__list'}>
          <ArticlesList
              title={'Nos dernières actualités'}
              articles={this.props.datas.news.filter((item,index) => {
                return item.title[this.props.lngDatas.name.data]?.length > 1
              })}
              files={this.props.datas.uploads}
              pagination={this.pagination}
              sideContent={'filtre'}
              contentType={"news"}
              newstypes={this.props.datas.newstypes}
              lngDatas={this.props.lngDatas}>
                <FilterSelect
                  lngDatas={this.props.lngDatas}
                  filters={this.props.datas.newstypes}
                />
          </ArticlesList>
        </section>
        <Footer
            datas={this.props.datas}
            didomiObject={this.props.didomiObject}
            lngDatas={this.props.lngDatas}
            footerMenuLinks={this.props.footerMenuLinks}
            footerMenuCopy={this.props.footerMenuCopy}
            locationPath={this.props.locationPath}
            params={this.props.paramsLocation}
            appControls={this.props.appControls}
            custom={
              {
                next: {
                  title: this.props.lngDatas.jobs.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.jobs.meta.slug,
                },
                prev: {
                  title: this.props.lngDatas.about.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.about.meta.slug,
                }
              }
            }
        />
      </div>
    )
  }
}
