import React, { Component } from "react";

export default class FooterNewsletter extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={'form__newsletter'}>
                <div className={'form__newsletterContainer'}>
                    <input type={'text'} className={'form__value'} placeholder={(this.props.lngDatas?.generic?.footer?.main?.newsletter?.placeholder)?this.props.lngDatas.generic.footer.main.newsletter.placeholder:''} />
                    <button type={'submit'} className={'form__submit'}> {this.props.lngDatas?.generic?.footer?.main?.newsletter?.button} </button>
                </div>
            </div>
        )
    }
}