import React, {Component} from "react";
import axios from 'axios';
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Link,
    Route,
    Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import GlideCarousel from '../../assets/scripts/GlideCarousel'
import PageHero from "../components/Hero";
import MediaComponent from "../partials/Media";
import Glide from '@glidejs/glide'
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

function string_to_slug(str) {
    str = str.toString();
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
    return str;
}

function timeSince(datee) {
    let date = new Date(datee);
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " ans";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " mois";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " jours";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " heures";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes";
    }
    return Math.floor(seconds) + " secondes";
}

export default class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.sto = {
            worker: false,
            animation: false,
        };

        this.state.publish = {};
        this.state.publish.jobs = {
            lab_nav: "Jobs",
            steps: {
                lab_step0: "Introduction",
                lab_step: "Offres d'emploi",
            },
            introduction: {
                title: 'vous souhaitez nous rejoindre ?',
                content: 'Nous rejoindre c’est intégrer une communauté de collaborateurs évoluant dans un environnement où l’innovation est le “leit motiv” et où on ose se tromper. Un environnement dans lequel nous partageons les memes valeurs d’innovation, de bienveillance, de responsabilité, d’exigence, de partage et d’inclusion. Où chaque ADN de marque est préservé mais où on développe des synergies communes pour unir nos forces.\n' +
                    'Nous racontons de vraies histoires, des histoires pleines de sens qui inspirent \n' +
                    'et encouragent à changer nos vies. Chaque Histoire compte, venez raconter \n' +
                    'la vôtre',
            }
        };
        this.state.publish.jobs.companys = [
            {
                name: 'Unify',
                slug: string_to_slug('Unify'),
                img: 'logo-unify',
                reference: 'Yy8yLa2',
            },
            {
                name: 'Auféminin',
                slug: string_to_slug('Auféminin'),
                img: 'logo-aufeminin',
                reference: 'kLDMajl',
            },
            {
                name: 'Marmiton',
                slug: string_to_slug('Marmiton'),
                img: 'logo-marmiton',
                reference: 'Q550z1P',
            },
            {
                name: 'Gamened!',
                slug: string_to_slug('Gamned'),
                img: 'logo-gamned',
                reference: 'edwxRKL',
            },
            {
                name: 'Doctissimo',
                slug: string_to_slug('Doctissimo'),
                img: 'logo-doctissimo',
                reference: 'rgwq0Nd',
            },
            {
                name: 'Vertical Station',
                slug: string_to_slug('Vertical Station'),
                img: 'logo-verticalStation',
                reference: '27wdNR5',
            },
        ];
        this.state.publish.jobs.meta = {
            title: "Unify Group - Offres d'emploi",
            slug: "jobs",
            description: "Group Unify - Découvrez nos offres d'emploi",
            keywords: "marques, marque, unify, group, groupe, régie, media, e-commerce, service"
        };
        this.state.news = 4;
        this.state.newsType = "rh";
        this.state.currentFilter = false;
        this.state.showfilters = [];
        this.state.showfilters.current = false;
        this.state.jobsItems = [];
        this.state.filterContractOrder = ['cdi', 'cdd-temporaire', 'freelance', 'stage'];
        this.state.pagination = {
            index: 1,
            totalPositions: 0,
            filteredPositions: 0,
            inView: {
                progress: 4,
                from: 0,
                to: 0,
                next: 0,
            }
        };

        this.state.filters = [
            {
                label: "Type de contrat",
                slug: 'contracts',
                slugItem: 'contract',
                values: [],
                current: false,
            },
            {
                label: "Professions",
                slug: 'profs',
                slugItem: 'prof',
                values: [],
                current: false,
            },
            {
                label: "Entreprises",
                slug: 'companys',
                slugItem: 'company',
                values: [],
                current: false,
            },
        ];
        this.state.selectedFilters = {};
        this.state.filtersItems = [];
        this.state.jobsItems = [];
        this.state.filteredJobsItems = [];
        this.state.filters.map((item, index) => {
            this.state.selectedFilters[item.slug] = [];
            this.state.filtersItems[item.slug] = React.createRef();
        });
        this.state.listAnnoncedom = React.createRef();
    }

    componentDidMount() {
        this.props.fnNav();
        const carousels = document.querySelectorAll('.gl-carousel');
        carousels.forEach((carousel, key) => {
            new GlideCarousel(carousel, {
                breakpoints: {
                    800: {
                        perView: 2
                    },
                    500: {
                        perView: 1
                    }
                }
            });
        });

        let jobsItemslist = [];
        let promesses = [];
        //console.log(this.state.publish.jobs.companys);
        this.state.publish.jobs.companys.map((item, index) => {
            let urlQuery = `https://www.welcomekit.co/api/v1/embed?organization_reference=${item.reference}`;
            promesses.push(axios.get(urlQuery));
        });
        axios.all(promesses).then((results) => {
            results.forEach((response) => {
                response.data.jobs.map((job) => {
                    job.company = response.data.name;
                });
                jobsItemslist = jobsItemslist.concat(response.data.jobs);
            });

            constructListAnnonce();
            constructFilters();
            this.filterItems();
        });

        let constructListAnnonce = () => {
            //console.log(jobsItemslist);
            // console.log(jobsItemslist);
            jobsItemslist.forEach((item, index) => {
                jobsItemslist[index] = constructItemAnnonce(item);
            });
            this.setState(state => {
                const jobsItems = jobsItemslist;
                return {
                    jobsItems
                };
            });

            this.setState({
                pagination: {
                    ...this.state.pagination,
                    totalPositions: jobsItemslist.length,
                }
            });

        };
        let constructItemAnnonce = (item) => {
            //console.log(item);
            let itemData = {};
            if (item.name) {
                itemData.titre = item.name;
            } else {
                itemData.titre = "";
            }
            if (item.published_at) {
                itemData.date = item.published_at;
            } else {
                itemData.date = false;
            }
            if (item.company) {
                itemData.company = {
                    label: item.company,
                    slug: string_to_slug(item.company),
                };
            } else {
                itemData.company = 'Unify';
            }
            if (item.office && item.office.city) {
                itemData.place = {
                    label: item.office.city,
                    slug: string_to_slug(item.office.city),
                };
            } else {
                itemData.place = {
                    label: '',
                    slug: string_to_slug(''),
                };
            }
            if (item.contract_type[this.props.lngDatas.name.data]) {
                itemData.contract = {
                    label: item.contract_type[this.props.lngDatas.name.data].replace('CDD / Temporaire', 'CDD'),
                    slug: string_to_slug(item.contract_type[this.props.lngDatas.name.data]),
                };
            } else {
                itemData.contract = {
                    label: '',
                    slug: string_to_slug(''),
                };
            }

            if (item.department && item.department.name) {
                itemData.prof = {
                    label: item.department.name,
                    slug: string_to_slug(item.department.name),
                };
            } else {
                itemData.prof = {
                    label: '',
                    slug: string_to_slug(''),
                };
            }

            if (item.websites_urls && item.websites_urls.length > 0) {
                itemData.url = item.websites_urls[0].url;
            } else {
                itemData.url = '';
            }

            return itemData;
        };
        let constructFilters = () => {
            this.state.jobsItems.map((item, index) => {
                this.state.filters.map((filter, indexFiltre) => {
                    if (item[filter.slugItem] && item[filter.slugItem].label && item[filter.slugItem].slug) {
                        let exist = this.state.filters[indexFiltre].values.filter(function (e) {
                            return e.label === item[filter.slugItem].label;
                        });
                        if (exist.length < 1) {
                            this.state.filters[indexFiltre].values.push(item[filter.slugItem]);
                        }
                    }
                });
            });

            let filtersContract = [];
            let orderContracts = ['cdi', 'cdd-temporaire', 'temps-partiel', 'alternance', 'stage', 'freelance'];


            orderContracts.map((filter, index) => {
                let exist = this.state.filters[0].values.filter(val => {
                    return val.slug === filter;
                });
                if (exist.length && exist.length > 0) {
                    exist.map((item, index) => {
                        filtersContract.push(item);
                    });
                }
            });


            this.state.filters[0].values = filtersContract;

        };

        if (this.props.datas.jobs.slider?.images && this.props.datas.jobs.slider.images?.length > 0) {
            this.officeSlider = new Glide('#slideroffice', {
                type: 'carousel',
                perView: 1,
            }).mount();
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    /**
     * Filters an array of objects by custom predicates.
     *
     * @param  {Array}  array: the array to filter
     * @param  {Object} filters: an object with the filter criteria
     * @return {Array}
     */
    filterArray(array, filters) {
        const filterKeys = Object.keys(filters);
        return array.filter(item => {
            // validates all filter criteria
            return filterKeys.every(key => {
                // ignores non-function predicates
                if (typeof filters[key] !== 'function') return true;
                return filters[key](item[key]);
            });
        });
    }

    renderFilters() {
        return this.state.filters.map((item, index) => {
            if (item.values) {
                const classList = this.state.currentFilter === item.slug ? 'unfold' : '';
                return (
                    <div id={`filter-${item.slug}`} key={index} ref={this.state.filtersItems[item.slug]}
                         data-filter={item.slug} className={'col-md-auto filters ' + classList}>
                        <div className="btn" onClick={(event) => {
                            //this.toggleFilters(item, 0);
                            // event.stopPropagation();
                        }}>
                            <div className="inside">
                                <div className="row m-0 justify-content-between filters-selected" onClick={(event) => {
                                    this.toggleFilters(item, 1);
                                    event.stopPropagation();
                                }}>
                                <span
                                    className="col-auto p-0 label">{item.current === false ? item.label : item.current}</span>
                                    <ReactSVG className="col-auto p-0 icon" src="/ui/ctas/chevron.svg"/>
                                </div>
                                <ul className="filters-select">
                                    <li className="d-block">
                                        <button className="filter-btn active" data-filter="all">
                                            <span>tous</span>
                                        </button>
                                    </li>
                                    {this.renderFiltersItems(item)}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return false;
            }
        });
    }

    handlePagination() {
        let pagination = this.state.pagination.index < Math.round(this.state.filteredJobsItems.length / this.props.lngDatas.jobs.offers?.pagination.next) ? this.state.pagination.index + 1 : this.state.pagination.index;
        this.setState({
            pagination: {...this.state.pagination, index: pagination}
        });
    }

    renderFiltersItems(item) {
        return item.values.map((val, index) => {
            let classList = this.state.selectedFilters[item.slug].indexOf(val.slug) > -1 ? 'selected' : '';
            return (
                <li className={'d-block'} key={index}>
                    <button className={'filter-btn ' + classList} onClick={() => this.updateFilters(item, val)}>
                        <ReactSVG
                            className="checked"
                            src="/ui/icons/icon-check.svg"
                        />
                        <span>{val.label.replace('CDD / Temporaire', 'CDD')}</span>
                    </button>
                </li>
            )
        });
    }

    toggleFilters(filter, item) {
        if (!this.state.filtersItems[filter.slug].current.classList.contains('unfold')) {
            this.setState({
                currentFilter: filter.slug
            });
        } else if (this.state.filtersItems[filter.slug].current.classList.contains('unfold')) {
            this.state.filtersItems[filter.slug].current.classList.remove('unfold');
            this.setState({
                currentFilter: false
            });
        }
    }

    renderNews() {
        const news = this.props.datas.news;
        console.log('news', news);
        
        let files = this.props.datas.uploads;
        let filtered = [];
        let currentType = this.props.datas.newstypes.filter(type => {
            return type.label === "rh"
        });
        const currentCat = currentType[0]._id;

        news.forEach((item) => {
            item.newstype.forEach((type) => {
                if (type._id === currentCat) {
                    filtered.push(item);
                }
            });
        });

        console.log('filtered', filtered);

        let items = [...filtered].splice(0, this.state.news);
        
        console.log('items', items);

        return items.map((item, index) => {

            let dateO = new Date(item.edit.createdAt.$date);
            const isDayHuge = dateO.getDate() >= 10;
            const isMonthHuge = dateO.getMonth() + 1 >= 10;
            let dateD = isDayHuge ? dateO.getDate() : ("0" + dateO.getDate());
            let dateM = isMonthHuge ? (dateO.getMonth() + 1) : ("0" + (dateO.getMonth() + 1));
            let dateY = dateO.getFullYear();
            let date = dateD + '-' + dateM + '-' + dateY;


            let imgSrc = "";
            if (item.visual && item.visual._id) {
                let img = item.visual._id;
                let file = findNestedObj(files, '_id', img);
                if (file) {
                    imgSrc = file.versions.thumbnail.staticPath;
                }
            }


            // on regarde quel est l'id du type actuel


            // on regarde si le tableau id de chaque new contient l'id du type actuel


            return (
                <div className="d-inline-block d-md-flex col-md-6 item glide__slide" key={`news-item-${index}`}>
                    <Link className="d-inline-block w-100 hiddenCursor"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.news.meta.slug + "/" + item.meta[`${this.props.lngDatas.name.data}`].slug}>
                        {item.visual &&
                        <figure className="d-block visual">
                            <div className="img-mask">
                                <img src={imgSrc} alt={item.title[`${this.props.lngDatas.name.data}`]}/>
                            </div>
                        </figure>
                        }
                        <span className="d-block date">
                            {date}
                        </span>
                        <span className="d-block title">
                            {item.title[`${this.props.lngDatas.name.data}`]}
                        </span>
                        <div className="arrow">
                            <ReactSVG
                                className="off"
                                src="/ui/ctas/news.svg"
                            />
                            <ReactSVG
                                className="on"
                                src="/ui/ctas/news_2.svg"
                            />
                        </div>
                    </Link>
                </div>
            )
        });
    }

    filterItems() {

        this.setState({
            pagination: {...this.state.pagination, index: 1}
        });

        let filters = {};

        if (this.state.selectedFilters['companys'].length > 0) {
            filters['company'] = company => this.state.selectedFilters['companys'].includes(company.slug);
        }
        if (this.state.selectedFilters['profs'].length > 0) {
            filters['prof'] = prof => this.state.selectedFilters['profs'].includes(prof.slug);
        }
        if (this.state.selectedFilters['contracts'].length > 0) {
            filters['contract'] = contract => this.state.selectedFilters['contracts'].includes(contract.slug);
        }

        let filtered = this.filterArray(this.state.jobsItems, filters);

        this.setState({
            filteredJobsItems: filtered,
        });
    }

    renderItemsEmploi() {

        let files = this.props.datas.uploads;

        this.state.filteredJobsItems.sort(function (a, b) {
            var dateA = new Date(a.date), dateB = new Date(b.date);
            return dateB - dateA;
        });
        return this.state.filteredJobsItems.slice(0, (this.state.pagination.index * this.props.lngDatas.jobs.offers?.pagination.next) || this.state.filteredJobsItems.length).map((item, index) => {
            let imgSrc = "";
            let brandDatas = this.props.datas.brands.filter(brandData => brandData.meta.fr.slug === item.company.slug);

            let logoDetailID = (brandDatas[0]) ? brandDatas[0].identity.logoSVG._id : '';

            let date;
            if (item.date) {
                date = timeSince(item.date);
            } else {
                date = "";
            }


            if (logoDetailID) {
                let img = logoDetailID;
                let file = findNestedObj(files, '_id', img);
                if (file) {
                    imgSrc = file.staticPath;
                } else {
                    imgSrc = '/ui/logo-unify-black.svg';
                }
            } else {
                imgSrc = '/ui/logo-unify-black.svg';
            }


            return (
                <li className="jobs__items__item" key={`job-${index}`}>
                    <a href={item.url} target="_blank">
                        <div className="item__image">
                            <ReactSVG
                                className={'logo'}
                                src={imgSrc}
                            />
                        </div>
                        <div className="item__infos">
                            <span className="company">{item.company.label}</span>
                            <span className="position">{item.titre}</span>
                            <ul className="datas">
                                <li className="data__item place">
                                    <div className="data__item__image">
                                        <img src="/ui/icons/place.svg" alt=""/>
                                    </div>
                                    <span className="data__item__title">{item.place.label}</span>
                                </li>
                                <li className="data__item contract">
                                    <div className="data__item__image">
                                        <img src="/ui/icons/work.svg" alt=""/>
                                    </div>
                                    <span className="data__item__title">{item.contract.label}</span>
                                </li>
                            </ul>
                            <span className="time">Il y a {date} </span>
                        </div>
                    </a>
                </li>
            )
        });
    }

    updateFilters(item, val) {


        if (this.state.sto.worker) {
            clearTimeout(this.state.sto.worker);
        }
        if (this.state.sto.animation) {
            clearTimeout(this.state.sto.animation);
        }

        this.state.listAnnoncedom.current.classList.add('transition');
        // this.state.selectedFilters[item.slug].push(val.slug);
        // construc newselectedFilters
        this.state.sto.worker = setTimeout(() => {

            const newselectedFilters = this.state.selectedFilters;
            let index = newselectedFilters[item.slug].indexOf(val.slug);
            if (index > -1) {
                newselectedFilters[item.slug].splice(index, 1);
            } else {
                newselectedFilters[item.slug].push(val.slug);
            }

            let _selectedFilters = newselectedFilters;


            this.setState({selectedFilters: _selectedFilters});
            this.filterItems();
        }, 500);
        this.state.sto.animation = setTimeout(() => {
            this.state.listAnnoncedom.current.classList.remove('transition');
        }, 600);


        //this.setState({ selectedFilters: newselectedFilters });


    }

    iniFooterNavCTAs(svg) {

        // CURSOR
        const cursor = document.getElementById('CursorDot'),
            follower = document.getElementById('CursorCircle');


        function hoverCtaFunc(e) {
            gsap.to(cursor, 0.3, {
                opacity: 1,
                scale: 0
            });
            gsap.to(follower, 0.3, {
                scale: 0
            });
        }

        function unhoverCtaFunc(e) {
            gsap.to(cursor, 0.3, {
                opacity: 1,
                scale: 1
            });
            gsap.to(follower, 0.3, {
                scale: 1
            });
        }

        let circleItem = svg.querySelector('.cta-ltr-circle');
        let arrowItem = svg.querySelector('.cta-ltr-arrow');


        let ctaParent = svg.parentNode;

        while (!ctaParent.classList.contains('cta') && ctaParent.parentNode !== null) {
            ctaParent = ctaParent.parentNode;
        }

        let ctacirc = gsap;
        let tlctacirc = ctacirc.timeline();
        let tlctarr = ctacirc.timeline();

        tlctacirc.startTime(0);

        tlctacirc
            .to(circleItem, {drawSVG: "0% 75%"})
            .pause();

        tlctacirc
            .fromTo(circleItem, {drawSVG: "0% 75%"}, {duration: .300, drawSVG: "100%", ease: "expo"})
            .pause();

        tlctarr
            .to(arrowItem, {opacity: 0})
            .pause();

        tlctarr
            .to(arrowItem, {duration: .300, x: 50, opacity: 1, ease: "expo"})
            .pause();

        ctaParent.addEventListener('mouseover', function (event) {
            //console.log(this);
            event.stopPropagation();
            //console.log('over');
            hoverCtaFunc();
            tlctacirc.play();
            tlctarr.play();
            //console.log(event.target);
        });

        ctaParent.addEventListener('mouseout', function (event) {
            //console.log(this);
            event.stopPropagation();
            //console.log('out');
            unhoverCtaFunc();
            tlctacirc.reverse();
            tlctarr.reverse();
            //console.log(event.target);
        });

    }

    renderMetas() {
        let title = (this.props.lngDatas.jobs.meta.title !== "") ? this.props.lngDatas.jobs.meta.title : (this.props.lngDatas.generic.meta.title !== "") ? this.props.lngDatas.generic.meta.title : "";
        let description = (this.props.lngDatas.jobs.meta.description !== "") ? this.props.lngDatas.jobs.meta.description : (this.props.lngDatas.generic.meta.description !== "") ? this.props.lngDatas.generic.meta.description : "";
        //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.jobs.meta.keywords !=="")? this.props.lngDatas.jobs.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
        let slug = this.props.lngDatas.jobs.meta.slug;
        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={slug}/>

                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content="/sm/unify_facebook.jpg"/>
                <meta property="og:url" content="{{global.url}}/index.html"/>

                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image" content="/sm/unify_twitter.jpg"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>
        )
    }

    render() {
        return (
            <>
                <section className="col-12 page jobs">


                    {this.renderMetas()}

                    <PageHero
                        lngDatas={this.props.lngDatas}
                        datas={this.props.datas}
                        identity={false}
                        surtitle={{
                            type: 'text',
                            content: this.props.lngDatas.jobs.lab_nav,
                        }}
                        title={{
                            type: 'text',
                            content: this.props.lngDatas.jobs.hero,
                        }}
                        bottom={true}
                        module={'brand'}
                    />


                    <section className={'jobs__introduction section content'}>
                        {(this.props.lngDatas.jobs.slider?.liste && this.props.lngDatas.jobs.slider?.liste?.length > 0) &&
                        <div className={"introduction__slider"}>

                            <div className="glide slider-office" id={'slideroffice'}>
                                <div className="glide__track" data-glide-el="track">
                                    <ul className="glide__slides">
                                        {this.props.lngDatas.jobs.slider?.liste.map((item, index) => {
                                            let media = false;
                                            if (item._id) {
                                                media = findNestedObj(this.props.datas.uploads, "_id", item._id);
                                            }
                                            if (media) {
                                                return (
                                                    <li className="glide__slide item-expertise"
                                                        key={`office-image-item-${index}`}>
                                                        <MediaComponent
                                                            media={media}
                                                            version={'original'}
                                                        />
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className={'slider__controls'}>
                                <button className={'slider__control'} onClick={() => {
                                    this.officeSlider.go('<')
                                }}>
                                    <ReactSVG
                                        className="arrow"
                                        src="/ui/icons/arrow-slider.svg"
                                    />
                                </button>
                                <button className={'slider__control'} onClick={() => {
                                    this.officeSlider.go('>')
                                }}>
                                    <ReactSVG
                                        className="arrow"
                                        src="/ui/icons/arrow-slider.svg"
                                    />
                                </button>
                            </div>
                            <p className={"slider__title"}
                               dangerouslySetInnerHTML={{__html: this.props.lngDatas.jobs.slider.title}}></p>
                        </div>
                        }
                        <div className={"introduction__content"}>
                            <p className={"section__title"}
                               dangerouslySetInnerHTML={{__html: this.props.lngDatas.jobs.introduction.catch}}></p>
                            <p className={"section__content"}
                               dangerouslySetInnerHTML={{__html: this.props.lngDatas.jobs.introduction.text}}></p>
                        </div>
                    </section>

                    <div className="jobs__list">

                        <div className="jobs__banner">
                            <a href="https://talentdating-groupetf1.postule.fr/?utm_source=site_carriere_unify" target="_blank">
                                <img class="jobs__banner__img" src="/ui/unify_baniere-carriere.jpg" alt="*" />
                            </a>
                        </div>


                        <div className="jobs__title">
                            <p className="surtitre">{this.props.lngDatas.jobs?.offers?.title}</p>
                            <h2 className="title">
                                <span>{this.state.filteredJobsItems.length} {this.props.lngDatas.jobs?.offers?.dispos}</span>
                            </h2>
                        </div>
                        <div className="jobs__filter">
                            {this.renderFilters()}
                        </div>
                        <div className="jobs__items">
                            <ul className="jobs__items__list" ref={this.state.listAnnoncedom}>
                                {this.renderItemsEmploi()}
                            </ul>


                            {
                                ((this.state.pagination.index) * this.props.lngDatas.jobs.offers?.pagination.next < this.state.filteredJobsItems.length) &&

                                <div className="jobs__cta cta hiddenCursor pagination"
                                     onClick={(event) => {
                                         this.handlePagination();
                                     }}>
                                <span
                                    className={`pagination`}>{this.props.lngDatas.jobs.offers?.pagination.from} {((this.state.pagination.index + 1) * this.props.lngDatas.jobs.offers?.pagination.next) > this.state.filteredJobsItems.length ? this.state.filteredJobsItems.length : (this.state.pagination.index + 1) * this.props.lngDatas.jobs.offers?.pagination.next} {this.props.lngDatas.jobs.offers?.pagination.to} {this.state.filteredJobsItems.length}</span>
                                    <div className={`circle`}>
                                        <ReactSVG
                                            className="circlesvg"
                                            src="/ui/ctas/a_circle_full_ltr.svg"
                                            renumerateIRIElements={false}
                                            afterInjection={(error, svg) => {
                                                if (error) {
                                                    console.error(error)
                                                    return
                                                } else {
                                                    this.iniFooterNavCTAs(svg)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                            }


                            <div className="col-12 p-xl-0 block align-items-center cv-anchor">
                                <p> {this.props.lngDatas.jobs.offers?.spont.first} <Link to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.contacts.meta.slug}`} className="animatedLink visible"><span>{this.props.lngDatas.jobs.offers?.spont.button}</span></Link> {this.props.lngDatas.jobs.offers?.spont.last}
                                </p>
                            </div>
                        </div>
                    </div>


                </section>

                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.lngDatas.brands.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.brands.meta.slug,
                            },
                            prev: {
                                title: this.props.lngDatas.news.lab_nav,
                                path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.news.meta.slug,
                            }
                        }
                    }
                />
            </>
        )
    }
}