import React, { Component } from "react";
import ButtonCircle from "../../partials/buttonCircle";
import FooterNewsletter from "./FooterNewsletter";
import {Link, NavLink} from "react-router-dom";
import ListSocials from "../../partials/socials";


export default class FooterNav extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={'footer__navigation'}>
                {this.props.custom?.prev &&
                <Link to={this.props.custom.prev.path} className={'btn-footernav prev'}>{this.props.lngDatas.generic.footer.navigation?.prev}</Link>
                }
                <div className={'navigation'}>
                    { this.props.custom?.prev?.title &&
                    <div className={'navigation__item'}>
                        <ButtonCircle
                            target={{
                                name: this.props.custom.prev.title.length > 25 ? this.props.custom.prev.title.substr(0, 22)+'...' : this.props.custom.prev.title,
                                path: this.props.custom.prev.path,
                            }}
                        />
                    </div>
                    }
                    { this.props.custom?.next?.title &&
                    <div className={'navigation__item'}>
                        <ButtonCircle
                            target={{
                                name: this.props.custom.next.title.length > 25 ? this.props.custom.next.title.substr(0, 22)+'...' : this.props.custom.next.title,
                                path: this.props.custom.next.path,
                            }}
                        />
                    </div>
                    }

                </div>
                {this.props.custom?.next &&
                <Link to={this.props.custom.next.path} className={'btn-footernav next'}>{this.props.lngDatas.generic?.footer?.navigation?.next}</Link>
                }
            </div>
        )
    }
}