import { ReactSVG } from "react-svg";
import React, {Component} from "react";





export default class ListSocials extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <ul className={'socials__list'}>
                {this.props.lngDatas.generic.socials.linkedin !=="" &&
                    <li className="social-link">
                        <a href={this.props.lngDatas.generic.socials.linkedin} target="_blank">
                            <ReactSVG
                                className="icon"
                                src="/ui/icons/icon-linkedin.svg"
                            />
                        </a>
                    </li>
                }
                {this.props.lngDatas.generic.socials.facebook !=="" &&
                    <li className="social-link">
                        <a href={this.props.lngDatas.generic.socials.facebook} target="_blank">
                            <ReactSVG
                                className="icon"
                                src="/ui/icons/icon-facebook.svg"
                            />
                        </a>
                    </li>
                }
                {this.props.lngDatas.generic.socials.twitter !=="" &&
                    <li className="social-link">
                        <a href={this.props.lngDatas.generic.socials.twitter} target="_blank">
                            <ReactSVG
                                className="icon"
                                src="/ui/icons/icon-twitter.svg"
                            />
                        </a>
                    </li>
                }
            </ul>
        )
    }


}
