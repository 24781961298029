import React from "react";

class ImageLazy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageStatus: "loading",
        };
        this.itemNode =  React.createRef();

    }

    componentDidMount() {
        this.itemNode.current.parentNode.classList.add('imageParentLoading');
    }

    handleImageLoaded() {
        //this.setState({ imageStatus: "loaded" });
        //this.itemNode.current.parentNode.classList.remove('imageParentLoading');
    }

    handleImageErrored() {
        this.setState({ imageStatus: "failed" });
    }

    render() {
        return (
                <img
                    ref={this.itemNode}
                    className={'image imageLazy ' + (this.props.cls !== undefined ? this.props.cls : '')  + ' ' + this.state.imageStatus }
                    src={this.props.imageUrl+'87'}
                    alt={this.props.alt}
                    onLoad={this.handleImageLoaded.bind(this)}
                    onError={this.handleImageErrored.bind(this)}
                />
        );
    }
}
export default ImageLazy;