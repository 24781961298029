import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Route,
    Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";

import BrandsHomepage from './Brands.Homepage';
import BrandsDetail from './Brands.Detail';
import ExpertisesDetail from "./Expertises.Detail";
import PageHero from "../components/Hero";

import ExpertisesHomepage from "./Expertises.Homepage";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

function RenderLngCtn(props){
    let lngDatas = props.lngDatas;
    let lngSelected = lngDatas.name.data;
    let datas = props.datas;
    let items =  (props.datas.expertises && props.datas.expertises.length > 0)?props.datas.expertises:[];

    // console.log(props);

    // let datas = {
    //   brands : props.brands,
    //   news : props.news,
    //   newscontactuser : props.newscontactuser,
    //   tags :  props.tags,
    //   types : props.types,
    //   universes : props.universes,
    //   uploads : props.uploads
    // };

    let routes = [
    ];

    items.map((item, index)=>{
        item.index = index;
        if ( item.meta && item.meta[lngDatas.name.data] && item.meta[lngDatas.name.data].slug ) {
            let route = {
                path : "/"+lngDatas.name.data+"/"+lngDatas.expertises.meta.slug+"/"+item.meta[lngDatas.name.data].slug,
                lab_nav : item.identity.name,
                contentData : {...item},
                component : ExpertisesDetail,
            };

            routes.push(route);
        }
    });

    routes.push(
        {
            path : "/"+lngDatas.name.data+"/"+lngDatas.expertises.meta.slug+"/:expertiseSlug",
            lab_nav : "home",
            component : ExpertisesHomepage,
        });

    routes.push(
        {
            path : "/"+lngDatas.name.data+"/"+lngDatas.expertises.meta.slug,
            lab_nav : "home",
            component : ExpertisesHomepage,
        });



    return(
        <Switch>
            {routes.map((route, index) => (
                    <Child
                        key={index}
                        {...route}
                        datas={datas}
                        lngDatas={lngDatas}
                        appControls={props.appControls}
                    />
                )
            )}
        </Switch>
    )
}

function Child(datasObj) {
    return (
        <Route
            exact
            path={datasObj.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <datasObj.component
                    {...props}
                    contentData={datasObj.contentData}
                    lngDatas={datasObj.lngDatas}
                    datas={datasObj.datas}
                    appControls={datasObj.appControls}
                />
            )}
        />
    )
}


export default class Expertises extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount(){
        this.props.appControls.handleNavigationPanel(false);
    }
    render() {
        return (
            <section className="col-12 page brands">
                <RenderLngCtn {...this.props} />
            </section>
        )
    }
}
