import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import IsVisible from "../../assets/scripts/IsVisible";
import GlideCarousel from '../../assets/scripts/GlideCarousel'
import ArticlesList from "../components/articlesList";


import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";

import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Link,
    Route,
    Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";
import PageHero from "../components/Hero";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class NewsDetail extends Component {
    constructor(props) {
        super(props);

        //console.log(this.props);

        this.state = {
            latestN: 5
        }

        this.news = this.props.datas.news;
        this.highlighted = [...this.news].slice(0, 1);
        //console.log(this.highlighted);
        this.latest = [...this.news].slice(1, this.state.latestN + 1);

    }

    componentDidMount() {
        gsap.registerPlugin(MorphSVGPlugin);



    }

    renderMetas() {

        let title = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].title !== "") ? this.props.contentData.meta[`${this.props.lngDatas.name.data}`].title : (this.props.lngDatas.brands.meta.title !== "") ? this.props.lngDatas.brands.meta.title : (this.props.lngDatas.generic.meta.title !== "") ? this.props.lngDatas.generic.meta.title : "";
        let description = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].description !== "") ? this.props.contentData.meta[`${this.props.lngDatas.name.data}`].description : (this.props.lngDatas.brands.meta.description !== "") ? this.props.lngDatas.brands.meta.description : (this.props.lngDatas.generic.meta.description !== "") ? this.props.lngDatas.generic.meta.description : "";
        //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.brands.meta.keywords !=="")? this.props.lngDatas.brands.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
        let slug = this.props.contentData.meta[`${this.props.lngDatas.name.data}`].slug;
        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={slug}/>
            </Helmet>
        )
    }


    renderDate(dateData) {

        let dateO = new Date(dateData);
        const isDayHuge = dateO.getDate() >= 10;
        const isMonthHuge = dateO.getMonth() + 1 >= 10;
        let dateD = isDayHuge ? dateO.getDate() : ("0" + dateO.getDate());
        let dateM = isMonthHuge ? (dateO.getMonth() + 1) : ("0" + (dateO.getMonth() + 1));
        let dateY = dateO.getFullYear();
        let date = dateD + '-' + dateM + '-' + dateY;


        return (
            date
        )
    }

    renderVisual() {
        let files = this.props.datas.uploads;
        let imgSrc = "";
        if (this.props.contentData.visual && this.props.contentData.visual._id) {
            let img = this.props.contentData.visual._id;
            let file = findNestedObj(files, '_id', img);
            if (file) {
                imgSrc = file.versions.large.staticPath;
            }
        }

        return this.props.contentData.visual && this.props.contentData.visual._id ? (
            <figure className="row visual">
                <img src={imgSrc} alt={this.props.contentData.title[`${this.props.lngDatas.name.data}`]}/>
            </figure>
        ) : ('')
    }


    formatBytes(bytes, decimals, binaryUnits) {
        if (bytes == 0) {
            return '0 Bytes';
        }
        var unitMultiple = (binaryUnits) ? 1024 : 1000;
        var unitNames = (unitMultiple === 1024) ? // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
            ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] :
            ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
        return parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)) + ' ' + unitNames[unitChanges];
    }


    renderPDF(_id) {
        //console.log(_id);
        let files = this.props.datas.uploads;
        let file = findNestedObj(files, '_id', _id);
        let fileSrc = file.staticPath;
        let size = this.formatBytes(file.size, 1);
        return (
            <li className="pdf">
                <a href={fileSrc} download>
                    <ReactSVG
                        src={'/ui/icons/icon-download.svg'}
                        className={'svg download'}
                    />
                    {/*<span>
                        {file.ext.toUpperCase()}({size.toLowerCase()})
                    </span>
                    */}
                </a>
            </li>
        )
    }

    renderNewsContactUser() {

        let newsNcus = this.props.contentData.newscontactuser;
        let colNum = 12 / newsNcus.length;
        let ncus = this.props.datas.newscontactuser;

        return newsNcus.map((item, index) => {
            let ncu = findNestedObj(ncus, '_id', item._id);
            let imgSrc = "";
            if (ncu.visual && ncu.visual._id && ncu.visual._id !== "") {
                let files = this.props.datas.uploads;
                let img = findNestedObj(files, '_id', ncu.visual._id);
                if (img) {
                    imgSrc = img.versions.thumbnail.staticPath;
                }
            }
            return (
                <div className={`col-${colNum}`} key={`newscontactuser-${index}`}>
                    <div className="row cont-user">
                        {ncu.visual && ncu.visual._id && ncu.visual._id !== "" &&
                        <figure className="col-3 picture">
                            <img src={imgSrc} alt={ncu.firstName + " " + ncu.lastName}/>
                        </figure>
                        }
                        <div className="col-9 informations">
                            <h5 className="name">
                <span>
                  {ncu.firstName + " " + ncu.lastName}
                </span>
                            </h5>
                            <p className="job">
                                {ncu.job[`${this.props.lngDatas.name.data}`]} - {ncu.company}
                            </p>
                            <p className="mailaddress animatedLink">
                <span>
                  {ncu.email}
                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )
        });

    }

    renderItems(datas, type, col) {
        let files = this.props.datas.uploads;

        return datas.map((item, index) => {
            let imgSrc = "";
            if (item.visual && item.visual._id) {
                let img = item.visual._id;
                let file = findNestedObj(files, '_id', img);
                if (file) {
                    imgSrc = file.versions[`${type}`].staticPath;
                }
            }

            return (
                <div className={col + " item" +' glide__slide'} key={`news-${type}-${index}`}>
                    <Link
                        to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.news.meta.slug + "/" + item.meta[`${this.props.lngDatas.name.data}`].slug}>
                        {item.visual && item.visual._id &&
                        <figure className="visual">
                            <div className="img-mask">
                                <img src={imgSrc} alt={item.title[`${this.props.lngDatas.name.data}`]}/>
                            </div>
                        </figure>
                        }
                        <span className="date">
                            {this.renderDate(item.edit.createdAt.$date)}
                        </span>
                        <h3 className="title">
                            <span>
                                {item.title[`${this.props.lngDatas.name.data}`]}
                            </span>
                        </h3>
                        <div className="arrow">
                            <ReactSVG
                                className="off"
                                src="/ui/ctas/news.svg"
                            />
                            <ReactSVG
                                className="on"
                                src="/ui/ctas/news_2.svg"
                            />
                        </div>
                    </Link>
                </div>
            )
        });
    }

    render() {
        return (
            <div className="col-12 page news-detail">

                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    identity={this.props.contentData.identity}
                    surtitle={{
                        type: 'text',
                        content: new Date(this.props.contentData.edit.createdAt.$date).toLocaleDateString(`${this.props.lngDatas.name.data}-${this.props.lngDatas.name.short}`, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } ),
                    }}

                    title={{
                        type: 'text',
                        content: this.props.contentData.title[this.props.lngDatas.name.data]
                    }}
                    media={findNestedObj(this.props.datas.uploads,"_id", this.props.contentData.visual._id )}
                    subtitle={false}
                    bottom={true}
                    module={'brand'}
                />

                <div className={'detail__close'}>
                    <Link to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/`}>
                    <button className={'close__page'}>
                        <ReactSVG
                            src={'/ui/icons/close.svg'}
                            className={'close__page__item'}
                        />
                    </button>
                    </Link>
                </div>

                <section className={'section content layout news__content'}>
                    <div className={"side__share"}>
                        <div className={"share__container"}>
                            <p className={"share__title"}>Partager sur</p>
                            <ul className={"share__list"}>
                                <li className={"share__item linkedin"}>
                                    <LinkedinShareButton
                                        className="btn-social"
                                        children={
                                            <ReactSVG
                                                src={'/ui/icons/icon-linkedin.svg'}
                                                className={'svg linkedin'}
                                            />}
                                        url={document.location.href}
                                    />
                                </li>
                                <li className={"share__item facebook"}>
                                    <FacebookShareButton
                                        className="btn-social"
                                        children={
                                            <ReactSVG
                                                src={'/ui/icons/icon-facebook.svg'}
                                                className={'svg facebook'}
                                            />}
                                        url={document.location.href}
                                    />
                                </li>
                                <li className={"share__item twitter"}>
                                    <TwitterShareButton
                                        className="btn-social"
                                        children={
                                            <ReactSVG
                                                src={'/ui/icons/icon-twitter.svg'}
                                                className={'svh twitter'}
                                            />}
                                        url={document.location.href}
                                    />
                                </li>
                                {this.props.contentData.pdf[`${this.props.lngDatas.name.data}`] && this.props.contentData.pdf[`${this.props.lngDatas.name.data}`]._id && this.props.contentData.pdf[`${this.props.lngDatas.name.data}`]._id !== "" &&
                                    this.renderPDF(this.props.contentData.pdf[`${this.props.lngDatas.name.data}`]._id)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={"side__content"}>
                        <div className={"new__detailContent"} dangerouslySetInnerHTML={{__html: this.props.contentData.text[this.props.lngDatas.name.data]}}></div>


                        { (this.props.contentData.newscontactuser && this.props.contentData.newscontactuser.length>0) &&
                        <div className={'new__detailContact'}>
                            <p className={'contacts-title'}>{this.props.lngDatas.news.lab_contact}</p>
                            <ul className={'details-contact'}>
                                {this.props.contentData.newscontactuser.map((item, key) => {
                                    if ( item._id) {
                                        let contact = findNestedObj(this.props.datas.newscontactuser, "_id", item._id);
                                        if ( contact ) {
                                            return (
                                                <li key={key} className={'contact-item'}>
                                                    <p className={"contact-position"}>{contact.job[this.props.lngDatas.name.data]}</p>
                                                    <p className={"contact-name"}>{`${contact.firstName} ${contact.lastName}`}</p>
                                                    <p className={"contact-email"}>{contact.email}</p>
                                                </li>
                                            )
                                        }
                                    }
                                })}
                            </ul>


                        </div>
                        }

                    </div>
                </section>

                <div className={'latest__news'}>
                    <ArticlesList
                        title={'Nos dernières actualités'}
                        articles={this.props.datas.news.slice(0,3)}
                        files={this.props.datas.uploads}
                        lngDatas={this.props.lngDatas}
                    />
                </div>

                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.contentData.index != this.props.datas.news.length-1 ? this.props.datas.news[this.props.contentData.index+1].title[this.props.lngDatas.name.data] : this.props.datas.news[0].title[this.props.lngDatas.name.data],
                                path:  this.props.contentData.index != this.props.datas.news.length-1 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${this.props.datas.news[this.props.contentData.index+1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${this.props.datas.news[0].meta[this.props.lngDatas.name.data].slug}` ,
                            },
                            prev: {
                                title: this.props.contentData.index > 0 ? this.props.datas.news[this.props.contentData.index-1].title[this.props.lngDatas.name.data] : this.props.datas.news[this.props.datas.news.length-1].title[this.props.lngDatas.name.data],
                                path:  this.props.contentData.index > 0 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${this.props.datas.news[this.props.contentData.index-1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${this.props.datas.news[this.props.datas.news.length-1].meta[this.props.lngDatas.name.data].slug}` ,
                            }
                        }
                    }
                />
            </div>
        )
    }
}
