import React, {Component} from "react";
import { ReactSVG } from "react-svg";
import ButtonCircle from "../partials/buttonCircle";
import ListSocials from "../partials/socials";


export default class ContactModal extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <section className={`contactPage`}>

                <div className={'contact__container'}>

                    <div className={'contact__wrapper'}>

                        <div className={'contact__content'}>
                            <div className={'contact__head'}>
                                <div className={'contact__title'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.contacts.hero}}></div>
                                <div className={'contact__description'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.contacts.introduction.text}}></div>
                                <div className={'contact__adress'}>
                                    <ReactSVG
                                        className="circle"
                                        src="/ui/icons/circle.svg"
                                    />
                                    <div className={'adress__description'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.contacts.address}}></div>
                                </div>
                            </div>
                            <div className={'contact__legals desktop'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.contacts.form.rgpd_text}}></div>
                        </div>
                        <div className={'contact__form'}>

                            <div className={'form__row'}>
                                <input type={"text"} className={'form__input'} placeholder={this.props.lngDatas.contacts.form.lab_lastName}/>
                                <input type={"text"} className={'form__input'} placeholder={this.props.lngDatas.contacts.form.lab_email}/>
                            </div>
                            <div className={'form__row'}>
                                <input type={"text"} className={'form__input'} placeholder={this.props.lngDatas.contacts.form.lab_company}/>
                                <input type={"text"} className={'form__input'} placeholder={this.props.lngDatas.contacts.form.lab_job}/>
                            </div>

                            <div className={'form__select'}>
                                <p className={'select__title'}>{this.props.lngDatas.contacts.form.lab_entity_select}</p>
                                <div className={'select__container'}>
                                    <div className={'select__value'}>
                                        <ReactSVG
                                            className="chevron"
                                            src="/ui/icons/chev.svg"
                                        />
                                    </div>
                                    <select className={'select__item'} name="object" id="form__object">
                                        <option value="parrot">Candidature</option>
                                        <option value="spider">Contact</option>
                                        <option value="goldfish">Information</option>
                                    </select>
                                </div>
                            </div>

                            <div className={'form__message'}>
                                <p className={'select__title'}>{this.props.lngDatas.contacts.form.lab_entity}</p>
                                <textarea className={'textarea__form'} id="content" name="message"/>
                                <ReactSVG
                                    className="resizer"
                                    src="/ui/icons/resizer.svg"
                                />
                            </div>

                            <span className={'info'}>* champs obligatoires</span>

                            <ButtonCircle
                                target={ false }
                                action={{
                                    ref: true,
                                    name: this.props.lngDatas.contacts.form.lab_btn_send,
                                }}
                            />
                            <div className={'contact__legals mobile'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.contacts.form.rgpd_text}}></div>

                        </div>

                    </div>

                    {this.props.lngDatas &&
                        <ListSocials
                            lngDatas={this.props.lngDatas}
                        />
                    }

                </div>

            </section>
        )
    }
}
