import React, { Component } from "react";
import ArticlesList from "../components/articlesList";
import FilterSelect from "../partials/filterSelect";
import FilterList from "../partials/filterList";
import {Helmet} from "react-helmet";
import PageHero from "../components/Hero";

export default class BlogHomePage extends Component {
    constructor(props) {
        super(props);

        this.state={
            selectedFilter: false,
            filters: [],
            articles: [],
            tags: [],
        };

        this.pagination = {
            setup:3,
            increase:3,
        };

    }

    renderMetas(){

        let title = (this.props.lngDatas.blog.meta.title !=="")? this.props.lngDatas.blog.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
        let description = (this.props.lngDatas.blog.meta.description !=="")? this.props.lngDatas.blog.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
        //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.news.meta.keywords !=="")? this.props.lngDatas.news.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
        let slug = this.props.lngDatas.blog.meta.slug;
        return(
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={slug} />
            </Helmet>
        )
    }

    selectFilter(filter) {
        this.setState({selectedFilter:filter})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.datas.blog.articles != this.props.datas.blog.articles) {
            if ( this.props.datas.blog.articles && this.props.datas.blog.articles.length > 0) {
                this.setState({articles: this.props.datas.blog.articles});
            }
        }
        if (prevProps.datas.blog.tags != this.props.datas.blog.tags) {
            if ( this.props.datas.blog.tags && this.props.datas.blog.tags.length > 0) {
                this.setState({tags: this.props.datas.blog.tags});
            }
        }

        if ( prevState.tags !== this.state.tags || prevState.articles !== this.state.articles) {
            if ( this.state.tags.length > 0 && this.state.articles.length > 0 ) {
                const filters = this.getFilterFromAPI(this.state.articles,this.state.tags);
                this.setState({filters:filters});
            }
        }
    }

    getFilterFromAPI(articles,tags) {
        if ( articles && articles.length > 0 && tags && tags.length> 0) {
            let filters = new Set();
            articles.map((article)=>{
                if ( article.tag_ids && article.tag_ids.length>0) {
                    let _tag = article.tag_ids[0];
                    let tagObject = tags.filter((tag)=>{
                        return parseInt(tag.id) === _tag
                    });
                    if ( tagObject.length > 0) {
                        filters.add(tagObject[0]);
                    }
                }
            });
            if ( filters.size > 0) {
                return filters;
            } else {
                return [];
            }
        }
        else {
            return [];
        }

    }

    componentDidMount() {
        this.props.appControls.handleNavigationPanel(false);

        if ( this.props.datas.blog.articles.length > 0 && this.props.datas.blog.tags.length > 0) {
            this.setState({articles: this.props.datas.blog.articles});
            this.setState({tags: this.props.datas.blog.tags});
            const filters = this.getFilterFromAPI(this.props.datas.blog.articles,this.props.datas.blog.tags.length);
            this.setState({filters:filters});
        }
    }

    render() {
        return (

            <section className={'blog_homepage page'}>
                {this.renderMetas()}

                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    title={{
                        type: 'text',
                        content: this.props.lngDatas.blog.hero,
                    }}
                    bottom={true}
                    module={'blog'}
                />

                <section className={'articles__list highlighted'}>
                    <ArticlesList
                        title={'À la une'}
                        articles={this.props.datas.blog.articles.slice(0,3)}
                        files={this.props.datas.uploads}
                        lngDatas={this.props.lngDatas}
                        presentation={"list"}
                        contentType={"blog"}
                        datas={this.props.datas}
                    />
                </section>

                <section className={'articles__list main__list'}>
                    <ArticlesList
                        title={'Nos derniers articles'}
                        articles={this.props.datas.blog.articles}
                        files={this.props.datas.uploads}
                        sideContent={"filtre"}
                        presentation={"list"}
                        contentType={"blog"}
                        pagination={this.pagination}
                        datas={this.props.datas}
                        lngDatas={this.props.lngDatas}>
                            <FilterList
                                lngDatas={this.props.lngDatas}
                                filters={this.state.filters}
                            />
                    </ArticlesList>
                </section>
            </section>
        )
    }
}
