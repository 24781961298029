import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import ReactPlayer from 'react-player'

export default class MediaComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video : {
                playing : (this.props.autoplay)?this.props.autoplay:false,
            }
        }

        //console.log('MediaComponent props', props);
    }

    renderImage() {
        if ( this.props.media?.isImage ) {
            if ( this.props.media.extension === "svg") {
                let file = this.props.media?.staticPath;
                if ( file ) {
                    return (
                        <ReactSVG
                            className="media-svg"
                            src={file}
                            renumerateIRIElements={false}
                        />
                    )
                }
            } else {
                // Check if version exists
                let version = (this.props.media?.versions[`${this.props.version}`])?this.props.media?.versions[`${this.props.version}`]:(this.props.media?.versions[`original`])?this.props.media?.versions[`original`]:this.props.media;

                // Check if version has staticPath
                let url = (version.staticPath && version.staticPath.trim())?version.staticPath:version.path;




                console.log('this.props.media', this.props.media);
                // if ( this.props.media.versions['thumbnail'] ) {
                //     url = this.props.media.versions['thumbnail'].staticPath || this.props.media.versions['thumbnail'].path;
                // } else if (  this.props.media.versions['original'] ) {
                //     url = this.props.media.versions['original'].staticPath || this.props.media.versions['original'].path;
                // } else {
                //     url =  this.props.media.path;
                // }
                //console.log('MediaComponent / renderImage / url:', url);
                if ( url ) {
                    return (
                        <img className={'media__item'} src={url} />
                    )
                }
            }
        } else if ( this.props.media?.isUrl) {
            return (
                <img src={this.props.media.path} className={'media__url'}/>
            )
        } else {
            return false;
        }
    }

    renderVideo() {
        if ( this.props.media?.isVideo) {
            //console.log('renderVideo / media', this.props.media);

            let version = (this.props.media?.versions[`${this.props.version}`])?this.props.media.versions[`${this.props.version}`]:false;
            //console.log('version', version);
            let url = (version)?(version.staticPath)?version.staticPath:version.path:false;
            if ( url && this.props.media.extension === "mp4") {
                return (
                    <div className={`media__video ${this.state.video.playing ? 'play':'paused'}`} onClick={()=>{this.setState({video: {...this.state.video, playing: !this.state.video.playing}})}}>
                        <div className={"play__toggle"}>
                            <ReactSVG
                                className="media-svg"
                                src={'/ui/icons/play.svg'}
                            />
                        </div>
                        <ReactPlayer
                            className={'media__item'}
                            playing={this.state.video.playing}
                            loop={true}
                            controls={false}
                            muted={true}
                            url={url} />
                    </div>

                )
            }
        }
    }

    render() {
        return (
            <div className={'module media'}>
                <div className={'media__container'}>
                {this.renderImage()}
                {this.props.media?.isVideo &&
                    this.renderVideo()
                }
                </div>
            </div>
        )
    }
}