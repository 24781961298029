import React, { Component } from "react";


export default class SectionStep extends Component {
    constructor(props) {
        super(props);
    }


    render() {



        return (
            <div className={'module section content__step'}>
                <p className={'step__title'}></p>
                <p className={'step__content'}></p>
            </div>
        )
    }
}