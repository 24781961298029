import { ReactSVG } from "react-svg";
import {Link} from "react-router-dom";
import React, {Component} from "react";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";




export default class ButtonCircle extends Component {
    constructor(props) {
        super(props);


        gsap.registerEffect({
            name: "fadeOut",
            effect: (targets, config) => {
                return gsap.to(targets, {duration: config.duration, opacity: 0});
            },
            defaults: {duration: 4}, //defaults get applied to any "config" object passed to the effect
            extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
        });
        gsap.registerEffect({
            name: "fadeIn",
            effect: (targets, config) => {
                return gsap.to(targets, {duration: config.duration, opacity: 1});
            },
            defaults: {duration: 4}, //defaults get applied to any "config" object passed to the effect
            extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
        });
    }

    componentDidMount() {
        gsap.registerPlugin(MorphSVGPlugin);
        gsap.registerPlugin(DrawSVGPlugin);
    }

    iniFooterNavCTAs(svg){

        //console.log('ButtonCircle iniFooterNavCTAs svg', this.props.id, svg);
        // CURSOR
        let circleItem = svg.querySelector('.cta-ltr-circle');
        let arrowItem = svg.querySelector('.cta-ltr-arrow');


        let ctaParent = svg.parentNode;

        while(!ctaParent.classList.contains('cta-circle') && ctaParent.parentNode !== null){
            ctaParent = ctaParent.parentNode;
        }

        let ctacirc = gsap;
        let tlctacirc = ctacirc.timeline();
        let tlctarr = ctacirc.timeline();

        tlctacirc.startTime(0);

        tlctacirc
            .to(circleItem, {drawSVG:"0% 75%"})
            .pause();

        tlctacirc
            .fromTo(circleItem, {drawSVG:"0% 75%"},{duration:.300, drawSVG:"100%", ease: "expo"})
            .pause();

        tlctarr
            .to(arrowItem, { opacity:0})
            .pause();

        tlctarr
            .to(arrowItem, {duration:.300, x: 50, opacity:1, ease: "expo"})
            .pause();

        ctaParent.addEventListener('mouseover', function(event){
            //console.log(this);
            event.stopPropagation();
            //console.log('over');
            tlctacirc.play();
            tlctarr.play();
            //console.log(event.target);
        });

        ctaParent.addEventListener('mouseout', function(event){
            //console.log(this);
            event.stopPropagation();
            //console.log('out');
            tlctacirc.reverse();
            tlctarr.reverse();
            //console.log(event.target);
        });


        if(this.props.fnCount){
            //console.log('ButtonCircle props', this.props);
            //console.log('btn event init done');
            
            this.props.fnCount(this.props.id);
        }
    }

    render() {
        if (this.props.target?.path && this.props.target?.name) {
            return (
                <Link to={this.props.target.path} className="buttonCircle cta-circle">
                    <span>
                      {this.props.target.name}
                    </span>
                    <div className="circle">
                        <ReactSVG
                            className="circlesvg"
                            src="/ui/ctas/a_circle_full_ltr.svg"
                            renumerateIRIElements={false}
                            afterInjection={(error, svg) => {
                                if (error) {
                                    console.error(error)
                                    return
                                } else {
                                    this.iniFooterNavCTAs(svg)
                                }
                            }}
                        />
                    </div>
                </Link>
            )
        } else if (this.props.action?.name && this.props.action?.ref) {
            return (
                <div className="buttonCircle cta-circle" onClick={()=>{ this.props.action.ref() }}>
                    <span>
                        {this.props.action.name}
                    </span>
                    <div className="circle">
                        <ReactSVG
                            className="circlesvg"
                            src="/ui/ctas/a_circle_full_ltr.svg"
                            renumerateIRIElements={false}
                            afterInjection={(error, svg) => {
                                if (error) {
                                    console.error(error)
                                    return
                                } else {
                                    this.iniFooterNavCTAs(svg)
                                }
                            }}
                        />
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

}
