import React, { Component } from "react";
import {
  Link
} from "react-router-dom";
import ButtonCircle from "../partials/buttonCircle";
import { ReactSVG } from "react-svg";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}


export default class Brands extends Component {
    constructor(props) {
        super(props);

        this.state={
            items : this.props.brands.sort((a, b) => a.order[this.props.lngDatas.name.data] - b.order[this.props.lngDatas.name.data]).slice(0,18)
        }
    }

    render() {
        return (
            <section className={'home__brands section fullWidth containered'}>
                <div className={'brands__list'}>
                    {this.state.items.map((item,index)=> {
                        let imgSrc="";
                        if(item.identity?.logoSVG && item.identity?.logoSVG._id){
                            let img = item.identity.logoSVG._id;
                            let file = findNestedObj(this.props.datas.uploads, '_id', img);
                            //console.log(file);
                            if(file){
                                imgSrc = file.staticPath;
                            }
                        }
                        return (
                            <div className={'brand__item'} key={'footer-brand-item-'+index}>
                                <div className={'item__container'}>
                                    <Link className="item-brand" to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.brands.meta.slug+"/"+item.meta[`${this.props.lngDatas.name.data}`].slug}>
                                        <ReactSVG
                                            className={'logo'}
                                            src={imgSrc}
                                        />
                                    </Link>
                                </div>
                            </div>
                        )
                    }) }
                </div>
                {this.props.lngDatas?.brands?.lab_cta_all &&
                <ButtonCircle
                    target={{
                        path: `/${this.props.lngDatas.name.data}/${this.props.lngDatas.brands.meta.slug}`,
                        name: this.props.lngDatas.brands.lab_cta_all
                    }}
                />
                }
            </section>
        )
    }
}