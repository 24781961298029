import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";

import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Link,
    Route,
    Redirect,
    useRouteMatch,
    useParams

} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import PageHero from "../components/Hero";
import MediaComponent from "../partials/Media";
import ArticlesList from "../components/articlesList";
import Footer from "../components/Footer/Footer";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class UniversesDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: (this.props.datas.blog?.offers)?this.props.datas.blog.offers:[],
            dispositifs: (this.props.datas.blog?.dispositifs)?this.props.datas.blog.dispositifs:[],
        }

        let brands = (this.props.datas?.brands?.length > 0)?this.props.datas.brands:[];
        this.relatedBrands = [];
        brands.map((brand, indbrand) => {
            let myBrands = brand.identity.universes;
            if (myBrands.find(item => item._id === this.props.contentData._id)) {
                this.relatedBrands.push(brand);
            }
        });


    }

    componentDidMount() {
        gsap.registerPlugin(MorphSVGPlugin);
        gsap.registerPlugin(DrawSVGPlugin);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevProps.datas.blog?.offers !== this.props.datas.blog?.offers &&  this.props.datas.blog?.offers.length > 0 ) {
            this.setState({offers: this.props.datas.blog.offers});
        }
        if (prevProps.datas.blog?.dispositifs !== this.props.datas.blog?.dispositifs && this.props.datas.blog?.dispositifs.length > 0) {
            this.setState({dispositifs: this.props.datas.blog?.dispositifs});
        }
    }

    getRelatedOffers(source, currentSubject) {
        
        let offers = source;
        let tags = (this.props.datas.blog?.tags)?this.props.datas.blog?.tags:[];
        let relatedOffers = [];
        offers.map((offer) => {
            let offerTags = [];
            offer.meta.tag_ids.map((tagId) => {
                let _tags = tags.filter((tagItem) => {
                    return parseInt(tagItem.id) === tagId;
                });
                _tags.map((_tag) => {
                    offerTags.push(_tag);
                });
            });
            offerTags.map((offerTagItem) => {
                if (offerTagItem.name.toLowerCase() === currentSubject.toLowerCase()) {
                    relatedOffers.push(offer);
                }
            });
        });
        return relatedOffers;
    }

    renderMetas() {

        let title = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].title !== "") ? this.props.contentData.meta[`${this.props.lngDatas.name.data}`].title : (this.props.lngDatas.brands.meta.title !== "") ? this.props.lngDatas.brands.meta.title : (this.props.lngDatas.generic.meta.title !== "") ? this.props.lngDatas.generic.meta.title : "";
        let description = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].description !== "") ? this.props.contentData.meta[`${this.props.lngDatas.name.data}`].description : (this.props.lngDatas.brands.meta.description !== "") ? this.props.lngDatas.brands.meta.description : (this.props.lngDatas.generic.meta.description !== "") ? this.props.lngDatas.generic.meta.description : "";
        //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.brands.meta.keywords !=="")? this.props.lngDatas.brands.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
        let slug = this.props.contentData.meta[`${this.props.lngDatas.name.data}`].slug;
        return (
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={slug}/>
            </Helmet>
        )
    }

    renderBrands(version) {

        let files = this.props.datas.uploads;
        let lngDatas = this.props.lngDatas;
        if (this.relatedBrands && this.relatedBrands.length > 0) {

            let sorted = this.relatedBrands.sort((a, b) => a.order[`${lngDatas.name.data}`] - b.order[`${lngDatas.name.data}`]);

            return sorted.map((item, index) => {
                let imgSrc = "";
                if (item.identity.logoSVG && item.identity.logoSVG._id) {
                    let img = item.identity.logoSVG._id;
                    let file = findNestedObj(files, '_id', img);
                    if (file) {
                        return (
                            <li className={'entity-item 8%'} key={`entity-item-${index}`}>
                                <MediaComponent
                                    media={file}
                                    version={version}
                                />
                            </li>
                        )
                    }
                }
            });
        } else {
            return (
                <div className="label"></div>
            )
        }
    }

    renderKeyFigures() {
        let items = this.props.contentData.keyFigures[`${this.props.lngDatas.name.data}`];

        return items.map((item, index) => {

            return (
                <div className="col-12 col-md-6 item" key={`keyfigures-item-${index}`}>
                    <div className="text">
            <span className="d-block figure">
              {item.figure}
            </span>
                        <span className="d-block label">
              {item.label}
            </span>
                        <span className="d-block description">
              {item.description}
            </span>
                        <span className="d-block source">
              {item.source}
            </span>
                    </div>
                </div>
            );
        });
    }

    renderHeaderNav() {
        const items = this.props.datas.universes;
        const itemIndex = items.findIndex(item => item._id === this.props.contentData._id);
        const hasPrev = (itemIndex > 0);
        const hasNext = (itemIndex < items.length - 1);
        const prevItem = (hasPrev) ? items[itemIndex - 1] : items[items.length - 1];
        const nextItem = (hasNext) ? items[itemIndex + 1] : items[0];

        return (
            <div className="row position-absolute p-0 justify-content-between navigation">
                <div className="col-6 p-0 text-left">
                    <Link className="cta hiddenCursor previous"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug + "/" + prevItem.meta[`${this.props.lngDatas.name.data}`].slug}>
                        <ReactSVG
                            className="arrow"
                            src="/ui/ctas/arrow.svg"
                            renumerateIRIElements={false}
                        />
                    </Link>
                </div>
                <div className="col-6 p-0 text-right">
                    <Link className="cta hiddenCursor next"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug + "/" + nextItem.meta[`${this.props.lngDatas.name.data}`].slug}>
                        <ReactSVG
                            className="arrow"
                            src="/ui/ctas/arrow_2.svg"
                            renumerateIRIElements={false}
                        />
                    </Link>
                </div>
            </div>
        )
    }

    iniFooterNavCTAs(svg) {
        new CircleCtaAnim(svg);
    }

    renderFooterNav() {
        const items = this.props.datas.universes;
        const itemIndex = items.findIndex(item => item._id === this.props.contentData._id);
        const hasPrev = (itemIndex > 0);
        const hasNext = (itemIndex < items.length - 1);
        const prevItem = (hasPrev) ? items[itemIndex - 1] : items[items.length - 1];
        const nextItem = (hasNext) ? items[itemIndex + 1] : items[0];

        return (
            <ul className="row w-100 justify-content-center navigation">
                <li className="col-12 col-md-6 align-self-start previous">
                    <Link className="cta hiddenCursor"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug + "/" + prevItem.meta[`${this.props.lngDatas.name.data}`].slug}>
                        <span className="text">
                            <span className="label">
                                {this.props.lngDatas.generic.lab_cta_prev}
                            </span>
                            <span className="name">
                                {prevItem.identity[`${this.props.lngDatas.name.data}`].name}
                            </span>
                        </span>
                        <div className="circle">
                            <ReactSVG
                                className="circlesvg"
                                src="/ui/ctas/a_circle_full.svg"
                                renumerateIRIElements={false}
                                afterInjection={(error, svg) => {
                                    if (error) {
                                        console.error(error)
                                        return
                                    } else {
                                        this.iniFooterNavCTAs(svg)
                                    }
                                }}
                            />
                        </div>
                    </Link>
                </li>
                <li className="col-12 col-md-6 align-self-end next">
                    <Link className="cta hiddenCursor"
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug + "/" + nextItem.meta[`${this.props.lngDatas.name.data}`].slug}>
                        <div className="circle">
                            <ReactSVG
                                className="circlesvg"
                                src="/ui/ctas/a_circle_full.svg"
                                renumerateIRIElements={false}
                                afterInjection={(error, svg) => {
                                    if (error) {
                                        console.error(error)
                                        return
                                    } else {
                                        this.iniFooterNavCTAs(svg)
                                    }
                                }}
                            />
                        </div>
                        <span className="text">
                            <span className="label">
                                {this.props.lngDatas.generic.lab_cta_next}
                            </span>
                            <span className="name">
                                {nextItem.identity[`${this.props.lngDatas.name.data}`].name}
                            </span>
                        </span>
                    </Link>
                </li>
            </ul>
        )
    }

    renderDispositifs(source){
        let items = this.getRelatedOffers(this.state.dispositifs, source);
        console.log('items', items);
        if(items && items.length > 0){
            return(
            <div className={'latest__devices'}>
                <ArticlesList
                    articles={items}
                    contentType={'blog'}
                    datas={this.props.datas}
                    files={this.props.datas.uploads}
                    lngDatas={this.props.lngDatas}
                    presentation={'slider'}
                    sliderControls={true}
                    title={'Nos derniers dispositifs'}
                />
            </div>
            )
        }
    }

    renderOffresSaisonnieres(source){
        let items = this.getRelatedOffers(this.state.offers, source);
        if(items && items.length > 0){
            return(
            <div className={'seasonal__offer'}>
                <ArticlesList
                    articles={items}
                    contentType={'seasonals'}
                    datas={this.props.datas}
                    files={this.props.datas.uploads}
                    filtre={false}
                    lngDatas={this.props.lngDatas}
                    presentation={'list'}
                    sideContent={'description'}
                    sliderControls={true}
                    title={'Nos offres saisonnières'}
                ><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua.</p>
                </ArticlesList>
            </div>
            )
        }
    }

    render() {
        return (
            <div className="col-12 page universes-detail">
                <PageHero
                    lngDatas={this.props.lngDatas}
                    datas={this.props.datas}
                    identity={{cover:this.props.contentData.heroVisual}}
                    surtitle={{
                        type: 'text',
                        content: this.props.contentData.identity[this.props.lngDatas.name.data].name,
                    }}
                    title={{
                        type: 'text',
                        content: this.props.contentData.identity[this.props.lngDatas.name.data].introduction.catch
                    }}
                    media={{
                        type: 'image',
                        content: this.props.contentData.visual,
                    }}
                    subtitle={false}
                    bottom={true}
                    module={'brand'}
                />

                <section className={'univers__header'}>

                    <div className={'detail__close'}>
                        <Link to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.universes.meta.slug}/`}>
                        <button className={'close__page'}>
                            <ReactSVG
                                src={'/ui/icons/close.svg'}
                                className={'close__page__item'}
                            />
                        </button>
                        </Link>
                    </div>

                    <section className={'section content content__step introduction'}>
                        <p className={'section__title'}>{this.props.lngDatas.universes.steps.lab_step0}</p>
                        <p className={'step__content'}
                           dangerouslySetInnerHTML={{__html: this.props.contentData.identity[this.props.lngDatas.name.data].introduction.text}}></p>
                    </section>

                    <section className={'section content content__keydata univers-keyfigures'}>
                        <ul className={'keydatas'}>
                            {this.props.contentData.keyFigures[this.props.lngDatas.name.data].map((item, itemI) => {
                                return (
                                    <li className={'keydata'} key={`keydata-${itemI}`}>
                                        <p className={'keydata__title'}>{item.figure}</p>
                                        <p className={'keydata__subtitle'}>{item.label}</p>
                                        <p className={'keydata__light'}>{item.description}</p>
                                        <p className={'keydata__xs'}>{item.source}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>

                </section>

                <section className={'section content content__step presentation univers-entites-title'}>
                    <p className={'section__title'}>{this.props.lngDatas.universes.steps.lab_step2}</p>
                    <p className={'step__content'}>{this.props.lngDatas.universes.brands.part1} {this.props.contentData.identity[this.props.lngDatas.name.data].name} {this.props.lngDatas.universes.brands.part2}</p>
                </section>

                <section className={'section content content__step presentation univers-entites-list'}>
                    {this.renderBrands('original')}
                </section>

                <section className={'section content content__step presentation '}>
                    <p className={'section__title'}>{this.props.lngDatas.universes.steps.details} {this.props.contentData.identity[this.props.lngDatas.name.data].name}</p>
                    <p className={'step__content'}>{this.props.lngDatas.universes.steps.exp_intro} {this.props.contentData.identity[this.props.lngDatas.name.data].name}</p>
                    {(this.props.contentData?.contacts && this.props.contentData?.contacts.length > 0) &&
                    <ul className={'details-contact'}>
                        {this.props.contentData.contacts.map((item, key) => {
                            return (
                                <li key={key} className={'contact-item'}>
                                    <p className={"contact-position"}>{item.position}</p>
                                    <p className={"contact-name"}>{item.name}</p>
                                    <p className={"contact-email"}>{item.adress}</p>
                                </li>
                            )
                        })}
                    </ul>
                    }
                </section>

                {
                    (this.props.contentData?.expertises && this.props.contentData.expertises.length>0) &&
                    <section className={'content_step section content layout univers-details-l'}>
                        <ul className={'details-expertises'}>
                            { this.props.contentData.expertises.map((item,key)=>{
                                let description = false;
                                let title = false;
                                let expertise = false;
                                let slug__base = this.props.lngDatas.expertises.meta.slug;
                                let slug__expertise = "";
                                expertise = findNestedObj(this.props.datas.expertises, '_id', item.expertise);
                                if ( expertise ) {
                                    title = expertise.identity[this.props.lngDatas.name.data].title;
                                    if ( expertise.meta && expertise.meta[this.props.lngDatas.name.data] &&  expertise.meta[this.props.lngDatas.name.data].slug ) {
                                        slug__expertise = expertise.meta[this.props.lngDatas.name.data].slug;
                                    }
                                    if ( item.description[this.props.lngDatas.name.data] && item.description[this.props.lngDatas.name.data].length > 0 ) {
                                        description = item.description[this.props.lngDatas.name.data];
                                    } else {
                                        description = expertise.identity[this.props.lngDatas.name.data].catch
                                    }


                                    return (
                                        <li key={key} className={'expertise-item'}>
                                            <p className={'expertise-name'}>{title}</p>
                                            <p className={'expertise-description'}>{description}</p>
                                            <NavLink className={'expertise__link'} to={`/${this.props.lngDatas.name.data}/${slug__base}/${slug__expertise}`}><p>{this.props.lngDatas.generic.lab_cta_readmore}</p></NavLink>
                                        </li>
                                    )
                                }

                            })}
                        </ul>
                    </section>
                }

                {/* this.renderDispositifs(this.props.contentData.identity[this.props.lngDatas.name.data].name) */}

                {/* this.renderOffresSaisonnieres(this.props.contentData.identity[this.props.lngDatas.name.data].name) */}


                <Footer
                    datas={this.props.datas}
                    didomiObject={this.props.didomiObject}
                    lngDatas={this.props.lngDatas}
                    footerMenuLinks={this.props.footerMenuLinks}
                    footerMenuCopy={this.props.footerMenuCopy}
                    locationPath={this.props.locationPath}
                    params={this.props.paramsLocation}
                    appControls={this.props.appControls}
                    custom={
                        {
                            next: {
                                title: this.props.contentData.index != this.props.datas.universes.length-1 ? this.props.datas.universes[this.props.contentData.index+1].identity[this.props.lngDatas.name.data].name : this.props.datas.universes[0].identity[this.props.lngDatas.name.data].name,
                                path:  this.props.contentData.index != this.props.datas.universes.length-1 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.universes.meta.slug}/${this.props.datas.universes[this.props.contentData.index+1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.universes.meta.slug}/${this.props.datas.universes[0].meta[this.props.lngDatas.name.data].slug}` ,
                            },
                            prev: {
                                title: this.props.contentData.index > 0 ? this.props.datas.universes[this.props.contentData.index-1].identity[this.props.lngDatas.name.data].name : this.props.datas.universes[this.props.datas.universes.length-1].identity[this.props.lngDatas.name.data].name,
                                path:  this.props.contentData.index > 0 ? `/${this.props.lngDatas.name.data}/${this.props.lngDatas.universes.meta.slug}/${this.props.datas.universes[this.props.contentData.index-1].meta[this.props.lngDatas.name.data].slug}` : `/${this.props.lngDatas.name.data}/${this.props.lngDatas.universes.meta.slug}/${this.props.datas.universes[this.props.datas.universes.length-1].meta[this.props.lngDatas.name.data].slug}` ,
                            }
                        }
                    }
                />


            </div>
        )
    }
}
