export default class SliderCursor {
    constructor(slider) {
        this.element = slider;
        this.cursor = document.querySelector('.cursor');

        this.element.addEventListener('mouseenter', e => {
            if ( !this.cursor.classList.contains('slider') ) {
                this.cursor.classList.add('slider');
            }
        });

        this.element.addEventListener('mouseleave', e => {
            if ( this.cursor.classList.contains('slider') ) {
                this.cursor.classList.remove('slider');
            }
        });
    }


    // quand curseur sur slider on retire la le petit rond dans le curseur
    // puis on fait apparaître une flèche orientée en fonction de où on se trouve dans le slider
}