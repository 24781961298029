import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import { ReactSVG } from "react-svg";
import MediaComponent from "../partials/Media";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class FilterSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selected: false,
            mounted: false,
            filters: [...this.props.filters]
        }
        this.state.filters.splice(0, 0, {
            name:{
                "fr":"Tous",
                "en":"All"
            },
            _id: 1,
        });


    }

    componentDidMount() {
        this.setState({selected:this.state.filters[0]});
        this.setState({mounted: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevState.selected !== this.state.selected && this.state.mounted ) {
            this.setState({open: false});
            if ( this.props.update ) {
                this.props.update(this.state.selected);
            }
        }
    }


    render() {
        return (
            <div className={`filter__select ${this.state.open ? 'opened':'closed'}`}>
                <div className={'filter__action'}>
                    { this.state.selected &&
                        <p className={'current'} onClick={()=>{this.setState({open: !this.state.open})}}>
                            <span>{this.state.selected.name[`${this.props.lngDatas.name.data}`]}</span>
                        </p>
                    }
                    <button className={'toggle'} onClick={()=>{this.setState({open: !this.state.open})}}>
                        <ReactSVG
                            className={'arrow'}
                            src={'/ui/icons/arrowdown.svg'}
                        />
                    </button>
                </div>
                <ul className={'filters_list'}>
                    {this.state.filters.map((item,index)=>{

                        return (
                            <li 
                                className={`${this.state.selected._id === item._id ? 'hidden':''}`} 
                                key={`filter-select-${index}`}
                                onClick={()=>{this.setState({selected: item})}}
                            >
                                {item.name[`${this.props.lngDatas.name.data}`]}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}