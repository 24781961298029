import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";


export default class Tos extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

  }

  componentDidMount() {
    let nav = document.querySelector('.navigation__container ');
    if ( nav ) {
      nav.style.display = "none";
    }
  }


  findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
  }

  openTos(){
    let tosId = this.props.lngDatas.generic.footer.file_tos._id;
    let tosFile = this.findNestedObj(this.props.datas.uploads, '_id', tosId);
    let tosSrc = (tosFile)?tosFile.staticPath:'';
    document.location.replace(tosSrc);
  }


  render() {
    return (
      <>
      {this.openTos()}
      </>
    )
  }
}
