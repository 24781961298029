import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";


export default class Legals extends Component {
  constructor(props) {
    super(props);


  }

    componentDidMount() {
        let nav = document.querySelector('.navigation__container ');
        if ( nav ) {
            nav.style.display = "none";
        }
    }


  renderMetas(){

    let title = (this.props.lngDatas.generic.legals.meta.title !=="")? this.props.lngDatas.generic.legals.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
    let description = (this.props.lngDatas.generic.legals.meta.description !=="")? this.props.lngDatas.generic.legals.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
    //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.expertises.meta.keywords !=="")? this.props.lngDatas.expertises.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
    let slug = this.props.lngDatas.expertises.meta.slug;
    return(
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
      </Helmet>
    )
  }

  render() {
    return (
      <section className="col-12 page common">
        <div className="col-12 mx-auto p-0 block">
          {this.renderMetas()}        
          <div className="header">
            <h1 className="title">
              <span>
                {this.props.lngDatas.generic.footer.lab_link_legals}
              </span>
            </h1>
            <h1 className="subtitle">
              <span>
                {this.props.lngDatas.generic.legals.subtitle}
              </span>
            </h1>
          </div>
          <div className="row editorial justify-content-start">
            <div className="col-12 col-lg-8 p-0">
                <div dangerouslySetInnerHTML={{__html:this.props.lngDatas.generic.legals.text}}></div>
            </div>

          </div>
        </div>
      </section>
    )
  }
}
