import ScrollReveal from 'scrollreveal'

export default class IsVisible {

    constructor(elements,options = {origin: 'bottom',distance: '30px'}) {
        this.elements = elements;
        this.options = {
            effects: false,
        };
        this.sr = new ScrollReveal();
        this.checkIfVisible();
    }

    checkIfVisible() {
        const elems = this.elements;
        elems.forEach( (elem)=>{
            this.sr.reveal(elem, {
                afterReveal: function (elem) {
                    elem.classList.add("visible");
                }
            });
        });
    }

}