import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import { ReactSVG } from "react-svg";
import MediaComponent from "../partials/Media";
import Glide from '@glidejs/glide'
import GlideCarousel from "../../assets/scripts/GlideCarousel";
import ButtonCircle from "../partials/buttonCircle";

function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}

export default class ArticlesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: false,
            pagination: false,
            articles: this.props.articles,
            filteredArticles: [],
            paginatedArticles: [],
        };
        if (this.props.pagination) {
            this.state.pagination = {...this.props.pagination};
            this.state.pagination.index = 1;
        }

    }

    componentDidMount() {
        let carousel = document.querySelector('#expertisesList');
        if (this.props.presentation && this.props.presentation === 'slider' && carousel) {
            this.carouselControleur = new Glide('#expertisesList', {
                type: 'carousel',
                perView: 3,
                focusAt: 'center',
                breakpoints: {
                    1024: {
                        focusAt: 0,
                        perView: 1,
                    },
                }
            }).mount();
        }
        this.filterArticles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.presentation && this.props.presentation === 'slider' && this.carouselControleur) {
            this.carouselControleur.update();
        }

        if (prevState.filter !== this.state.filter) {
            this.filterArticles();
        }

        if (prevProps.articles !== this.props.articles && this.props.articles.length > 0) {
            this.filterArticles();
        }
    }

    _initGlide(){
          
    }

    handlePagination(dir) {
        let _pagination = {...this.state.pagination};
        if (_pagination.index === this.state.filteredArticles.length) {
            return false;
        } else {
            _pagination.index += 1;
            this.setState({pagination: _pagination});
        }
    }

    renderArticles() {
        if (this.props.presentation && this.props.presentation === 'slider') {
            if (this.props.contentType === 'seasonals') {
                return this.renderSliderSeasonals('thumbnail');
            } else if (this.props.contentType === 'blog') {
                return this.renderSliderBlog('thumbnail');
            } else {
                return this.renderSliderContent('thumbnail');
            }
        } else if (this.props.presentation && this.props.presentation === 'list') {
            if (this.props.contentType === 'seasonals') {
                return this.renderListSeasonals('thumbnail');
            } else if (this.props.contentType === 'blog') {
                return this.renderListBlog('thumbnail');
            } else {
                return this.renderListContent('large');
            }
        } else {
            return this.renderListContent('large');
        }
    }

    updateFilter(item) {
        this.setState({filter: item});
        if (this.state.pagination) {
            let _pag = this.state.pagination;
            _pag.index = 1;
            this.setState({pagination: _pag});
        }

    }

    handleControls(dir) {
        this.carouselControleur.go(dir);
    }

    renderFilter() {
        const childrenWithProps = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {update: this.updateFilter.bind(this)});
            }
            return child;
        });

        return <div>{childrenWithProps}</div>;
    }

    renderSliderBlog(version) {

        // let articles = this.state.filteredArticles;
        // if (this.state.pagination) {
        //     articles = articles.slice(0, (this.state.pagination.index * this.state.pagination.increase));
        // }
        return (
            <div className="glide slider-articles" id={'expertisesList'}>
                <div className="glide__track " data-glide-el="track">
                    <ul className="glide__slides articles-slider">
                {this.props.articles.map((item, key) => {
                    if (item.meta?.featured_image) {
                        item.image = item.meta.featured_image;
                    } else if (item.image) {
                        item.image = item.image;
                    } else if (item.featured_image) {
                        item.image = item.featured_image;
                    } else if (item.screenshot_preview_url) {
                        item.image = item.screenshot_preview_url;
                    }

                    console.log('renderListBlog item', item);
                    let tagId = false;
                    let topicsId = false;

                    if (item?.tag_ids?.length > 0 && this.props.datas.blog.tags?.length > 0) {
                        tagId = this.props.datas.blog.tags.find(tag => {
                            return parseInt(tag.id) === item.tag_ids[0]
                        });

                        if (tagId) {
                            item.tag = tagId;
                        }
                    }

                    if (item?.topic_ids?.length > 0 && this.props.datas.blog.topics?.length > 0) {
                        topicsId = this.props.datas.blog.topics.find(topic => {
                            return topic.id === item.topic_ids[0];
                        });

                        if (topicsId) {
                            item.topic = topicsId;
                        }
                    }

                    item.time = Math.floor(Math.random() * (8 - 2 + 1) + 2);

                    if (item.title) {
                        item.name = item.title;
                    }

                    if (item.meta_description) {
                        item.description = item.meta_description;
                    }

                    return (
                        <li className="glide__slide item-slide" key={`expertises-item-${key}`}>  
                            <a href={item.url} target={'_blank'}
                               className={`article ${this.props.contentType}-article`}
                               key={"blog-mainlist-article-" + key}>

                                <div className={'visual'}>
                                    {(item.tag && item.tag.name) &&
                                    <p className={"article__categ"}>{item.tag.name}</p>
                                    }
                                    <MediaComponent
                                        media={{
                                            isUrl: item.image,
                                            path: item.image || false,
                                        }}
                                        version={version}
                                    />
                                </div>


                                {item.time2 &&
                                <p className={'article__time'}>
                                    {this.props.lngDatas.blog.labels.lecture} <span>{item.time} min</span>
                                </p>
                                }

                                {item.title &&
                                <p className={'article__title'}>
                                    {item.title}
                                </p>
                                }

                                {item.description &&
                                <p className={'article__description'}>
                                    {(item.description.length > 100) ? item.description.substr(0, 100 - 1) + '...' : item.description}
                                </p>
                                }

                                {item.form &&
                                <div className={'form__blog'}>
                                    {item.form.map((item, index) => {
                                        return (
                                            <input key={'blog-news-input-' + index} type={item.type}
                                                   placeholder={item.placeholder} name={item.name}
                                                   className={`form__input ${item.name}`}/>
                                        )
                                    })}
                                </div>
                                }

                                {item.button &&
                                <ButtonCircle
                                    action={item.button.action}
                                    target={false}
                                />
                                }

                                {item.url &&
                                <div className={'article__link'}>
                                    <span>Lire l'article</span>
                                </div>
                                }


                            </a>
                        </li>
                    )

                })}
                    </ul>
                </div>
            </div>
        )


        return (
                <div className="glide slider-articles" id={'expertisesList'}>
                    <div className="glide__track " data-glide-el="track">
                        <ul className="glide__slides articles-slider">
                            {this.props.articles.filter((item) => {
                                if (this.state.filter && this.state.filter._id !== 1) {
                                    let res = false;
                                    item.newstype.map((nt) => {
                                        if (nt._id === this.state.filter._id) {
                                            res = true;
                                        }
                                    });
                                    return res;
                                } else {
                                    return true;
                                }
                            }).map((item, index) => {
                                let media = false;
                                if (item.visual && item.visual._id) {
                                    let img = item.visual._id;
                                    let file = findNestedObj(this.props.files, '_id', img);
                                    if (file) {
                                        media = file;
                                    }
                                }
                                return (
                                    <div className="glide__slide item-slide" key={`expertises-item-${index}`}>

                                        <div className={'article'}>
                                            {media &&
                                            <div className={'visual'}>
                                                <MediaComponent
                                                    media={media}
                                                    version={version}
                                                />
                                            </div>
                                            }
                                            <p className={'article__title'}>
                                                Brand
                                            </p>
                                            <p className={'article__description'}>{item.title[`${this.props.lngDatas.name.data}`]}</p>
                                            <ButtonCircle
                                                id={`brand-news-item-button-${index}`}
                                                target={{
                                                    path: `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${item.meta[this.props.lngDatas.name.data].slug}`,
                                                    name: this.props.lngDatas.generic.lab_cta_readmore,
                                                }}

                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </ul>
                    </div>
                </div>
        );
    }

    renderSliderContent(version) {
        return (
                <div className="glide slider-articles" id={'expertisesList'}>
                    <div className="glide__track " data-glide-el="track">
                        <ul className="glide__slides articles-slider">
                            {this.props.articles.filter((item) => {
                                if (this.state.filter && this.state.filter._id !== 1) {
                                    let res = false;
                                    item.newstype.map((nt) => {
                                        if (nt._id === this.state.filter._id) {
                                            res = true;
                                        }
                                    });
                                    return res;
                                } else {
                                    return true;
                                }
                            }).map((item, index) => {
                                let media = false;
                                if (item.visual && item.visual._id) {
                                    let img = item.visual._id;
                                    let file = findNestedObj(this.props.files, '_id', img);
                                    if (file) {
                                        media = file;
                                    }
                                }
                                return (
                                    <div className="glide__slide item-slide" key={`expertises-item-${index}`}>

                                        <div className={'article'}>
                                            {media &&
                                            <div className={'visual'}>
                                                <MediaComponent
                                                    media={media}
                                                    version={version}
                                                />
                                            </div>
                                            }
                                            <p className={'article__title'}>
                                                Brand
                                            </p>
                                            <p className={'article__description'}>{item.title[`${this.props.lngDatas.name.data}`]}</p>
                                            <ButtonCircle
                                                id={`brand-news-item-button-${index}`}
                                                target={{
                                                    path: `/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${item.meta[this.props.lngDatas.name.data].slug}`,
                                                    name: this.props.lngDatas.generic.lab_cta_readmore,
                                                }}

                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </ul>
                    </div>
                </div>
        );
    }

    renderSliderControl() {
        return (
            <ul className={'slider__controls'}>
                <li className={'control control__prev'}></li>
                <li className={'control control__next'}></li>
            </ul>
        )
    }

    renderListSeasonals(version) {
        return (
            <div className={'articles seasonals'}>
                {this.props.articles.slice(0, this.props.articles.length > 3 ? 3 : this.props.articles.length).map((item, key) => {
                    let media = false;

                    console.log('renderListSeasonals item', item);
                    if (item.visual && item.visual._id) {
                        let img = item.visual._id;
                        let file = findNestedObj(this.props.files, '_id', img);
                        if (file) {
                            media = file;
                        }
                    }

                    if (item.meta?.featured_image) {
                        item.image = item.meta.featured_image;
                    } else if (item.image) {
                        item.image = item.image;
                    } else if (item.featured_image) {
                        item.image = item.featured_image;
                    } else if (item.screenshot_preview_url) {
                        item.image = item.screenshot_preview_url;
                    }

                    return (
                        <div className={'article seasonal'} key={`article-seasonal-${key}`}>
                            {item.image &&
                            <div className={'visual'}>
                                <MediaComponent
                                    media={{
                                        isUrl: item.image,
                                        path: item.image || false,
                                    }}
                                    version={version}
                                />
                            </div>
                            }
                            <p className={'article__brand'}>
                                {item.brand}
                            </p>
                            <p className={'article__title'}>{item.title}</p>
                            <div className={'article__contact'}>
                                <p className={'contact__name'}>Contact name</p>
                                <p className={'contact__position'}>Contact position</p>
                                <p className={'contact__email'}>contact@article.fr</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderListBlog(version) {
        let articles = this.state.filteredArticles;
        if (this.state.pagination) {
            articles = articles.slice(0, (this.state.pagination.index * this.state.pagination.increase));
        }
        return (
            <div className={'articles blog'}>
                {articles.map((item, key) => {
                    if (item.meta?.featured_image) {
                        item.image = item.meta.featured_image;
                    } else if (item.image) {
                        item.image = item.image;
                    } else if (item.featured_image) {
                        item.image = item.featured_image;
                    } else if (item.screenshot_preview_url) {
                        item.image = item.screenshot_preview_url;
                    }

                    console.log('renderListBlog item', item);
                    let tagId = false;
                    let topicsId = false;

                    if (item?.tag_ids?.length > 0 && this.props.datas.blog.tags?.length > 0) {
                        tagId = this.props.datas.blog.tags.find(tag => {
                            return parseInt(tag.id) === item.tag_ids[0]
                        });

                        if (tagId) {
                            item.tag = tagId;
                        }
                    }

                    if (item?.topic_ids?.length > 0 && this.props.datas.blog.topics?.length > 0) {
                        topicsId = this.props.datas.blog.topics.find(topic => {
                            return topic.id === item.topic_ids[0];
                        });

                        if (topicsId) {
                            item.topic = topicsId;
                        }
                    }

                    item.time = Math.floor(Math.random() * (8 - 2 + 1) + 2);

                    if (item.title) {
                        item.name = item.title;
                    }

                    if (item.meta_description) {
                        item.description = item.meta_description;
                    }

                    return (
                        <a href={item.url} target={'_blank'}
                           className={`article ${this.props.contentType}-article`}
                           key={"blog-mainlist-article-" + key}>

                            <div className={'visual'}>
                                {(item.tag && item.tag.name) &&
                                <p className={"article__categ"}>{item.tag.name}</p>
                                }
                                <MediaComponent
                                    media={{
                                        isUrl: item.image,
                                        path: item.image || false,
                                    }}
                                    version={version}
                                />
                            </div>


                            {item.time2 &&
                            <p className={'article__time'}>
                                {this.props.lngDatas.blog.labels.lecture} <span>{item.time} min</span>
                            </p>
                            }

                            {item.title &&
                            <p className={'article__title'}>
                                {item.title}
                            </p>
                            }

                            {item.description &&
                            <p className={'article__description'}>
                                {(item.description.length > 100) ? item.description.substr(0, 100 - 1) + '...' : item.description}
                            </p>
                            }

                            {item.form &&
                            <div className={'form__blog'}>
                                {item.form.map((item, index) => {
                                    return (
                                        <input key={'blog-news-input-' + index} type={item.type}
                                               placeholder={item.placeholder} name={item.name}
                                               className={`form__input ${item.name}`}/>
                                    )
                                })}
                            </div>
                            }

                            {item.button &&
                            <ButtonCircle
                                action={item.button.action}
                                target={false}
                            />
                            }

                            {item.url &&
                            <a href={item.url} className={'article__link'}>
                                <span>Lire l'article</span>
                            </a>
                            }


                        </a>
                    )

                })}
            </div>
        )
    }

    filterArticles() {
        let filteredArticles = [];

        if (this.state.filter) {
            if (this.props.contentType === "blog") {
                if (this.state.filter != "all") {
                    filteredArticles = this.props.articles.filter((item) => {
                        let tagId = false;
                        if (item?.tag_ids?.length > 0 && this.props.datas.blog.tags?.length > 0) {
                            tagId = this.props.datas.blog.tags.find(tag => {
                                return parseInt(tag.id) === item.tag_ids[0]
                            });
                            if (tagId) {
                                if (tagId.name === this.state.filter) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        } else {
                            return false
                        }
                    });
                } else {
                    filteredArticles = this.props.articles;
                }
            } else if (this.props.contentType === "news") {
                if (this.state.filter._id && this.state.filter._id != 1) {
                    filteredArticles = this.props.articles.filter((item) => {
                        if (item.newstype && item.newstype.length > 0) {
                            let res = false;
                            item.newstype.map((nt) => {
                                if (nt._id === this.state.filter._id) {
                                    res = true;
                                }
                            });
                            return res;
                        } else {
                            return false;
                        }
                    });
                } else {
                    filteredArticles = this.props.articles;
                }
            }
        } else {
            filteredArticles = this.props.articles;
        }

        this.setState({filteredArticles: filteredArticles});
    }

    paginateArticles(list) {
        if (this.state.pagination) {
            let max = this.state.pagination.increase * this.state.pagination.index;
            return list.slice(0, max);
        } else {
            return list;
        }

    }

    renderListContent(version) {
        let articles = this.state.filteredArticles;
        if (this.state.pagination && articles) {
            articles = articles.slice(0, (this.state.pagination.index * this.state.pagination.increase));
        }
        return (
            <div className={'articles list__carticles'}>
                {articles.map((item, index) => {
                    let media = false;
                    let type = false;

                    console.log('renderListContent item', item);

                    if (this.props.newstypes && item.newstype && item.newstype[0] && item.newstype[0]._id) {
                        type = findNestedObj(this.props.newstypes, "_id", item.newstype[0]._id);
                    }
                    if (item.visual && item.visual._id) {
                        let img = item.visual._id;
                        let file = findNestedObj(this.props.files, '_id', img);
                        if (file) {
                            media = file;
                        }
                    }
                    return (
                        <Link 
                            key={`article-item-${index}`}
                            to={`/${this.props.lngDatas.name.data}/${this.props.lngDatas.news.meta.slug}/${item.meta[this.props.lngDatas.name.data].slug}`}
                        >
                            <div className={'article'}>
                                {media &&
                                <div className={'visual'}>
                                    <MediaComponent
                                        media={media}
                                        version={version}
                                    />
                                </div>
                                }
                                {
                                    type &&
                                    <p className={'article__title'}>

                                        {type.name[this.props.lngDatas.name.data]}
                                    </p>
                                }
                                <p className={'article__description'}>{item.title[`${this.props.lngDatas.name.data}`]}</p>
                            </div>
                        </Link>
                    )
                })}
            </div>
        )
    }


    render() {
        return (
            <>
            {this.props.articles 
                && this.props.articles.length > 0 && 
            <section className={'articlesList'}>

                <div className={`header__articles ${this.props.sideContent ? this.props.sideContent : ''}`}>
                    <p className={'section__title'}>{this.props.title}</p>

                    {(this.props.sideContent && this.props.sideContent === 'filtre') &&
                    <div className={'list__filters'}>
                        {this.renderFilter()}
                    </div>
                    }

                    {(this.props.sideContent && this.props.sideContent === 'description') &&
                    <div className={'list__description'}>
                        {this.props.children}
                    </div>
                    }

                    {(this.props.presentation === 'slider' && this.props.sliderControls) &&
                    <ul className={'slider__controls'}>
                        <li className={'control control__prev'}>
                            <button className={'control__item'} onClick={() => {
                                this.handleControls('<');
                            }}>
                                <ReactSVG
                                    className="arrow"
                                    src="/ui/icons/arrow-slider.svg"
                                />
                            </button>
                        </li>
                        <li className={'control control__next'}>
                            <button className={'control__item'} onClick={() => {
                                this.handleControls('>');
                            }}>
                                <ReactSVG
                                    className="arrow"
                                    src="/ui/icons/arrow-slider.svg"
                                />
                            </button>
                        </li>
                    </ul>
                    }
                </div>

                {this.renderArticles()}

                {(this.props.pagination && (this.state.pagination.index * this.state.pagination.increase) < this.state.filteredArticles.length) &&
                <div className={"pagination"}>
                    <ButtonCircle
                        target={false}
                        action={{
                            name: `voir ${((this.state.pagination.index * this.state.pagination.increase) + this.state.pagination.increase) > this.state.filteredArticles.length ? this.state.filteredArticles.length : (this.state.pagination.index * this.state.pagination.increase) + this.state.pagination.increase} sur ${this.state.filteredArticles.length}`,
                            ref: this.handlePagination.bind(this, '+'),
                        }

                        }
                    />
                </div>
                }
            </section>
            }
            </>
        )
    }
}