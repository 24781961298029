import React from 'react';
import { hydrate, render } from 'react-dom';

import './assets/scss/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import publish from './assets/datas/publish';

let dlftLng = {};

publish.languages.map((item, index)=>{
  if(item.edit.isDefault){
    dlftLng = {
    	_id : item._id,
    	name : {
    		rich : item.name.rich,
    		short : item.name.short,
    		data : item.name.data
    	}
    }
  }
});

          
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(<App dfltLng={dlftLng} {...publish} />, rootElement);
} else {
    render(<App dfltLng={dlftLng} {...publish} />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
