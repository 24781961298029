import React, { Component } from "react";
import ButtonCircle from "../../partials/buttonCircle";
import MediaComponent from "../../partials/Media";


function findNestedObj(entireObj, keyToFind, valToFind) {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
}



export default class MixedContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: false,
        }
    }

    componentDidMount() {
        if ( this.props.content.media && this.props.content.media._id ) {
            let file = findNestedObj(this.props.files, '_id', this.props.content.media._id);
            this.setState({file: file });
        }
    }

    render() {



        return (
            <div className={'module content mixed__content'}>
                <div className={'content__media content__side'}>
                    {this.state.file &&
                        <MediaComponent
                            media={this.state.file}
                            version={'original'}
                        />
                    }
                </div>
                <div className={'content__content content__side'}>
                    <p className={'section__title'}>{this.props.content.title}</p>
                    <p className={'section__content'} dangerouslySetInnerHTML={{ __html: this.props.content.content }}></p>
                    <ButtonCircle
                        target={{
                            path: this.props.content.button.path,
                            name: this.props.content.button.title,
                        }}
                    />
                </div>
            </div>
        )
    }
}