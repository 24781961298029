import React, {Component} from "react";

export default class FilterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            filters: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( prevState.selected !== this.state.selected ) {
            if ( this.props.update ) {
                this.props.update(this.state.selected);
            }
        }

        if ( prevProps.filters !== this.props.filters) {
            this.setState({filters: [...this.props.filters]});
        }
    }

    componentDidMount() {
        this.setState({selected: 'all'})
    }


    render() {
        return (
            <ul className={"filter__list"}>
                <li key={"filter-all"} className={`filter__listItem ${this.state.selected === "all" ? 'active':''}`} onClick={()=>{this.setState({selected:"all"})}}>
                    <span className={"filter__name"}>Tous</span>
                </li>
                { this.state.filters.map((item, index)=>{
                    return (
                        <li key={"filter-"+index} className={`filter__listItem ${this.state.selected === item.name ? 'active':''}`} onClick={()=>{this.setState({selected:item.name})}}>
                            <span className={"filter__name"}>{item.name}</span>
                        </li>
                    )
                })}
            </ul>
        )
    }
}