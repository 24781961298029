import React, {Component} from "react";

export default class AppLoading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className={'apploading'}>

            </section>
        )
    }
}