import { gsap } from "gsap";

//
// Manage animation on Circle CTAs
//

export default class CircleCtaAnim {
    constructor(element,options = {}) {
        this.element = element;
        this.ctaParent = this.element.parentNode;
        this.ctaParentParent = this.ctaParent.parentNode;
        while(!this.ctaParent.classList.contains('cta') && this.ctaParent.parentNode !== null){
            this.ctaParent = this.ctaParent.parentNode;
        }

        if ( this.ctaParentParent.classList.contains('next')) {
            this.direction = "right";
        } else {
            this.direction = "left";
        }




        this.circleItem = this.element.querySelector('.cta-circle');
        this.arrowItem = this.element.querySelector('.cta-arrow');
        this.planeItem = this.element.querySelector('.cta-plane');
        this.contentItem = this.ctaParent.querySelector('.text');


        this.ctacirc = gsap;
        this.tlctacirc = this.ctacirc.timeline();
        this.tlctarr = this.ctacirc.timeline();
        this.tlctaplaneIn = this.ctacirc.timeline();
        this.tlctaplaneOut = this.ctacirc.timeline();
        this.tlcontent = this.ctacirc.timeline();

        this.tlctacirc.startTime(0);

        //this.tlctacirc.to(this.circleItem, {drawSVG:"0% 75%"}).pause();
        this.tlctacirc.fromTo(this.circleItem, {drawSVG:"0% 75%"},{duration:.300, drawSVG:"100%", ease: "expo", delay:0.2}).pause();
        this.tlctarr.to(this.arrowItem, {x: -50, duration:.500, opacity:1, ease: "expo"}).pause();

        this.tlctaplaneIn.fromTo(this.planeItem, {opacity:0, x:0, y:0}, {x: "+=43px", y:"-=43px", duration:.250, delay:0.33, opacity:1, ease: "expo"}).pause();

        this.tlctaplaneOut.fromTo(this.planeItem, {x: "+=43px", y:"-=43px"}, {x: "+=53px", y:"-=63px", duration:.500, opacity:1, ease: "expo"}).pause();



        this.ctaParent.addEventListener('mouseover', (event)=> {
            event.stopPropagation();
            this.tlctacirc.play();
            if ( this.arrowItem) {
                this.tlctarr.play();
            } else if ( this.planeItem) {
               this.tlctaplaneIn.play();
            }
        });

        this.ctaParent.addEventListener('mouseout', (event)=> {
            event.stopPropagation();
            this.tlctacirc.reverse();
            if ( this.arrowItem) {
                this.tlctarr.reverse();
            } else if ( this.planeItem) {
                this.tlctaplaneIn.reverse();
            }
        });
    }
}