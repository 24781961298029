import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Link,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import ImageLazy from "../transversals/ImageLazy"
import MediaComponent from "../partials/Media";
import PageHero from "../components/Hero";
import Footer from "../components/Footer/Footer";


function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

export default class UniversesHomepage extends Component {
  constructor(props) {
    super(props);

  }

  renderMetas(){

    let title = (this.props.lngDatas.universes.meta.title !=="")? this.props.lngDatas.universes.meta.title : (this.props.lngDatas.generic.meta.title !=="")?this.props.lngDatas.generic.meta.title : "";
    let description = (this.props.lngDatas.universes.meta.description !=="")? this.props.lngDatas.universes.meta.description : (this.props.lngDatas.generic.meta.description !=="")?this.props.lngDatas.generic.meta.description : "";
    //let keywords = (this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords !== "") this.props.contentData.meta[`${this.props.lngDatas.name.data}`].keywords : (this.props.lngDatas.universes.meta.keywords !=="")? this.props.lngDatas.universes.meta.keywords : (this.props.lngDatas.generic.meta.keywords !=="")?this.props.lngDatas.generic.meta.keywords : "";
    let slug = this.props.lngDatas.universes.meta.slug;
    return(
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={slug} />
      </Helmet>
    )
  }

  renderItems(){
    let items = this.props.datas.universes;
    let files = this.props.datas.uploads; 
    let lngDatas = this.props.lngDatas;
    let lng = lngDatas.name.data;
    return items.map((item, index)=>{

      let imgSrc="";
      if(item.visual && item.visual._id){
        let img = item.visual._id;
        let file = findNestedObj(files, '_id', img);

        if(file){
          imgSrc = file.versions.thumbnail.staticPath;
        }
      }

      return(
        <div key={`news-item-${index}`} className="col-6 col-md-4 col-lg-3 item" data-color={item.color}>
          <Link to={"/"+lng+"/"+lngDatas.universes.meta.slug+"/"+item.meta[`${lngDatas.name.data}`].slug}>
          <h5 className="title">
            <span>
              {item.identity[`${this.props.lngDatas.name.data}`].name}
            </span>
          </h5>
            {item.visual &&
            <figure className="m-0 visual">
              <img src={imgSrc} alt={item.identity[`${this.props.lngDatas.name.data}`].name} />
            </figure>
            } 
          </Link>
        </div>
      )
    });   
  }

  iniFooterNavCTAs(svg){
    new CircleCtaAnim(svg);
  }

  renderFooterNav(){
    return(
    <ul className="row w-100 justify-content-center navigation">
      <li className="col-12 col-md-6 align-self-start previous">
        <Link className="cta hiddenCursor" to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.brands.meta.slug}>
          <span className="text">
            <span className="label">
              {this.props.lngDatas.generic.lab_cta_next}
            </span>
            <span className="name">
              {this.props.lngDatas.brands.lab_nav}
            </span>
          </span>
          <div className="circle">
            <ReactSVG
              className="circlesvg"
              src="/ui/ctas/a_circle_full.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                } else {
                  this.iniFooterNavCTAs(svg)
                }
              }}
            />
          </div>
        </Link>
      </li>
      <li className="col-12 col-md-6 align-self-end next">
        <Link className="cta hiddenCursor" to={"/"+this.props.lngDatas.name.data+"/"+this.props.lngDatas.expertises.meta.slug}>
          <div className="circle">
            <ReactSVG
              className="circlesvg"
              src="/ui/ctas/a_circle_full.svg"
              renumerateIRIElements={false}
              afterInjection={(error, svg) => {
                if (error) {
                  console.error(error)
                  return
                } else {
                  this.iniFooterNavCTAs(svg)
                }
              }}
            />
          </div>
          <span className="text">
            <span className="label">
              {this.props.lngDatas.generic.lab_cta_next}
            </span>
            <span className="name">
              {this.props.lngDatas.expertises.lab_nav}
            </span>
          </span>
        </Link>
      </li>
    </ul>
    )
  }

  renderUniverses(version) {
    let items = this.props.datas.universes;
    let files = this.props.datas.uploads;
    let cols = [
      [items[0], items[1], items[2]],
      [items[3], items[4]],
      [items[5], items[6], items[7]]
    ];

    return cols.map((col, key) => {
      return (
          <div key={`col-items-${key}`} className={`colItems-${key + 1}`}>
            {
              col.map((item, index) => {

                let imgSrc = "";
                item.file = false;
                if (item.visual && item.visual._id) {
                  let img = item.visual._id;
                  let file = findNestedObj(files, '_id', img);
                  item.file = file;
                }

                let brands = this.props.datas.brands;
                let universeBrands = [];
                brands.map((brand, indbrand) => {
                  let brandUniverses = brand.identity.universes;
                  if (brandUniverses.find(brandunivers => brandunivers._id === item._id)) {
                    universeBrands.push(brand);
                  }
                });


                if ( universeBrands.length>5) {
                  universeBrands = universeBrands.slice(0,5);
                }




                return (
                    <div key={`news-item-${index}`} className="item" data-color={item.color}>
                      <Link
                          to={"/" + this.props.lngDatas.name.data + "/" + this.props.lngDatas.universes.meta.slug + "/" + item.meta[`${this.props.lngDatas.name.data}`].slug}>
                        <p className={'item__name'}>{item.identity[`${this.props.lngDatas.name.data}`].name}</p>
                        {item.file &&
                        <div className={"univers__list__container"}>
                          {universeBrands.length>0 &&
                          <div className={"item__brands"}>
                            {universeBrands.map((univitem,univinedx)=>{
                              if ( univitem.identity.logoSVG && univitem.identity.logoSVG._id) {
                                let img = univitem.identity.logoSVG._id;
                                let file = findNestedObj(files, '_id', img);
                                if (file) {
                                  return (
                                      <div className={"universeBrandsItem"}>
                                        <MediaComponent
                                            media={file}
                                            version={version}
                                        />
                                      </div>
                                  )
                                }
                              }
                            })}
                          </div>
                          }
                          <div className={"overlay"}></div>
                          <div className={'item__media'}>
                            <MediaComponent
                                media={item.file}
                                version={version}
                            />
                          </div>
                        </div>
                        }
                      </Link>
                    </div>
                );
              })
            }
          </div>
      );
    });
  }

  componentDidMount(){
    gsap.registerPlugin(MorphSVGPlugin);
    gsap.registerPlugin(DrawSVGPlugin);

    let universes = document.querySelector('.universes');
    if(universes){
      let unitems = universes.querySelectorAll('.item');

      unitems.forEach(function(item){
        let color = item.getAttribute('data-color');
        let link = item.querySelector('a');
        let img = item.querySelector('img');

        if ( img ) {
          link.addEventListener("mouseover", function(){
            img.style.backgroundColor = color;
          });

          link.addEventListener("mouseout", function(){
            img.style.backgroundColor = '';
          });
        }
      });      
    }


  }


  render() {
    return (
      <div className="universes-homepage page">

        {this.renderMetas()}

        <PageHero
            lngDatas={this.props.lngDatas}
            datas={this.props.datas}
            identity={false}
            surtitle={{
              type: 'text',
              content: this.props.lngDatas.universes.lab_nav,
            }}
            title={{
              type: 'text',
              content: this.props.lngDatas.universes.hero,
            }}
            bottom={true}
            module={'heropage'}
        />

        { (this.props.lngDatas.universes.keyFigures && this.props.lngDatas.universes.keyFigures.length > 0)  &&
        <section className={'section content content__keydata universes__keyfigures'}>
          <ul className={'keydatas'}>
            {this.props.lngDatas.universes.keyFigures.map((item, index) => {
              return (
                  <li className={'keydata'} key={index}>
                    <p className={'keydata__title'}>{item.figure}</p>
                    <p className={'keydata__subtitle'}>{item.label}</p>
                    <p className={'keydata__light'}>{item.description}</p>
                    <p className={'keydata__xs'}>{item.source}</p>
                  </li>
              )
            })}
          </ul>
        </section>
        }

        <section className={'universes__introduction content section'}>
          <p className={'section__title'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.universes.introduction.title}}></p>
          <p className={'section__content'} dangerouslySetInnerHTML={{__html: this.props.lngDatas.universes.introduction.content}}></p>
        </section>

        <section className={'universes__list section content'}>
          {this.renderUniverses('thumbnail')}
        </section>

        <Footer
            datas={this.props.datas}
            didomiObject={this.props.didomiObject}
            lngDatas={this.props.lngDatas}
            footerMenuLinks={this.props.footerMenuLinks}
            footerMenuCopy={this.props.footerMenuCopy}
            locationPath={this.props.locationPath}
            params={this.props.paramsLocation}
            appControls={this.props.appControls}
            custom={
              {
                next: {
                  title: this.props.lngDatas.about.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.about.meta.slug,
                },
                prev: {
                  title: this.props.lngDatas.expertises.lab_nav,
                  path: '/'+this.props.lngDatas.name.data+'/'+this.props.lngDatas.expertises.meta.slug,
                }
              }
            }
        />

      </div>
    )
  }
}
