import React, {Component} from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {Helmet} from "react-helmet";
import {gsap} from "gsap";
import {MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {
    BrowserRouter as Router,
    FadeIn,
    Switch,
    NavLink,
    Route,
    Redirect,
    useRouteMatch,
    useParams,
    useLocation
} from "react-router-dom";
import CircleCtaAnim from "../../assets/scripts/CircleCtaAnim";
import IsVisible from "../../assets/scripts/IsVisible";
import {TimelineMax, Power3} from 'gsap';
import SplitText from "gsap/SplitText";

export default class HeaderGen extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.state.splited = false;
        this.state.current = {};
        this.state.lastActive = {};

        this.state.current.title = false;
        this.state.current.path = false;

        this.state.lastActive.title = false;
        this.state.lastActive.path = false;

        this.state.active = false;

        this.state.title = false;
        this.state.path = false;

        this.listRef = React.createRef();
        this.blockRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        this.getCurrentItem();

        if (this.state.active) {
            if (this.state.current.title !== this.state.lastActive.title) {
                this.setCurrentTitles();
            }
        }
    }

    componentDidMount() {
        this.splitTitles();
        this.getCurrentItem();

        if (this.state.active) {
            this.setCurrentTitles();
        } else {
        }
    }

    splitTitles() {
        let titles = this.listRef.current.querySelectorAll('.hdr__title');
        titles.forEach((item, index) => {
            new SplitText(item, {type: "chars", charsClass: "char"});
        });
    }

    getCurrentItem() {
        let newArray = this.props.datas.filter(el => {
            return el.path == this.props.current;
        });


        if (newArray.length > 0) {

            if (this.state.current.title && this.state.current.path) {
                this.state.lastActive.title = this.state.current.title;
                this.state.lastActive.path = this.state.current.path;
            } else {
            }
            this.state.active = true;
            this.blockRef.current.classList = "header__page row m-0 content active";
            this.state.current.title = newArray[0].lab_nav;
            this.state.current.path = newArray[0].path;
        } else {
            this.state.active = false;
            this.blockRef.current.classList = "header__page row m-0 content hidden-b";
        }

    }

    setCurrentTitles() {
        let titles = this.listRef.current.querySelectorAll('.hdr__title');
        let lastTitle = this.listRef.current.querySelectorAll('.hdr__title.current');
        let currentIndex = this.props.datas.map(e => e.path).indexOf(this.props.current);
        let currentTitle = titles[currentIndex];


        if (lastTitle.length > 0 && lastTitle[0] != currentTitle) {
            this.animeOutLastTitle(lastTitle[0]);
        }
        if (currentTitle && lastTitle[0] != currentTitle) {
            this.animateInCurrentTitle(currentTitle)
        }
    }

    animeOutLastTitle(last) {
        last.classList.remove('current');
        const timeline = new TimelineMax({
            onComplete: () => {
                timeline.kill();
            },
        });
        const letters = last.querySelectorAll('.char');
        const waitTime = 0;
        letters.forEach((letter, i) => {
            timeline.fromTo(letter, 1.3,
                {autoAlpha: 1, y: -70},
                {autoAlpha: 0, y: -140, ease: Power3.easeInOut}, i * 0.03 + waitTime);
        });
    }

    animateInCurrentTitle(current) {

        current.classList.add('current');
        const timeline = new TimelineMax({
            onComplete: () => {
                timeline.kill();
            },
        });
        const letters = current.querySelectorAll('.char');
        const waitTime = 0;
        letters.forEach((letter, i) => {
            timeline.fromTo(letter, 1.3,
                {autoAlpha: 0, y: 0},
                {autoAlpha: 1, y: -70, ease: Power3.easeInOut}, i * 0.03 + waitTime);
        });
    }

    renderTitles() {
        return this.props.datas.map((item, index) => {
            return (
                <li key={index} className="hdr__title" data-index={index}>{item.lab_nav}</li>
            )
        });
    }


    render() {

        return (

            <section className="header__page row m-0 content" ref={this.blockRef}>
                <div className="col-12 page">
                    <div className="row m-0">
                        <div className="col-12 mx-auto p-0 block-l">
                            <ul className="titles" ref={this.listRef}>
                                {this.renderTitles()}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )

    }
}
