import Glide from '@glidejs/glide';
import SliderCursor from './SliderCursor'

export default class GlideCarousel {
    constructor(element,options)
    {
        this.options = {
            type: 'carousel',
            startAt: 0,
            perView: 3,
            focusAt:'center',
            keyboard:true,
        };
        for (const property in options) {
            this.options[property] = options[property];
        }
        this.slider = new Glide(element,this.options);
        this.slider.mount();

       this.cursor = new SliderCursor(element);
    }
}