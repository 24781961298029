import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import {
  BrowserRouter as Router,
  FadeIn,
  Switch,
  NavLink,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";

import BrandsHomepage from './Brands.Homepage';
import BrandsDetail from './Brands.Detail';
import PageHero from "../components/Hero";
import Footer from "../components/Footer/Footer";
import ExpertisesHomepage from "./Expertises.Homepage";

function RenderLngCtn(props){
  let lngDatas = props.lngDatas;
  let lngSelected = lngDatas.name.data;
  let datas = props.datas;
  let items = props.datas.brands;

  // console.log(props);

  // let datas = {
  //   brands : props.brands,
  //   news : props.news,
  //   newscontactuser : props.newscontactuser,
  //   tags :  props.tags,
  //   types : props.types,
  //   universes : props.universes,
  //   uploads : props.uploads
  // };

  let routes = [
  ];

  items.map((item, index)=>{
    item.index = index;
    let route = {
      path : "/"+lngDatas.name.data+"/"+lngDatas.brands.meta.slug+"/"+item.meta[`${lngDatas.name.data}`].slug,
      lab_nav : item.identity.name,
      contentData : item,
      component : BrandsDetail,
    };

    routes.push(route);
  });

  routes.push(
      {
        path : "/"+lngDatas.name.data+"/"+lngDatas.brands.meta.slug+"/:brandSlug",
        lab_nav : "home",
        component : BrandsHomepage,
      });

  routes.push(
    {
      path : "/"+lngDatas.name.data+"/"+lngDatas.brands.meta.slug,
      lab_nav : "home",
      component : BrandsHomepage,
    });

  return(
      <Switch>
        {routes.map((route, index) => (
            <Child 
              key={index} 
              {...route} 
              datas={datas}
              lngDatas={lngDatas}
              header={ PageHero }
            />
          )
        )}
      </Switch>
  )
}

function Child(datasObj) {
  //console.log(datasObj);
  return (
    <Route 
      exact
      path={datasObj.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <datasObj.component 
          {...props} 
          contentData={datasObj.contentData}
          lngDatas={datasObj.lngDatas}
          datas={datasObj.datas}  
        />
      )}
    />
  )
} 


export default class Brands extends Component {
  constructor(props) {
    super(props);

    //console.log(this.props);

  }
  componentDidMount(){
    this.props.appControls.handleNavigationPanel(false);
  }
  render() {
    return (
      <section className="col-12 page brands">
          <RenderLngCtn {...this.props} />
      </section>
    )
  }
}
